import { memo, useEffect } from "react";
import { useContext, useState } from "react";
import { ContextApp } from "../../Context";
import "./SimpleCard.scss";
import axios from "axios";
import axs from "../../utils/axios"

function SimpleCard({ nft, currentItem, maxItems, showEmoji, foreignProfile, isOptimizedSizes=true }) {
    const { theme, changeTheme, setAuthPopup, currentUser } = useContext(ContextApp);

    const [likes, setLikes] = useState([0, 0, 0, 0, 0, 0, 0]);

    const [likeProccess, setLikeProccess] = useState(false);

    const [lastDoWasPlaceAuction, setLastDoWasPlaceAuction] = useState(false);

    useEffect(() => {
        axs
        .post("/nft_history/list", {
            filters: {
                nft_id: nft.id.toString(),
            },
            subqueries: {
                new_owner: {
                    subqueries: {
                        img: {},
                    },
                },
                old_owner: {
                    subqueries: {
                        img: {},
                    },
                },
            },
        })
        .then(res => {
            setLastDoWasPlaceAuction(res.data.items.reverse()[0]?.event_type === "auction")
            
        })
        .catch(err => console.log(err))
    }, [])

    // set likes on start card
    useEffect(() => {
        if (nft.likes.length > 0 || nft?.anon_likes) {
            let likes_copy = [0, 0, 0, 0, 0, 0, 0];
            if (nft.likes.length > 0) {
                nft.likes.map(like => (likes_copy[Number(like.type_id) - 1] += 1));
            }
            if (nft?.anon_likes) {
                Object.keys(nft.anon_likes).map(like_id => {
                    likes_copy[Number(like_id) - 1] += Number(nft.anon_likes[like_id]);
                });
            }
            setLikes(likes_copy);
        }
    }, [nft]);

    const handleUserLike = e => {
        if (!likeProccess) {
            setLikeProccess(true);
            if (localStorage.getItem("auth") === "true") {
                axios
                    .post(
                        "https://nft-one.art/api/nft_likes/list",
                        {
                            filters: {
                                nft_id: nft?.id,
                                user_id: currentUser.id,
                            },
                        },
                        {
                            headers: {
                                Token: localStorage.getItem("tonkeeperToken")
                                    ? localStorage.getItem("tonkeeperToken")
                                    : localStorage.getItem("tonhubToken"),
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        let hasLikes = response.data.items.length > 0;
                        let clickTypeID = e.target.getAttribute("data-emoji");

                        // если этот пользователь еще не ставил лайки этой нфт/коллекции
                        if (!hasLikes) {
                            axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/upsert",
                                    {
                                        items: [
                                            {
                                                nft_id: nft?.id,
                                                type_id: clickTypeID,
                                                user_id: currentUser.id,
                                            },
                                        ],
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                    let likes_copy = [...likes];
                                    likes_copy[Number(clickTypeID) - 1] += 1;
                                    setLikes([...likes_copy]);
                                    setLikeProccess(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }

                        // если этот пользователь ставил лайки этой нфт/коллекции и выбранный лайк совпадает с уже имеющимся на сервере
                        if (hasLikes && clickTypeID === response.data.items[0].type_id) {
                            axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/unlike",
                                    {
                                        nft_id: nft?.id,
                                        type_id: clickTypeID,
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                    let likes_copy = [...likes];
                                    likes_copy[Number(clickTypeID) - 1] -= 1;
                                    setLikes([...likes_copy]);
                                    setLikeProccess(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }

                        // если этот пользователь ставил лайки этой нфт/коллекции и выбранный лайк НЕ совпадает с уже имеющимся на сервере
                        if (hasLikes && !(clickTypeID === response.data.items[0].type_id)) {
                            let needDeleteTypeID = response.data.items[0].type_id;
                            let likes_copy = [...likes];
                            likes_copy[needDeleteTypeID - 1] -= 1;
                            likes_copy[clickTypeID - 1] += 1;
                            setLikes([...likes_copy]);
                            const updateLikesOnServer = (() => {
                                axios
                                    .post(
                                        "https://nft-one.art/api/nft_likes/unlike",
                                        {
                                            nft_id: nft?.id,
                                            type_id: needDeleteTypeID,
                                        },
                                        {
                                            headers: {
                                                Token: localStorage.getItem("tonkeeperToken")
                                                    ? localStorage.getItem("tonkeeperToken")
                                                    : localStorage.getItem("tonhubToken"),
                                            },
                                            auth: {
                                                username: "odmen",
                                                password: "NFTflsy",
                                            },
                                        },
                                    )
                                    .then(response => {})
                                    .catch(error => {
                                        console.log(error);
                                    });
                                axios
                                    .post(
                                        "https://nft-one.art/api/nft_likes/upsert",
                                        {
                                            items: [
                                                {
                                                    nft_id: nft?.id,
                                                    type_id: clickTypeID,
                                                    user_id: currentUser.id,
                                                },
                                            ],
                                        },
                                        {
                                            headers: {
                                                Token: localStorage.getItem("tonkeeperToken")
                                                    ? localStorage.getItem("tonkeeperToken")
                                                    : localStorage.getItem("tonhubToken"),
                                            },
                                            auth: {
                                                username: "odmen",
                                                password: "NFTflsy",
                                            },
                                        },
                                    )
                                    .then(response => {})
                                    .catch(error => {
                                        console.log(error);
                                    });
                                setLikeProccess(false);
                            })();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                setAuthPopup(true);
            }
        }
    };

    return (
        <div
            className={changeTheme("simpleCard", "simpleCard simpleCard--dark")}
            style={{ background: changeTheme("#fff", "#2B3239"), color: changeTheme("#000", "#fff") }}>
            <a href={`/nft/${nft?.id}`}>
                <div
                    className="simpleCard__photo"
                    style={{
                        background: nft?.img?.hash
                            ? `${`url(https://nft-one.art/api/files/thumb/?hash=${nft?.img?.hash}${isOptimizedSizes ? "&width=450&height=390" : ""}) no-repeat center center/cover`}`
                            : `url(/img/card/photo-2.svg) no-repeat center center/cover`,
                    }}></div>
                <div className="simpleCard__info">
                    <div className="simpleCard__info-left">
                        <h6 className="simpleCard__info-left-title">
                            {nft?.name?.length > 16 ? nft?.name?.slice(0, 14) + "..." : nft?.name}
                        </h6>
                        {["sale", "auction"].includes(nft?.state) && (
                            <p
                                className="simpleCard__info-left-price"
                                style={{ color: theme === "light" ? "#0088cc" : "#fff" }}>
                                <img src={`/img/card/diamond-dark.png`} alt="Price" />
                                {
                            (nft?.state !== "sale" && !lastDoWasPlaceAuction) ? ((Number(nft?.price) - 0.01 * 10 ** 9) / 10 ** 9) :
                            Number(nft?.price) / (10 ** 9)
                        }
                            </p>
                        )}
                    </div>
                    <div className="simpleCard__info-right">
                        <div className="simpleCard__info-right-user">
                            <div
                                className="simpleCard__info-right-user-avatar"
                                style={{
                                    background: foreignProfile?.id && foreignProfile?.img?.hash
                                        ? `url(https://nft-one.art/api/files/thumb/?hash=${foreignProfile?.img?.hash}&width=45&height=45) no-repeat center center/cover`
                                        : nft?.is_foreign && currentUser?.img?.hash
                                        ? `url(https://nft-one.art/api/files/thumb/?hash=${currentUser?.img?.hash}&width=45&height=45) no-repeat center center/cover`
                                        : nft?.creator?.img?.hash
                                        ? `url(https://nft-one.art/api/files/thumb/?hash=${nft?.creator?.img?.hash}&width=45&height=45) no-repeat center center/cover`
                                        : "url(/img/sections/myNFT/avatar.svg) no-repeat center center / cover",
                                }}></div>
                            {foreignProfile?.id ? (
                                <p className="simpleCard__info-right-user-name">
                                    by{" "}
                                    {foreignProfile?.name?.length > 10
                                        ? foreignProfile?.name?.slice(0, 10) + "..."
                                        : foreignProfile?.name}
                                </p>
                            ) : nft?.is_foreign ? (
                                <p className="simpleCard__info-right-user-name">
                                    by{" "}
                                    {currentUser?.name?.length > 10
                                        ? currentUser?.name?.slice(0, 10) + "..."
                                        : currentUser?.name}
                                </p>
                            ) : (
                                <p className="simpleCard__info-right-user-name">
                                    by{" "}
                                    {nft?.creator?.name?.length > 10
                                        ? nft?.creator?.name?.slice(0, 10) + "..."
                                        : nft?.creator?.name}
                                </p>
                            )}
                        </div>
                        {!showEmoji && (
                            <div className="simpleCard__info-right-count" style={{ marginTop: "8px" }}>
                                {currentItem} of {maxItems}
                            </div>
                        )}
                        {
                            nft?.is_foreign && <div className="simpleCard__info-right-external">
                                <p className="simpleCard__info-right-external-text">External NFT</p>
                                <img className="simpleCard__info-right-external-question" src={`/img/sections/nft/question-${"light"}.svg`} alt="" data-tooltip-id="click-me" data-tooltip-content="NFT minted on another source. Operations with external NFT’s soon…"/>
                            </div>
                        }
                        {showEmoji &&
                            (likes.every(el => el === 0) ? (
                                <ul className="card__info-right-emoji" style={{ opacity: "0" }}>
                                    <li className={"card__info-right-emoji-item-" + theme} style={{ opacity: "0" }}>
                                        😡<span>0</span>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="card__info-right-emoji" style={{ marginTop: "10px" }}>
                                    {["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((emoji, index) => (
                                        <li
                                            className={"card__info-right-emoji-item-" + theme}
                                            key={index}
                                            style={{ display: `${likes[index] === 0 ? "none" : ""}` }}>
                                            {emoji}
                                            <span>{likes[index]}</span>
                                        </li>
                                    ))}
                                </ul>
                            ))}
                    </div>
                </div>
            </a>
            <ul className="simpleCard__menuEmoji" style={{ backgroundColor: changeTheme("", "#2C333A") }}>
                {["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((item, index) => (
                    <li
                        key={index}
                        data-emoji={index + 1}
                        className="simpleCard__menuEmoji-item"
                        onClick={e => handleUserLike(e)}>
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default memo(SimpleCard);
