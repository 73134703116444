import {useContext} from "react";
import { ContextApp } from "../../../../Context";

export const AdminPanelHome = ({ isAdmin, setPage }) => {
    const { changeTheme, } = useContext(ContextApp);

    return (
        <section className={changeTheme("adminPanelHome", "adminPanelHome adminPanelHome--dark")}>
            {isAdmin ? (
                <>
                    <div className="adminPanelHome__block" onClick={() => setPage("finance")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/finance.svg" alt="" />
                            Finance
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            View user operations, make data selections and manage them
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("users")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/users.svg" alt="" />
                            Users
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            View registered users, make a selection and manage them
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("collections")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/collections.svg" alt="" />
                            Collections
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            View user-created collections, make selections and manage them
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("nft")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/nft.svg" alt="" />
                            NFT
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            View user-created NFTs, make selections and manage them
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("faq")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/faq.svg" alt="" />
                            FAQ
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            Edit the FAQ section on the site and view the current answers-questions
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("statistics")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/statistics.svg" alt="" />
                            Statistics
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            See statistics on users, cards and much more in a convenient infographic format
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("settings")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/adminPanel/settings.svg" alt="" />
                            Settings
                        </h6>
                        <p className="adminPanelHome__block-txt">Manage global site settings (configuration)</p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>{" "}
                </>
            ) : (
                <>
                    <div className="adminPanelHome__block" onClick={() => setPage("collections")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/collections.svg" alt="" />
                            Collections
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            View user-created collections, make selections and manage them
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                    <div className="adminPanelHome__block" onClick={() => setPage("nft")}>
                        <h6 className="adminPanelHome__block-title">
                            <img src="/img/header/nft.svg" alt="" />
                            NFT
                        </h6>
                        <p className="adminPanelHome__block-txt">
                            View user-created NFTs, make selections and manage them
                        </p>
                        <img
                            className="adminPanelHome__block-img"
                            src="/img/adminPanel/arrow-to.svg"
                            alt="Navigate to"
                        />
                    </div>
                </>
            )}
        </section>
    );
};
