import { useState } from "react";
import { useEffect } from "react";

const NotFound = () => {

    const [seconds, setSeconds] = useState(5)

    useEffect(() => {
        setTimeout(() => {
            setInterval(() => {
                setSeconds(prev => {
                    if (prev === 1) {
                        window.location.href = "/"
                    } else {
                        return prev - 1
                    }
                })
            }, 1500)
        }, 500)
    }, [])

    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            rowGap: "8px",
            font: "400 32px/100% 'DM Sans'"
        }}>
            <div style={{
                fontWeight: "600",
                fontSize: "64px",
                lineHeight: '100%',
                color: "#004d8c"
            }}>#404</div>
            <div style={{
                fontWeight: "600",
                fontSize: "64px",
                lineHeight: '100%',
                color: "#0088cc"
            }}>PAGE NOT FOUND</div>
            <div style={{
                lineHeight: '100%',
                fontSize: "22px"
            }}>Redirect on homepage after {seconds} seconds</div>
        </div>
    );
};

export default NotFound;
