import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import Card from "../../components/Card";
import CollectionCard from "../../components/CollectionCard";
import { ContextApp } from "./../../Context";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import Countdown from "react-countdown";
import "./Home.scss";

function Home() {

    const [popularNFT, setPopularNFT] = useState([]);
    const [popularCollection, setPopularCollection] = useState([]);

    const [recentNFT, setRecentNFT] = useState([]);
    const [recentCollection, setRecentCollection] = useState([]);

    const [isLoadingPopular, setIsLoadingPopular] = useState(true);
    const [isLoadingRecent, setIsLoadingRecent] = useState(true);

    const [popularFilter, setPopularFilter] = useState("nft");
    const [recentFilter, setRecentFilter] = useState("nft");

    const [currentUser, setCurrentUser] = useState({});

    const [auctionTimeItems, setAuctionTimeItems] = useState([]);

    const [config, setConfig] = useState({});

    const { changeTheme } = useContext(ContextApp);

    const settingsForSlider = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const rendererCountdown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return;
        } else {
            return (
                <span>
                    {days}d {hours}h {minutes}m {seconds}s
                </span>
            );
        }
    };

    const renderWelcomeLibrary = useMemo(() => {
        return auctionTimeItems.slice(0,3).map((item, index) => (
            <div
            key={item?.id}
                onClick={() => window.location.href = `/nft/${item?.id}`}
                className={`welcome__library-item welcome__library-item-${index + 1}`}
                style={{
                    background: `${`url(https://nft-one.art/api/files/thumb/?hash=${item?.img?.hash}&width=600&height=650) no-repeat center center/cover`}`,
                }}>
                <h6 className="welcome__library-item-title">{item?.name}</h6>
                <div className="welcome__library-item-user">
                    <div
                        className="welcome__library-item-user-avatar"
                        style={{ background: item?.creator?.img?.hash ? `url(https://nft-one.art/api/files/thumb/?hash=${item?.creator?.img?.hash}&width=80&height=80) no-repeat center center / cover` : "/img/card/avatar.png" }}
                    ></div>
                    <p className="welcome__library-item-user-name">
                        {item?.creator?.name?.length > 16
                            ? item?.creator?.name?.slice(0, 16) + "..."
                            : item?.creator?.name}
                    </p>
                </div>
                <div className="welcome__library-item-info">
                    <div className="welcome__library-item-info-left">
                        <p className="welcome__library-item-info-left-label">Current Bid</p>
                        <p className="welcome__library-item-info-left-price">{item?.price / (10 ** 9)}</p>
                    </div>
                    <div className="welcome__library-item-info-right">
                        <p className="welcome__library-item-info-right-label">Ends in</p>
                        <p className="welcome__library-item-info-right-time">
                            <Countdown date={Date.now() + (item?.end_time * 1000 - Date.now())} renderer={rendererCountdown} />
                        </p>
                    </div>
                </div>
                {
                    index === 0 && (
                        <div className="circular">
                            <div>
                                <svg viewBox="0 0 100 100">
                                    <path d="M 0,50 a 50,50 0 1,1 0,1 z"
                                        id="circle" />
                                    <text><textPath xlinkHref="#circle">
                                        {config?.values?.index_circle_text}
                                    </textPath></text>
                                </svg>
                                <div>
                                    <img src="/img/sections/connect/diamond-big.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        ))
    }, [auctionTimeItems, config])

    const renderMobileWelcomeLibrary = useMemo(() => {
        return auctionTimeItems.slice(0, 3).map((item, index) => (
            <div
            key={item?.id}
                onClick={() => window.location.href = `/nft/${item?.id}`}
                className={`welcome__libraryMobile-item welcome__libraryMobile-item-${index + 1}`}
                style={{
                    background: `${`url(https://nft-one.art/api/files/thumb/?hash=${item?.img?.hash}&width=600&height=650) no-repeat center center/cover`}`,
                }}>
                <h6 className="welcome__libraryMobile-item-title">{item?.name}</h6>
                <div className="welcome__libraryMobile-item-user">
                    <div
                        className="welcome__libraryMobile-item-user-avatar"
                        style={{background: item?.creator?.img?.hash ? `url(https://nft-one.art/api/files/thumb/?hash=${item?.creator?.img?.hash}&width=50&height=50) no-repeat center center / cover` : "/img/card/avatar.png"}}
                    ></div>
                    <p className="welcome__libraryMobile-item-user-name">{item?.creator?.name?.length > 16
                            ? item?.creator?.name?.slice(0, 16) + "..."
                            : item?.creator?.name}</p>
                </div>
                <div className="welcome__libraryMobile-item-info">
                    <div className="welcome__libraryMobile-item-info-left">
                        <p className="welcome__libraryMobile-item-info-left-label">Current Bid</p>
                        <p className="welcome__libraryMobile-item-info-left-price">{item?.price / (10 ** 9)}</p>
                    </div>
                    <div className="welcome__libraryMobile-item-info-right">
                        <p className="welcome__libraryMobile-item-info-right-label">Ends in</p>
                        <p className="welcome__libraryMobile-item-info-right-time">
                            <Countdown date={Date.now() + (item?.end_time * 1000 - Date.now())} renderer={rendererCountdown} />
                        </p>
                    </div>
                </div>
                {
                        index === 0 && (
                            <div className="circular">
                                <div>
                                    <svg viewBox="0 0 100 100">
                                        <path d="M 0,50 a 50,50 0 1,1 0,1 z"
                                            id="circle" />
                                        <text><textPath xlinkHref="#circle">
                                            {config?.values?.index_circle_text}
                                        </textPath></text>
                                    </svg>
                                    <div>
                                        <img src="/img/sections/connect/diamond-big.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
            </div>
        ))
    }, [auctionTimeItems, config])

    useEffect(() => {
        document.querySelectorAll("a.welcome__info-btn")[0].addEventListener("click", function (e) {
            e.preventDefault();
            const blockID = document.querySelectorAll("a.welcome__info-btn")[0].getAttribute("href");
            document.querySelector(blockID).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        });
        let pageSubquery = window.location.href.split("#")[1];

        if (pageSubquery === "delete-nft") {
            axios
                        .post(
                            "https://nft-one.art/api/nfts/delete",
                            {
                                ids: [window.location.href.split("#")[2]]
                            },
                            {
                                headers: {
                                    Token: localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken")
                                },
                                auth: {
                                    username: "odmen",
                                    password: "NFTflsy",
                                },
                            },
                        )
                        .then(res => {
                            console.log("Успешно удалено")
                            window.location.href = "/"
                        })
                        .catch(error => {
                            console.log(error);
                        });
        } else if (pageSubquery === "delete-collection") {
            axios
                        .post(
                            "https://nft-one.art/api/nft_collections/delete",
                            {
                                ids: [window.location.href.split("#")[2]]
                            },
                            {
                                headers: {
                                    Token: localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken")
                                },
                                auth: {
                                    username: "odmen",
                                    password: "NFTflsy",
                                },
                            },
                        )
                        .then(res => {
                            console.log("Успешно удалено")
                            window.location.href = "/"
                            
                        })
                        .catch(error => {
                            console.log(error);
                        });
        }
        if (localStorage.getItem("auth") === "true") {
            axios
                .post(
                    "https://nft-one.art/api/auth/current",
                    {},
                    {
                        headers: {
                            Token: localStorage.getItem("tonkeeperToken")
                                ? localStorage.getItem("tonkeeperToken")
                                : localStorage.getItem("tonhubToken"),
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    setCurrentUser(response.data.user);
                })
                .catch(error => {
                    console.log(error);
                });
        }

        axios
            .post(
                "https://nft-one.art/api/batch",
                {
                    "nfts-1": {
                        action: "nfts/list",
                        filters: {
                            event_type: ['hold', 'sale', 'auction'],
                            is_foreign: false,
                            is_popular: false
                        },
                        subqueries: {
                            img: {},
                            likes: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        },
                        order_by: "most_popular desc",
                        limit: 20
                    },
                    "nfts-2": {
                        action: "nfts/list",
                        filters: {
                            is_foreign: false,
                            is_popular: true
                        },
                        subqueries: {
                            img: {},
                            likes: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        }
                    },
                    "recent-nfts": {
                        action: "nfts/list",
                        order_by: "add_time desc",
                        filters: {
                            is_foreign: false
                        },
                        subqueries: {
                            likes: {},
                            img: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        },
                        limit: 20
                    },
                    "collection-1": {
                        action: "nft_collections/list",
                        filters: {
                            is_foreign: false,
                            is_popular: false,
                        },
                        subqueries: {
                            likes: {},
                            img: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        },
                        order_by: "most_popular desc",
                        limit: 20
                    },
                    "collection-2": {
                        action: "nft_collections/list",
                        filters: {
                            is_foreign: false,
                            is_popular: true
                        },
                        subqueries: {
                            likes: {},
                            img: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        },
                    },
                    "recent-collection": {
                        action: "nft_collections/list",
                        filters: {
                            is_foreign: false
                        },
                        order_by: "add_time desc",
                        subqueries: {
                            likes: {},
                            img: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        },
                        limit: 20
                    },
                    "cfg": {
                        action:  "config/get"
                    }
                },
                {
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setPopularNFT([...response.data["nfts-2"].items, ...response.data["nfts-1"].items]);
                // setPopularNFT(response.data["popular-nfts"].items);
                setPopularCollection([...response.data["collection-2"].items, ...response.data["collection-1"].items])
                // setPopularCollection(response.data["popular-collection"].items);

                setRecentNFT(response.data["recent-nfts"].items);
                setRecentCollection(response.data["recent-collection"].items);

                setConfig(response.data["cfg"])
                if (Object.keys(response.data["cfg"]).length > 0) {
                        let adminAuctionNFTs = response.data["cfg"]?.values?.index_nfts?.split(",")
                        axios
                            .post(
                                "https://nft-one.art/api/nfts/list",
                                {
                                    filters: {
                                        id: [Number(adminAuctionNFTs[0]), Number(adminAuctionNFTs[1]), Number(adminAuctionNFTs[2])],
                                        is_foreign: false
                                    },
                                    subqueries: {
                                        img: {},
                                        likes: {},
                                        creator: {
                                            subqueries: {
                                                img: {},
                                            },
                                        },
                                    },
                                },
                                {
                                    auth: {
                                        username: "odmen",
                                        password: "NFTflsy",
                                    },
                                },
                            )
                            .then(response => {
                                let arr = []
                                adminAuctionNFTs.map((nftID, index) => {
                                    response.data.items.map(nft => {
                                        if (Number(nftID) === Number(nft?.id)) {
                                            arr[index] = nft
                                        }
                                    })
                                })
                                setAuctionTimeItems(arr)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                }

                setIsLoadingPopular(false);
                setIsLoadingRecent(false);
            })
            .catch(error => {
                console.log(error);
            })

        document.addEventListener("scroll", e => {
            console.log(window.scrollX);
        })

    }, []);

    return (
        <>
            <Header currentPage={"zero"} />
            <section className={`welcome ${changeTheme("", "welcome--dark")}`}>
                <div className="welcome__bg"></div>
                <div className="welcome__info">
                    <h1 className="welcome__info-title">{config?.values?.index_center_title}</h1>
                    <p className="welcome__info-text">
                        {config?.values?.index_center_text}
                    </p>
                    <a className="welcome__info-btn" href="#popular-section">
                        Explore Now
                    </a>
                </div>
                <div className="welcome__library">
                    {
                        renderWelcomeLibrary
                    }
                </div>
                <div className="welcome__libraryMobile">
                    {
                        renderMobileWelcomeLibrary
                    }
                </div>
            </section>
            <section
                id="popular-section"
                className={`popular ${changeTheme("", "popular--dark")} ${
                    popularFilter === "nft" ? "popular--nft" : ""
                }`}>
                <h2 className="popular__title">Popular NFTs</h2>
                <div className="popular__categories">
                    <button
                        onClick={() => setPopularFilter("nft")}
                        className={`popular__categories-nfts ${
                            popularFilter === "nft" ? "popular__categories-nfts--active" : ""
                        }`}>
                        NFTs
                    </button>
                    <button
                        onClick={() => setPopularFilter("collection")}
                        className={`popular__categories-collections ${
                            popularFilter === "collection" ? "popular__categories-collections--active" : ""
                        }`}>
                        Collections
                    </button>
                </div>
                {isLoadingPopular ? (
                    <Skeleton height="24.21vw" style={{ marginTop: "47px" }} count={1} />
                ) : (
                    <Slider {...settingsForSlider}>
                        {popularFilter === "nft"
                            ? popularNFT.map(nft => <Card key={nft?.id} nft={nft} currentUser={currentUser} />)
                            : popularCollection.map(collection => (
                                  <CollectionCard key={collection?.id} collection={collection} currentUser={currentUser} />
                              ))}
                    </Slider>
                )}
            </section>
            <section className={`recent ${changeTheme("", "recent--dark")}`}>
                <div className="recent__bg"></div>
                <h2 className="recent__title">Recent added</h2>
                <div className="recent__categories">
                    <button
                        className={`recent__categories-nfts ${
                            recentFilter === "nft" ? "recent__categories-nfts--active" : ""
                        }`}
                        onClick={() => setRecentFilter("nft")}>
                        NFTs
                    </button>
                    <button
                        className={`recent__categories-collections ${
                            recentFilter === "collection" ? "recent__categories-collections--active" : ""
                        }`}
                        onClick={() => setRecentFilter("collection")}>
                        Collections
                    </button>
                </div>
                {isLoadingRecent ? (
                    <Skeleton height="24.21vw" style={{ marginTop: "47px" }} count={1} />
                ) : (
                    <Slider {...settingsForSlider}>
                        {recentFilter === "nft"
                            ? recentNFT.map(nft => <Card key={nft?.id} nft={nft} currentUser={currentUser} />)
                            : recentCollection.map(collection => (
                                  <CollectionCard key={collection?.id} collection={collection} currentUser={currentUser} />
                              ))}
                    </Slider>
                )}
                <div className="recent__all">
                    <a href="/catalog" className="recent__all-btn">
                        View all
                    </a>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Home;
