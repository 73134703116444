import { useEffect } from "react";
import styles from "./FullscreenImage.module.scss";

const FullscreenImage = ({ onToggle, imgHash }) => {

    useEffect(() => {
        const keyDownHandler = event => {
    
          if (event.key === 'Escape') {
            event.preventDefault();
    
            // 👇️ your logic here
            onToggle(false)
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        // 👇️ clean up event listener
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.content}
                onClick={e => {
                    if (!(e.target === document.getElementById("fullscreen-nft-img"))) {
                        onToggle(false);
                    }
                }}>
                <img
                    className={styles.img}
                    id="fullscreen-nft-img"
                    src={`https://nft-one.art/api/files/thumb/?hash=${imgHash}`}
                    alt="Fullscreen NFT"
                />
            </div>
        </div>
    );
};

export default FullscreenImage;
