import { useEffect } from "react";
import $ from "jquery";

const useScript = (url, pageID, classForAdd, theme) => {
    useEffect(() => {
        $(".nft iframe").remove()
        $(".collectionComments iframe").remove()
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        script.setAttribute("data-limit", "5");
        script.setAttribute("data-comments-app-website", "cIl5yLxk");
        script.setAttribute("data-page-id", pageID);
        if(theme === "dark") {
            script.setAttribute("data-dark", "1");
        } else {
            script.setAttribute("data-dark", "0");
        }

        document.getElementsByClassName(classForAdd)[0].appendChild(script);

        return () => {
            document.getElementsByClassName(classForAdd)[0].removeChild(script);
        }
    }, [url, pageID, classForAdd, theme])
}

export default useScript;