import axios from "axios";
import { useState } from "react";

function AdminPanelAuth() {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    
    return (
        <section className="adminPanelAuth">
            <form className="adminPanelAuth__form" action="">
                <h1>You Admin?</h1>
                <input type="text" placeholder="Login" value={login} onChange={e => setLogin(e.target.value)} />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <button
                    onClick={e => {
                        e.preventDefault();
                        axios
                            .post(
                                "https://nft-one.art/api/auth/login",
                                {
                                    login,
                                    password,
                                },
                                {
                                    auth: {
                                        username: "odmen",
                                        password: "NFTflsy",
                                    },
                                },
                            )
                            .then(response => {
                                localStorage.setItem("adminToken", response.data.token);
                                window.location.reload();
                            })
                            .catch(error => {
                                alert("Неверные данные для входа");
                            });
                    }}>
                    Enter
                </button>
            </form>
        </section>
    );
}

export default AdminPanelAuth;