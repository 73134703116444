import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import RangeSlider from "../../components/RangeSlider";
import { useCallback, useContext, useEffect, useState } from "react";
import { ContextApp } from "../../Context";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import Checkbox from "../../components/Checkbox";
import { useParams } from "react-router";

function EditNFT() {
    const { changeTheme, theme } = useContext(ContextApp);

    const params = useParams();

    // EDITING SINGLE NFT
    const [filename, setFilename] = useState("");
    const [attrs, setAttrs] = useState([{ id: 1, name: "", value: "" }]); // Attributes

    const [nameNFT, setNameNFT] = useState(""); // Display Name
    const [tags, setTags] = useState(["Example"]); // Tags
    const [tagInput, setTagInput] = useState("");
    const [categories, setCategories] = useState([]); // Category
    const [description, setDescription] = useState(""); // Description
    const [royalties, setRoyalties] = useState(0); // Royalties

    const [previewHash, setPreviewHash] = useState(null)
    const [previewPath, setPreviewPath] = useState(null)

    const [configCategories, setConfigCategories] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        setPreviewPath(URL.createObjectURL(acceptedFiles[0]))
    }, [setPreviewPath])

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/webp": [".webp"],
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/svg+xml": [".svg"],
            "image/gif": [".gif"]
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 12582912,
        onDrop
    });

    // собираем категории из конфига, получаем текущий NFT через ID в URL
    useEffect(() => {
        axios
                .post("https://nft-one.art/api/config/get", {}, {
                    headers: {
                        Token: localStorage.getItem("tonhubToken") ? localStorage.getItem("tonhubToken") : localStorage.getItem("tonkeeperToken"),
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                })
                .then(response => {
                    setConfigCategories(response.data.defaults["nft_categories"].split("\n"))
                })
                .catch(error => {
                    console.log(error);
                });
        axios
            .post("https://nft-one.art/api/nfts/list", {
                filters: {
                    id: params.id,
                    is_foreign: false
                },
                subqueries: {
                    img: {}
                }
            }, {
                auth: {
                    username: "odmen",
                    password: "NFTflsy",
                },
            })
            .then(response => {
                let currentNFT = response.data.items[0]
                setNameNFT(currentNFT.name)
                setTags(currentNFT.tags)
                setDescription(currentNFT.info)
                setRoyalties(currentNFT.royalty_pct)
                document.getElementById("slider-popa").value = (currentNFT.royalty_pct * 10) + 100;
                setCategories(currentNFT.categories);
                setFilename(currentNFT?.img?.props?.origName)
                setPreviewHash(currentNFT?.img?.hash)
                if(Object.keys(currentNFT.attrs)[0] === "") {
                    setAttrs([{ id: 1, name: "", value: "" }])
                } else {
                    let zopa = [];
                    Object.keys(currentNFT.attrs).map((key, index) => zopa.push({id: index + 1, name: key, value: currentNFT.attrs[key]}))
                    setAttrs([...zopa])
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        if (fileRejections.length > 0) {
            toast.error(`Invalid file extension or size`, {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            });
        }
    }, [fileRejections])

    useEffect(() => {
        setFilename(acceptedFiles[0]?.path);
    }, [acceptedFiles]);

    const setAttrsChange = (e, type) => {
        let attrs_copy = [...attrs];
        if (type === "value") {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) == attr.id ? (attr.value = e.target.value) : null,
            );
        } else {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) == attr.id ? (attr.name = e.target.value) : null,
            );
        }
        setAttrs(attrs_copy);
    };

    // Добавляет теги
    function onChangeTagInput(e) {
        let currentValue = e.target.value;
        setTagInput(currentValue);
        if (currentValue[currentValue.length - 1] === " ") {
            setTagInput("");
            setTags([...tags, currentValue.slice(0, currentValue.length - 1)]);
        }
    }

    function deleteTag(e) {
        setTags(tags.filter(tag => e.target.getAttribute("data-tag") !== tag));
    }
    
    function deleteAttr(id) {
        let attrs_new = [];
        attrs.map(attr => (attr.id == Number(id) ? null : attrs_new.push(attr)));
        setAttrs(attrs_new);
    }

    const getFormatAttrsForBackend = () => {
        let new_attrs = {};
        attrs.map(attr => new_attrs[attr.name] = attr.value);
        return new_attrs;
    }

    const changeCategoriesArray = (e) => {
        let currentCategory = e.target.innerText;
        if (categories.includes(currentCategory)) {
            setCategories(categories.filter(cat => cat !== currentCategory));
        } else {
            setCategories([...categories, currentCategory]);
        }
    }

    const validationEnters = () => {
        if (nameNFT !== "") {
            return true;
        } else {
            return false;
        }
    };

    // обновляет НФТ
    const updateNFT = () => {
        if (validationEnters()) {
            const formData = new FormData();
            formData.append("json_data", JSON.stringify({
                items: [{
                    id: params.id,
                    img: "file",
                    name: nameNFT,
                    info: description,
                    royalty_pct: royalties,
                    // price: Number(priceNFT) * (10 ** 9),
                    attrs: getFormatAttrsForBackend(),
                    state: "new",
                    categories: categories,
                    tags: tags
                }]
            }));
            formData.append("file", acceptedFiles[0]);
            axios
                .post("https://nft-one.art/api/nfts/upsert", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Token: localStorage.getItem("tonhubToken") ? localStorage.getItem("tonhubToken") : localStorage.getItem("tonkeeperToken"),
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                })
                .then(response => {
                    toast.success(`NFT «${nameNFT}» update`, {
                        position: "bottom-right",
                        style: {
                            font: "400 21px/100% 'DM Sans'",
                        },
                    });
                    setTimeout(() => {
                        window.location.href = `/nft/${params.id}`
                        // resetEnters();
                    }, 1200)
                })
                .catch(error => {
                    console.log(error)
                    toast.error(`Server error: try later`, {
                        position: "bottom-right",
                        style: {
                            font: "400 21px/100% 'DM Sans'",
                        },
                    });
                });
        } else {
            toast.error(`Check the data`, {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            });
        }
    };

    return (
        <>
            <Header />
            <section
                className={`createNFT ${changeTheme("", "createNFT--dark")}`}
                style={{ backgroundColor: changeTheme("#fff", "#15191E") }}>
                <Toaster />
                <div className="createNFT__left">
                    <div
                        {...getRootProps({ className: "dropzone" })}
                        className="createNFT__left-download"
                        style={{
                            backgroundColor: changeTheme("#f4f6f9", "#1C2026"),
                            borderColor: changeTheme("#efefef", "#1c2026"),
                            color: changeTheme("#000", "#fff"),
                        }}>
                        <img
                            className="createNFT__left-download-img"
                            src={previewPath ? previewPath : previewHash ? `https://nft-one.art/api/files/thumb/?hash=${previewHash}&width=600&height=400` : "/img/sections/createNFT/drag-and-drop.svg"}
                            alt="Drag and drop NFT"
                        />
                        {filename ? (
                            <>
                                <p className="createNFT__left-download-drag">{filename.length > 12 ? filename.slice(filename.length - 12, filename.length) : filename}</p>
                            </>
                        ) : (
                            <>
                                <p className="createNFT__left-download-drag">Drag and drop photos here<br/><span style={{
                                    opacity: ".8",
                                    fontSize: "0.82em",
                                    fontWeight: "400"
                                }}>(.png, .jpg, .jpeg, .svg, .webp, .gif maximum size of 12mb)</span></p>
                                <p
                                    className="createNFT__left-download-or"
                                    style={{ color: changeTheme("#000", "rgba(255, 255, 255, 0.7)") }}>
                                    or
                                </p>
                            </>
                        )}
                        <button className="createNFT__left-download-browse">
                            <input {...getInputProps()} type="file" onClick={e => e.stopPropagation()}/>
                            Browse Files
                        </button>
                    </div>
                    <div
                        className="createNFT__left-attr"
                        style={{
                            backgroundColor: changeTheme("#f4f6f9", "#1C2026"),
                            borderColor: changeTheme("#efefef", "#1c2026"),
                            color: changeTheme("#000", "#fff"),
                        }}>
                        <h6 className="createNFT__left-attr-title">Attributes</h6>
                        <div className="createNFT__left-attr-box">
                            {window.innerWidth <= 768 ? (
                                attrs.map((item, index) => (
                                    <>
                                        <div className="createNFT__left-attr-box-name">
                                            <label>Name</label>
                                            <input type="text" placeholder={`${!index ? "Price" : ""}`} />
                                        </div>
                                        <div className="createNFT__left-attr-box-value">
                                            <label>Value</label>
                                            <div>
                                                <input type="text" placeholder={`${!index ? "10$" : ""}`} />
                                                {index !== 0 && (
                                                    <div
                                                        className="createNFT__left-attr-box-value-minus"
                                                        onClick={() => deleteAttr()}></div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <>
                                    <div className="createNFT__left-attr-box-name">
                                        <label>Name</label>
                                        {attrs.map((item, index) => (
                                            <input
                                                data-attrid={item.id}
                                                type="text"
                                                placeholder={`${!index ? "Price" : ""}`}
                                                value={item.name}
                                                onChange={e => setAttrsChange(e, "name")}
                                            />
                                        ))}
                                    </div>
                                    <div className="createNFT__left-attr-box-value">
                                        <label>Value</label>
                                        {attrs.map((item, index) => (
                                            <div>
                                                <input
                                                    data-attrid={item.id}
                                                    type="text"
                                                    placeholder={`${!index ? "10$" : ""}`}
                                                    value={item.value}
                                                    onChange={e => setAttrsChange(e, "value")}
                                                />
                                                {index !== 0 && (
                                                    <div
                                                        data-attrid={item.id}
                                                        className="createNFT__left-attr-box-value-minus"
                                                        onClick={e =>
                                                            deleteAttr(e.target.getAttribute("data-attrid"))
                                                        }></div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        <button
                            className="createNFT__left-attr-add"
                            onClick={() => {
                                setAttrs([...attrs, { id: attrs[attrs.length - 1].id + 1, name: "", value: "" }]);
                            }}>
                            + Add attribute
                        </button>
                    </div>
                </div>
                <div
                    className="createNFT__right"
                    style={{
                        backgroundColor: changeTheme("#f4f6f9", "#1C2026"),
                        borderColor: changeTheme("#efefef", "#1c2026"),
                        color: changeTheme("#000", "#fff"),
                    }}>
                    <h1 className="createNFT__right-title">Editing single nft</h1>
                    <form className="createNFT__right-form" action="">
                        <div className="createNFT__right-form-name">
                            <label>Display Name<span>*</span></label>
                            <input
                                type="text"
                                placeholder="NFT name"
                                value={nameNFT}
                                onChange={e => setNameNFT(e.target.value)}
                            />
                        </div>
                        <div className="createNFT__right-form-tags">
                            <label>Tags</label>
                            <div
                                className="createNFT__right-form-tags-box"
                                style={{
                                    background: changeTheme("#fff", "#1C2026"),
                                    borderColor: changeTheme("", "#596577"),
                                }}>
                                <div className="createNFT__right-form-tags-box-items">
                                    {tags.map((tag, index) => (
                                        <div
                                            key={index}
                                            className="createNFT__right-form-tags-box-items-item"
                                            style={{ backgroundColor: changeTheme("", "rgba(255, 255, 255, 0.13)") }}>
                                            {tag}
                                            <img
                                                data-tag={tag}
                                                src={`/img/sections/createNFT/cross-${theme}.svg`}
                                                alt="Tag"
                                                onClick={e => {
                                                    deleteTag(e);
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <input
                                        id="tags-input"
                                        type="text"
                                        onChange={e => {
                                            onChangeTagInput(e);
                                        }}
                                        value={tagInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="createNFT__right-form-categories">
                            <label>Categories</label>
                            <div>
                                {configCategories.map(category => <Checkbox hasInArray={categories.includes(category)} text={category} onClick={e => changeCategoriesArray(e)}/>)}
                            </div>
                        </div>
                        <div className="createNFT__right-form-description">
                            <label>Description<span>*</span></label>
                            <textarea value={description} onChange={e => setDescription(e.target.value)}></textarea>
                        </div>
                        <div className="createNFT__right-form-royalties">
                            <label>Royalties</label>
                            <RangeSlider setValue={val => setRoyalties(val)} />
                        </div>
                        <div className="createNFT__right-form-buttons">
                            <input
                                className="createNFT__right-form-buttons-create"
                                type="button"
                                onClick={updateNFT}
                                value="SAVE"
                            />
                            <input
                                className={`createNFT__right-form-buttons-reset ${changeTheme(
                                    "",
                                    "createNFT__right-form-buttons-reset--dark",
                                )}`}
                                type="button"
                                value="RESET"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            />
                        </div>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default EditNFT;
