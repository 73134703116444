import axios from "axios";
import { useState, createContext, useEffect } from "react";

export const ContextApp = createContext();

function Context(props) {
    const [theme, setTheme] = useState("light");
    const [popup, setPopup] = useState(false);
    const [auth, setAuth] = useState(false);

    const [authPopup, setAuthPopup] = useState(false);

    const [globalExtraFilters, setGlobalExtraFilters] = useState([])

    /*
        Стейты для фильтров
    */
    const [filterSearch, setFilterSearch] = useState("");
    const [filterStatus, setFilterStatus] = useState("all");
    const [filterQuantity, setFilterQuantity] = useState("all");
    const [filterPriceAt, setFilterPriceAt] = useState("");
    const [filterPriceTo, setFilterPriceTo] = useState("");
    const [filterRarity, setFilterRarity] = useState([]);
    const [filterEmotional, setFilterEmotional] = useState();
    const [filterCategory, setFilterCategory] = useState([]);

    const [NFTs, setNFTs] = useState([]);
    const [collections, setCollections] = useState([]);

    const [currentCollection, setCurrentCollection] = useState(null);

    const [currentUser, setCurrentUser] = useState({});

    const [allUsers, setAllUsers] = useState([]);

    const [cfg, setCfg] = useState();

    useEffect(() => {
        if (auth || localStorage.getItem("auth") === "true") {
            axios
                .post(
                    "https://nft-one.art/api/auth/current",
                    {
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken")
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => setCurrentUser(response.data.user))
                .catch(err => { });
        }
    }, [auth]);

    const getAllOffsets = async (endpointBeforeList, aData={}, aHeaders = "user") => {
        let items = []
        let numberOffsets = 0;

        const firstResponse = await axios.post(
                `https://nft-one.art/api/${endpointBeforeList}/list`,
                {
                    ...aData,
                    calc_total: true
                },
                {
                    headers: {
                        Token: aHeaders === "user" ? (localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken")) : localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
        }})

        items = [...items, ...firstResponse.data.items]

        if (Number(firstResponse.data.total) > 99) {
            numberOffsets = Math.floor((Number(firstResponse.data.total) / 100))
        } else {
            numberOffsets = 0
        }

        if (numberOffsets > 0) {
            for (let start = 1; start <= numberOffsets; start++) {
                    let res = await axios
                        .post(
                            `https://nft-one.art/api/${endpointBeforeList}/list`,
                            {
                                ...aData,
                                offset: start * 100
                            },
                            {
                                headers: {
                                    Token: aHeaders === "user" ? (localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken")) : localStorage.getItem("adminToken")
                                },
                                auth: {
                                    username: "odmen",
                                    password: "NFTflsy",
                                },
                            })
                    items = [...items, ...res.data.items]
            }
        }
        return items.reverse();
    }

    useEffect(() => {
        getAllOffsets("users", {subqueries: {img: {}}}).then(res => setAllUsers(res.reverse()))
        axios
            .post(
                "https://nft-one.art/api/batch",
                {
                    "cfg": {
                        action: "config/get"
                    }
                },
                {
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setCfg(response.data["cfg"])
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const changeTheme = (forLight, forDark) => {
        return theme === "light" ? forLight : forDark;
    };

    return (
        <ContextApp.Provider
            value={{
                currentCollection,
                setCurrentCollection,
                theme,
                setTheme,
                popup,
                setPopup,
                changeTheme,
                auth,
                setAuth,
                NFTs,
                setNFTs,
                collections,
                setCollections,
                filterStatus,
                setFilterStatus,
                filterQuantity,
                setFilterQuantity,
                filterPriceAt,
                setFilterPriceAt,
                filterPriceTo,
                setFilterPriceTo,
                filterRarity,
                setFilterRarity,
                filterEmotional,
                setFilterEmotional,
                filterCategory,
                setFilterCategory,
                currentUser,
                setCurrentUser,
                allUsers,
                authPopup,
                setAuthPopup,
                globalExtraFilters,
                setGlobalExtraFilters,
                filterSearch,
                setFilterSearch,
                cfg,
                getAllOffsets
            }}>
            {props.children}
        </ContextApp.Provider>
    );
}

export default Context;
