import { useState, useContext } from "react";
import { ContextApp } from "../../../../Context";

function AdminPanelHeader({ setAdminPanelAuth }) {
    const { theme, setTheme, changeTheme, currentUser } = useContext(ContextApp);
    const [adminPopup, setAdminPopup] = useState(false);

    return (
        <header className="adminPanel__header" style={{ background: changeTheme("", "#1C2026") }}>
            <a href="/">
                <img className="adminPanel__header-logo" src="./img/header/header-logo.svg" alt="" />
            </a>
            <div className="adminPanel__header-btns">
                <button onClick={() => setAdminPopup(!adminPopup)}>
                    You are logged in as {currentUser?.user_role}
                    <img
                        src="./img/header/arrow-light.svg"
                        alt=""
                        style={{ transform: adminPopup ? "rotate(180deg)" : "rotate(0deg)" }}
                    />
                    {adminPopup && (
                        <ul className="adminPanel__header-btns-popup">
                            <li className="adminPanel__header-btns-popup-item">
                                <img src="/img/header/exit-light.svg" alt="" />
                                <p
                                    onClick={() => {
                                        setAdminPanelAuth(true);
                                        localStorage.removeItem("adminToken");
                                    }}>
                                    Exit
                                </p>
                            </li>
                        </ul>
                    )}
                </button>
                <img
                    className="adminPanel__header-btns-theme"
                    onClick={() => setTheme(theme === "light" ? "dark" : "light")}
                    src={`./img/header/${theme}-theme.svg`}
                    alt=""
                />
            </div>
        </header>
    );
}

export default AdminPanelHeader;
