import { useContext, useEffect, useState } from "react";
import { ContextApp } from "../../Context";
import "./Header.scss";
import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import { Popup } from "./../Popup";

import TonConnect, {
	toUserFriendlyAddress,
	isWalletInfoCurrentlyEmbedded,
} from "@tonconnect/sdk";
import { memo } from "react";
import { toast } from "react-hot-toast";

function Header({ currentPage }) {
	const [openMobileMenu, setOpenMobileMenu] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [popup, setPopup] = useState(false);
	const [userMenu, setUserMenu] = useState(false);
	const [searchFilter, setSearchFilter] = useState("nft");
	const [NFTs, setNFTs] = useState([]);
	const [collections, setCollections] = useState([]);

	const {
		theme,
		setTheme,
		changeTheme,
		auth,
		setAuth,
		authPopup,
		setAuthPopup,
		cfg,
		getAllOffsets,
	} = useContext(ContextApp);

	const [tonkeeperQR, setTonkeeperQR] = useState(false);
	const [tonkeeperUniversalLink, setTonkeeperUniversalLink] = useState("");

	const [tonhubData, setTonhubData] = useState({});
	const [tonhubQR, setTonhubQR] = useState(false);

	const [currentUser, setCurrentUser] = useState({});
	const [showUserBanned, setShowUserBanned] = useState(false);
	const [avatarHash, setAvatarHash] = useState();

	const [configFooterSocialLinks, setConfigFooterSocialLinks] = useState("");

	useEffect(() => {
		if (cfg) {
			setConfigFooterSocialLinks(
				cfg?.values?.footer_social_links?.split(","),
			);
		}
	}, [cfg]);

	// take currentUser and set avatar in header, if auth is true
	useEffect(() => {
		if (
			localStorage.getItem("auth") === "true" &&
			(localStorage.getItem("tonhubToken") ||
				localStorage.getItem("tonkeeperToken"))
		) {
			axios
				.post(
					"https://nft-one.art/api/auth/current",
					{},
					{
						headers: {
							Token: localStorage.getItem("tonkeeperToken")
								? localStorage.getItem("tonkeeperToken")
								: localStorage.getItem("tonhubToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					setCurrentUser(response.data.user);
					setAvatarHash(response.data.user.img?.hash);
				})
				.catch((error) => {
					console.log("Ошибка авторизации:", error);
					if (
						localStorage.getItem("tonkeeperToken") ||
						localStorage.getItem("tonhubToken")
					) {
						setAuth(false);
						localStorage.setItem("auth", false);
						setUserMenu(false);
						localStorage.removeItem("tonkeeperUsername");
						localStorage.removeItem("tonkeeperToken");
						localStorage.removeItem("tonhubUsername");
						localStorage.removeItem("tonhubToken");
						localStorage.removeItem(
							"ton-connect-storage_http-bridge-gateway",
						);
						localStorage.removeItem(
							"ton-connect-storage_bridge-connection",
						);
					}
				});
		}
	}, []);

	// Проверка при старте страницы на то, авторизован ли пользователь (отслеживаем наличие токенов в localStorage)
	useEffect(() => {
		if (
			localStorage.getItem("tonkeeperToken") ||
			localStorage.getItem("tonhubToken")
		) {
			setAuth(true);
			localStorage.setItem("auth", true);
		} else {
			setAuth(false);
			localStorage.setItem("auth", false);
		}
	}, []);

	// Здесь ставим тему из localStorage (светлую или темную) и смотрим на auth
	useEffect(() => {
		setTheme(
			localStorage.getItem("theme") === null
				? "light"
				: localStorage.getItem("theme"),
		);
		setAuth(localStorage.getItem("auth"));

		if (!["dark", "light"].includes(localStorage.getItem("theme"))) {
			if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
				// Theme set to dark.
				localStorage.setItem("theme", "dark");
				setTheme("dark");
			} else {
				// Theme set to light.
				localStorage.setItem("theme", "light");
				setTheme("light");
			}
		}
	}, []);

	const userExit = () => {
		setAuth(false);
		localStorage.setItem("auth", false);
		setUserMenu(false);
		localStorage.removeItem("tonkeeperUsername");
		localStorage.removeItem("tonkeeperToken");
		localStorage.removeItem("tonhubUsername");
		localStorage.removeItem("tonhubToken");
		localStorage.removeItem("ton-connect-storage_http-bridge-gateway");
		localStorage.removeItem("ton-connect-storage_bridge-connection");
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	const handleConnectWallet = () => {
		const connector = new TonConnect({
			manifestUrl:
				"https://grizli-rgb.github.io/meta-info-nftone/tonconnect-manifest.json",
		});

		connector.getWallets().then((res) => {
			// если мы в браузере мобильного кошелька Тонкипер
			if (res.find(isWalletInfoCurrentlyEmbedded)) {
				axios
					.post(
						"https://nft-one.art/api/auth/start_tonkeeper",
						{},
						{
							auth: {
								username: "odmen",
								password: "NFTflsy",
							},
						},
					)
					.then(function (response) {
						const walletConnectionSource = {
							jsBridgeKey: "tonkeeper",
						};

						connector.connect(
							walletConnectionSource,
							{ tonProof: response.data.token },
						);

						connector.onStatusChange((walletInfo) => {
							axios
								.post(
									"https://nft-one.art/api/auth/check_tonkeeper",
									{
										ton_addr:
											walletInfo.account.address.toString(),
										state_init:
											walletInfo.account.walletStateInit,
										proof: walletInfo.connectItems.tonProof
											.proof,
									},
									{
										headers: {
											Token: response.data.token,
										},
										auth: {
											username: "odmen",
											password: "NFTflsy",
										},
									},
								)
								.then(function (res) {
									setAuth(true);
									localStorage.setItem("auth", true);
									localStorage.setItem(
										"tonkeeperToken",
										response.data.token,
									);
									localStorage.setItem(
										"tonkeeperUsername",
										toUserFriendlyAddress(
											walletInfo.account.address,
										),
									);
									window.location.reload();
								})
								.catch(function (error) {
									if (
										error?.response?.data?.error ===
										"user-blocked"
									) {
										setAuth(false);
										setTonkeeperQR(false);
										localStorage.setItem("auth", false);
										setUserMenu(false);
										setPopup(false);
										localStorage.removeItem(
											"tonkeeperUsername",
										);
										localStorage.removeItem(
											"tonkeeperToken",
										);
										localStorage.removeItem(
											"tonhubUsername",
										);
										localStorage.removeItem("tonhubToken");
										localStorage.removeItem(
											"ton-connect-storage_http-bridge-gateway",
										);
										localStorage.removeItem(
											"ton-connect-storage_bridge-connection",
										);
										setShowUserBanned(true);
									} else {
										console.log("Ошибка: ", error);
										toast.error("Server error: try later");
									}
								});
						});

						connector.restoreConnection();
					})
					.catch(function (error) {
						console.log(
							"Ошибка получения токена с сервера: ",
							error,
						);
					});
			} else {
				setPopup(true);
			}
		});
	};

	const tonkeeperAuthClick = () => {
		axios
			.post(
				"https://nft-one.art/api/auth/start_tonkeeper",
				{},
				{
					auth: {
						username: "odmen",
						password: "NFTflsy",
					},
				},
			)
			.then(function (response) {
				const connector = new TonConnect({
					manifestUrl:
						"https://grizli-rgb.github.io/meta-info-nftone/tonconnect-manifest.json",
				});
				setTonkeeperQR(true);
				const walletConnectionSource = {
					universalLink: "https://app.tonkeeper.com/ton-connect",
					bridgeUrl: "https://bridge.tonapi.io/bridge",
				};
				setTonkeeperUniversalLink(
					connector.connect(walletConnectionSource, {
						tonProof: response.data.token,
					}),
				);
				connector.onStatusChange((walletInfo) => {
					axios
						.post(
							"https://nft-one.art/api/auth/check_tonkeeper",
							{
								ton_addr: walletInfo.account.address.toString(),
								state_init: walletInfo.account.walletStateInit,
								proof: walletInfo.connectItems.tonProof.proof,
							},
							{
								headers: {
									Token: response.data.token,
								},
								auth: {
									username: "odmen",
									password: "NFTflsy",
								},
							},
						)
						.then(function (res) {
							setPopup(false);
							setTonkeeperQR(false);
							setAuth(true);
							localStorage.setItem("auth", true);
							localStorage.setItem(
								"tonkeeperToken",
								response.data.token,
							);
							localStorage.setItem(
								"tonkeeperUsername",
								toUserFriendlyAddress(
									walletInfo.account.address,
								),
							);
							window.location.reload();
						})
						.catch(function (error) {
							if (
								error?.response?.data?.error === "user-blocked"
							) {
								setAuth(false);
								setTonkeeperQR(false);
								localStorage.setItem("auth", false);
								setUserMenu(false);
								setPopup(false);
								localStorage.removeItem("tonkeeperUsername");
								localStorage.removeItem("tonkeeperToken");
								localStorage.removeItem("tonhubUsername");
								localStorage.removeItem("tonhubToken");
								localStorage.removeItem(
									"ton-connect-storage_http-bridge-gateway",
								);
								localStorage.removeItem(
									"ton-connect-storage_bridge-connection",
								);
								setShowUserBanned(true);
							} else {
								console.log("Ошибка: ", error);
								toast.error("Server error: try later");
							}
						});
				});
				connector.restoreConnection();
			})
			.catch(function (error) {
				console.log("Ошибка получения токена с сервера: ", error);
			});
	};

	const tonhubAuthClick = () => {
		axios
			.post(
				"https://nft-one.art/api/auth/start_tonhub",
				{},
				{
					auth: {
						username: "odmen",
						password: "NFTflsy",
					},
				},
			)
			.then(function (response) {
				setTonhubData(response.data);
				setTonhubQR(true);
				const checkTonhubAuth = setInterval(() => {
					axios
						.post(
							"https://nft-one.art/api/auth/check_tonhub",
							{},
							{
								headers: {
									Token: response.data.token,
								},
								auth: {
									username: "odmen",
									password: "NFTflsy",
								},
							},
						)
						.then(function (res) {
							if (res.data.ok) {
								localStorage.setItem(
									"tonhubToken",
									response.data.token,
								);
								localStorage.setItem(
									"tonhubUsername",
									res.data.user.name,
								);
								setPopup(false);
								setTonhubQR(false);
								setAuth(true);
								localStorage.setItem("auth", true);
								clearInterval(checkTonhubAuth);
								window.location.reload();
							}
						})
						.catch(function (error) {
							if (error.response.data.error === "user-blocked") {
								clearInterval(checkTonhubAuth);
								setAuth(false);
								setTonhubQR(false);
								localStorage.setItem("auth", false);
								setUserMenu(false);
								setPopup(false);
								localStorage.removeItem("tonkeeperUsername");
								localStorage.removeItem("tonkeeperToken");
								localStorage.removeItem("tonhubUsername");
								localStorage.removeItem("tonhubToken");
								localStorage.removeItem(
									"ton-connect-storage_http-bridge-gateway",
								);
								localStorage.removeItem(
									"ton-connect-storage_bridge-connection",
								);
								setShowUserBanned(true);
							} else {
								console.log("Ошибка: ", error);
							}
						});
				}, 1200);
			})
			.catch(function (error) {
				console.log("Ошибка получения токена с сервера: ", error);
			});
	};

	useEffect(() => {
		getAllOffsets("nfts", {
			filters: {
				is_hidden: false,
				is_foreign: false,
			},
			subqueries: {
				img: {},
			},
		}).then((res) => setNFTs(res));
		getAllOffsets("nft_collections", {
			filters: {
				is_hidden: false,
				is_foreign: false,
			},
			subqueries: {
				img: {},
			},
		}).then((res) => setCollections(res));
	}, [getAllOffsets]);

	useEffect(() => {
		if (theme === "dark") {
			document.getElementById("switch").checked = true;
		} else {
			document.getElementById("switch").checked = false;
		}
	}, [theme]);

	useEffect(() => {
		if (popup) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
			document.body.style.overflowX = "hidden";
		}
	}, [popup, setPopup]);

	useEffect(() => {
		if (openMobileMenu) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
			document.body.style.overflowX = "hidden";
		}
	}, [openMobileMenu]);

	useEffect(() => {
		setPopup(authPopup);
	}, [authPopup]);

	return (
		<header
			className="header"
			style={{
				backgroundColor: theme === "light" ? "#004f87" : "#1C2026",
			}}>
			{!openSearch && (
				<div className="header__logo">
					<a href="/">
						<img src="/img/header/header-logo.svg" alt="Logo" />
					</a>
				</div>
			)}
			<nav className="header__menu">
				{["Catalog", "FAQ"].map((item, index) => (
					<a
						key={index}
						href={`/${item.toLowerCase()}`}
						className={`header__menu-link ${
							theme === "light" ? "" : "header__menu-link--dark"
						} ${
							currentPage === item.toLocaleLowerCase()
								? "header__menu-link--active"
								: ""
						}`}>
						{item}
					</a>
				))}
			</nav>
			<div className="header__search">
				<input
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					type="text"
					placeholder="Search"
					style={{
						backgroundColor: theme === "light" ? "#fff" : "#272E37",
						borderColor: theme === "light" ? "#efefef" : "#373F4A",
						color: changeTheme("", "#fff"),
					}}
				/>
				<img
					src={`/img/header/${
						searchQuery !== "" ? "clear" : "search"
					}.svg`}
					alt=""
					onClick={() => setSearchQuery("")}
				/>
				{searchQuery !== "" && (
					<div
						className="header__search-popup"
						style={{ backgroundColor: changeTheme("", "#1C2026") }}>
						<div className="header__search-popup-btns">
							<button
								style={{
									backgroundColor: changeTheme("", "#313A42"),
									color: changeTheme("", "#fff"),
								}}
								onClick={() => {
									setSearchFilter("nft");
								}}
								className={`header__search-popup-btns-nfts ${
									searchFilter === "nft"
										? "header__search-popup-btns-nfts--active"
										: ""
								}`}>
								NFTs
							</button>
							<button
								style={{
									backgroundColor: changeTheme("", "#313A42"),
									color: changeTheme("", "#fff"),
								}}
								onClick={() => setSearchFilter("collection")}
								className={`header__search-popup-btns-collections ${
									searchFilter === "collection"
										? "header__search-popup-btns-collections--active"
										: ""
								}`}>
								Collections
							</button>
						</div>
						<ul className="header__search-popup-list">
							{searchFilter === "nft"
								? NFTs.filter(
										(nft) =>
											nft.name
												.toLowerCase()
												.includes(
													searchQuery.toLowerCase(),
												) && searchQuery !== "",
								  ).map((nft) => (
										<li
											className="header__search-popup-list-item"
											key={nft?.id}>
											<a href={`/nft/${nft.id}`}>
												<div
													style={{
														background: `${`url(https://nft-one.art/api/files/thumb/?hash=${nft.img?.hash}&width=70&height=55) no-repeat center center/cover`}`,
													}}></div>
												<p
													style={{
														color: changeTheme(
															"",
															"#fff",
														),
													}}>
													{nft?.name}
												</p>
											</a>
										</li>
								  ))
								: collections
										.filter(
											(collection) =>
												collection.name
													.toLowerCase()
													.includes(
														searchQuery.toLowerCase(),
													) && searchQuery !== "",
										)
										.map((collection) => (
											<li
												className="header__search-popup-list-item"
												key={collection?.id}>
												<a
													href={`/collection/${collection.id}`}>
													<div
														style={{
															background: `${`url(https://nft-one.art/api/files/thumb/?hash=${collection.img?.hash}&width=70&height=55) no-repeat center center/cover`}`,
														}}></div>
													<p
														style={{
															color: changeTheme(
																"",
																"#fff",
															),
														}}>
														{collection?.name}
													</p>
												</a>
											</li>
										))}
						</ul>
						<a
							className="header__search-popup-all"
							href={`/catalog?searchQuery=${searchQuery}?${searchFilter}`}>
							See all results
						</a>
					</div>
				)}
			</div>
			{!openSearch && (
				<div className="header__buttons">
					{!auth || auth === "false" ? (
						<button
							onClick={() => handleConnectWallet()}
							className="header__buttons-connect">
							{window.innerWidth <= 1440
								? "Connect"
								: "Connect wallet"}
						</button>
					) : (
						<>
							<button
								className="header__buttons-user"
								onClick={() => setUserMenu(!userMenu)}>
								<div
									className="header__buttons-user-avatar"
									style={{
										background: avatarHash
											? `url(https://nft-one.art/api/files/thumb/?hash=${avatarHash}&width=50&height=50) no-repeat center center / cover`
											: "url(/img/sections/myNFT/avatar.svg) no-repeat center center / cover",
									}}></div>
								{window.innerWidth <= 768
									? currentUser?.name?.slice(0, 9) + "..."
									: currentUser?.name?.length < 20
									? currentUser?.name
									: currentUser?.name?.slice(0, 20) + "..."}
								<img
									className="header__buttons-user-arrow"
									src="/img/header/arrow.svg"
									alt=""
									style={{
										transform: `rotate(${
											userMenu ? "180deg" : "0deg"
										})`,
									}}
								/>
							</button>
							{userMenu && (
								<div
									className={`header__buttons-userMenu ${changeTheme(
										"",
										"header__buttons-userMenu--dark",
									)}`}>
									<a
										href="/edit-profile"
										className="header__buttons-userMenu-item">
										<img
											src={`/img/header/profile-${theme}.svg`}
											alt=""
										/>
										<p className="header__buttons-userMenu-item-link">
											Profile
										</p>
									</a>
									<a
										href="/edit-profile#collections"
										className="header__buttons-userMenu-item">
										<img
											src={`/img/header/collections-${theme}.svg`}
											alt=""
										/>
										<p className="header__buttons-userMenu-item-link">
											My Collections
										</p>
									</a>
									<a
										href="/create-nft"
										className="header__buttons-userMenu-item">
										<img
											src={`/img/header/create-${theme}.svg`}
											alt=""
										/>
										<p className="header__buttons-userMenu-item-link">
											Create NFT
										</p>
									</a>
									<a
										href="/create-collection"
										className="header__buttons-userMenu-item">
										<img
											src={`/img/header/createCollection-${theme}.svg`}
											alt=""
										/>
										<p className="header__buttons-userMenu-item-link">
											Create Collection
										</p>
									</a>
									<a
										href="/edit-profile#favorites"
										className="header__buttons-userMenu-item">
										<img
											src={`/img/header/favorite-${theme}.svg`}
											alt=""
										/>
										<p className="header__buttons-userMenu-item-link">
											Favorites
										</p>
									</a>
									<a
										href="#"
										className="header__buttons-userMenu-item"
										onClick={() => {
											userExit();
										}}>
										<img
											src={`/img/header/exit-${theme}.svg`}
											alt=""
										/>
										<p className="header__buttons-userMenu-item-link">
											Exit
										</p>
									</a>
								</div>
							)}
						</>
					)}
					<button className="header__buttons-theme">
						<img
							onClick={() => {
								setTheme(theme === "light" ? "dark" : "light");
								localStorage.setItem(
									"theme",
									theme === "light" ? "dark" : "light",
								);
							}}
							src={`/img/header/${theme}-theme.png`}
							alt={`${theme} theme`}
						/>
					</button>
				</div>
			)}
			{!openSearch && (
				<div className="header__mobile">
					<button
						className="header__mobile-search"
						onClick={() => setOpenSearch(true)}>
						<img src="/img/header/search-white.svg" alt="Search" />
					</button>
					<div
						className="header__mobile-hamburger"
						onClick={() => setOpenMobileMenu(true)}>
						<div className="header__mobile-hamburger-line"></div>
						<div className="header__mobile-hamburger-line"></div>
						<div className="header__mobile-hamburger-line"></div>
					</div>
				</div>
			)}
			<div
				className="header__mobileMenu"
				style={{
					right: openMobileMenu ? "0" : "-600px",
					color: theme === "light" ? "#000" : "#fff",
					backgroundColor: theme === "light" ? "#fff" : "#1C2026",
				}}>
				<img
					className="header__mobileMenu-close"
					src={`/img/header/${
						theme === "light" ? "close" : "close-white"
					}.png`}
					alt="Close"
					onClick={() => setOpenMobileMenu(false)}
				/>
				<ul className="header__mobileMenu-list">
					<li className="header__mobileMenu-list-item">
						<a
							className="header__mobileMenu-list-item-link"
							href="/catalog">
							Catalog
						</a>
					</li>
					<li className="header__mobileMenu-list-item">
						<a
							className="header__mobileMenu-list-item-link"
							href="/faq">
							FAQ
						</a>
					</li>
					<li className="header__mobileMenu-list-item header__mobileMenu-list-item-mode">
						<a
							className="header__mobileMenu-list-item-link"
							href="#">
							Dark mode
						</a>
						<div className="header__mobileMenu-list-item-switch">
							<input type="checkbox" id="switch" />
							<label
								id="switch-label"
								onClick={() => {
									setTimeout(() => {
										let newTheme = changeTheme(
											"dark",
											"light",
										);
										setTheme(newTheme);
										localStorage.setItem("theme", newTheme);
									}, 500);
								}}
								htmlFor="switch">
								Toggle
							</label>
						</div>
					</li>
					{localStorage.getItem("auth") === "true" && (
						<>
							<li className="header__mobileMenu-list-item header__mobileMenu-list-item-authTrue">
								<img
									src={`/img/header/profile-${theme}.svg`}
									alt=""
								/>
								<a
									className="header__mobileMenu-list-item-link"
									href="/edit-profile">
									Profile
								</a>
							</li>
							<li className="header__mobileMenu-list-item header__mobileMenu-list-item-authTrue">
								<img
									src={`/img/header/collections-${theme}.svg`}
									alt=""
								/>
								<a
									className="header__mobileMenu-list-item-link"
									href="/edit-profile#collections">
									My Collections
								</a>
							</li>
							<li className="header__mobileMenu-list-item header__mobileMenu-list-item-authTrue">
								<img
									src={`/img/header/create-${theme}.svg`}
									alt=""
								/>
								<a
									className="header__mobileMenu-list-item-link"
									href="/create-nft">
									Create NFT
								</a>
							</li>
							<li className="header__mobileMenu-list-item header__mobileMenu-list-item-authTrue">
								<img
									src={`/img/header/createCollection-${theme}.svg`}
									alt=""
								/>
								<a
									className="header__mobileMenu-list-item-link"
									href="/create-collection">
									Create Collection
								</a>
							</li>
							<li className="header__mobileMenu-list-item header__mobileMenu-list-item-authTrue">
								<img
									src={`/img/header/favorite-${theme}.svg`}
									alt=""
								/>
								<a
									className="header__mobileMenu-list-item-link"
									href="/edit-profile#favorites">
									Favorites
								</a>
							</li>
							<li
								className="header__mobileMenu-list-item header__mobileMenu-list-item-authTrue"
								onClick={() => {
									userExit();
								}}>
								<img
									src={`/img/header/exit-${theme}.svg`}
									alt=""
								/>
								<a
									className="header__mobileMenu-list-item-link"
									href="#">
									Exit
								</a>
							</li>
						</>
					)}
				</ul>
				<div className="header__mobileMenu-down">
					{localStorage.getItem("auth") === "false" && (
						<button
							className="header__mobileMenu-connect"
							onClick={() => handleConnectWallet()}>
							Connect
						</button>
					)}
					<div className="header__mobileMenu-items">
						{configFooterSocialLinks !== "" &&
							[
								"vk",
								"telegram",
								"twitter",
								"reddit",
								"discord",
							].map((social, index) => {
								if (configFooterSocialLinks[index] !== "0") {
									let hrefA =
										"https://" +
										(index === 0
											? "vk.com/"
											: index === 1
											? "t.me/"
											: index === 2
											? "twitter.com/"
											: index === 3
											? "reddit.com/"
											: index === 4
											? "discord.gg/"
											: null) +
										configFooterSocialLinks[index];
									return (
										<a
											href={hrefA}
											target="_blank"
											rel="noreferrer"
											key={index}>
											<img
												src={`/img/footer/${social}.svg`}
												alt={social}
												className="header__mobileMenu-items-item"
											/>
										</a>
									);
								}
							})}
					</div>
				</div>
			</div>
			<div
				className="header__mobileSearch"
				style={{ left: openSearch ? "58px" : "958px" }}>
				<input
					className="header__mobileSearch-enter"
					type="text"
					placeholder="Search"
					onChange={(e) => setSearchQuery(e.target.value)}
					value={searchQuery}
					style={{
						backgroundColor: theme === "light" ? "#fff" : "#272E37",
						borderColor: theme === "light" ? "#efefef" : "#373F4A",
						color: theme === "light" ? "#2d333e" : "#fff",
					}}
				/>
				<img
					className="header__mobileSearch-back"
					src="/img/header/arrow-left.png"
					alt="Back"
					onClick={() => setOpenSearch(false)}
				/>
				<img
					className="header__mobileSearch-clear"
					src="/img/header/close-grey.png"
					alt="Clear"
					onClick={() => setSearchQuery("")}
				/>
				{searchQuery !== "" && (
					<div
						className="header__search-popup"
						style={{ backgroundColor: changeTheme("", "#272E37") }}>
						<div className="header__search-popup-btns">
							<button
								style={{
									backgroundColor: changeTheme("", "#313A42"),
									color: changeTheme("", "#fff"),
								}}
								onClick={() => {
									setSearchFilter("nft");
								}}
								className={`header__search-popup-btns-nfts ${
									searchFilter === "nft"
										? "header__search-popup-btns-nfts--active"
										: ""
								}`}>
								NFTs
							</button>
							<button
								style={{
									backgroundColor: changeTheme("", "#313A42"),
									color: changeTheme("", "#fff"),
								}}
								onClick={() => setSearchFilter("collection")}
								className={`header__search-popup-btns-collections ${
									searchFilter === "collection"
										? "header__search-popup-btns-collections--active"
										: ""
								}`}>
								Collections
							</button>
						</div>
						<ul
							className="header__search-popup-list"
							style={{ color: changeTheme("", "#fff") }}>
							{searchFilter === "nft"
								? NFTs.filter(
										(nft) =>
											nft.name
												.toLowerCase()
												.includes(
													searchQuery.toLowerCase(),
												) && searchQuery !== "",
								  ).map((nft) => (
										<li
											className="header__search-popup-list-item"
											key={nft?.id}>
											<a href={`/nft/${nft.id}`}>
												<div
													style={{
														background: `${`url(https://nft-one.art/api/files/thumb/?hash=${nft.img?.hash}&width=70&height=55) no-repeat center center/cover`}`,
													}}></div>
												<p>{nft?.name}</p>
											</a>
										</li>
								  ))
								: collections
										.filter(
											(collection) =>
												collection.name
													.toLowerCase()
													.includes(
														searchQuery.toLowerCase(),
													) && searchQuery !== "",
										)
										.map((collection) => (
											<li
												className="header__search-popup-list-item"
												key={collection?.id}>
												<a
													href={`/collection/${collection.id}`}>
													<div
														style={{
															background: `${`url(https://nft-one.art/api/files/thumb/?hash=${collection.img?.hash}&width=70&height=55) no-repeat center center/cover`}`,
														}}></div>
													<p>{collection?.name}</p>
												</a>
											</li>
										))}
						</ul>
						<a
							className="header__search-popup-all"
							href={`/catalog?searchQuery=${searchQuery}?${searchFilter}`}>
							See all results
						</a>
					</div>
				)}
			</div>
			{popup && (
				<>
					<div className="connect">
						<div
							className="connect__popup"
							style={{
								backgroundColor:
									theme === "light" ? "#fff" : "#1C2026",
								padding: `${tonhubQR ? "65px" : ""}`,
							}}>
							{tonhubQR ? (
								<>
									<img
										onClick={() => {
											setAuthPopup(false);
											setPopup(false);
											setTonhubQR(false);
										}}
										className="connect__popup-img"
										src={`/img/header/${
											theme === "light"
												? "close"
												: "close-white"
										}.png`}
										alt="Close"
									/>
									<h6
										className="connect__popup-title-qr"
										style={{
											color:
												theme === "light"
													? "#000"
													: "#fff",
										}}>
										Click or scan with Tonhub
									</h6>
									<a href={tonhubData?.link}>
										<QRCodeSVG
											fgColor={changeTheme(
												"#000",
												"#fff",
											)}
											bgColor={changeTheme(
												"#fff",
												"#0088CC",
											)}
											value={tonhubData?.link}
											size={200}
											imageSettings={{
												src: "/img/sections/connect/crystal.png",
											}}
										/>
									</a>
								</>
							) : tonkeeperQR ? (
								<>
									<img
										onClick={() => {
											setAuthPopup(false);
											setPopup(false);
											setTonkeeperQR(false);
										}}
										className="connect__popup-img"
										src={`/img/header/${
											theme === "light"
												? "close"
												: "close-white"
										}.png`}
										alt="Close"
									/>
									<h6
										className="connect__popup-title-qr"
										style={{
											color:
												theme === "light"
													? "#000"
													: "#fff",
										}}>
										Click or scan with Tonkeeper
									</h6>
									<a href={tonkeeperUniversalLink}>
										<QRCodeSVG
											fgColor={changeTheme(
												"#000",
												"#fff",
											)}
											bgColor={changeTheme(
												"#fff",
												"#0088CC",
											)}
											value={tonkeeperUniversalLink}
											size={200}
											imageSettings={{
												src: "/img/sections/connect/diamond-big.png",
											}}
										/>
									</a>
								</>
							) : (
								<>
									<img
										onClick={() => {
											setAuthPopup(false);
											setPopup(false);
										}}
										className="connect__popup-img"
										src={`/img/header/${
											theme === "light"
												? "close"
												: "close-white"
										}.png`}
										alt="Close"
									/>
									<h6
										className="connect__popup-title"
										style={{
											color:
												theme === "light"
													? "#000"
													: "#fff",
										}}>
										Connect Wallet
									</h6>
									<p
										className="connect__popup-text"
										style={{
											color:
												theme === "light"
													? "rgba(0, 0, 0, 0.5)"
													: "rgba(255, 255, 255, 0.7)",
										}}>
										A wallet is a simple, anonymous way to
										log in. To create ('mint') or buy an
										NFT, you must connect a wallet or create
										a new one.
									</p>
									<button
										className="connect__popup-btn"
										onClick={() => {
											tonkeeperAuthClick();
										}}
										style={{
											color:
												theme === "light"
													? "#000"
													: "#fff",
											backgroundColor:
												theme === "light"
													? "#f4f4f4"
													: "#272E37",
										}}>
										Tonkeeper
									</button>
									<button
										className="connect__popup-btn"
										onClick={() => {
											tonhubAuthClick();
										}}
										style={{
											color:
												theme === "light"
													? "#000"
													: "#fff",
											backgroundColor:
												theme === "light"
													? "#f4f4f4"
													: "#272E37",
										}}>
										Tonhub
									</button>
								</>
							)}
						</div>
					</div>
				</>
			)}
			<Popup
				show={showUserBanned}
				onShow={(arg) => setShowUserBanned(arg)}>
				<img
					src="/img/header/circle-ex.svg"
					alt=""
					style={{
						width: "64px",
					}}
				/>
				<p
					style={{
						font: "500 18px/100% 'DM Sans'",
						marginTop: "20px",
					}}>
					This account is banned
				</p>
			</Popup>
		</header>
	);
}

export default memo(Header);
