import axios from "axios";
import axs from "../../../../utils/axios"
import { useState, useEffect, useContext } from "react";
import { ContextApp } from "../../../../Context";

function AdminPanelStatistics() {
    const [usersStat, setUsersStat] = useState({ all: 0, active: 0, banned: 0 });
    const [itemsStat, setItemsStat] = useState({ nfts: 0, collections: 0 });
    
    const [nftHistory, setNftHistory] = useState([]);
    const [TONinDollars, setTONinDollars] = useState(0);

    const [marketplaceTranscations, setMarketplaceTransactions] = useState(0)
    const [marketplaceRevenue, setMarketplaceRevenue] = useState(0);

    const { getAllOffsets } = useContext(ContextApp)

    useEffect(() => {
        getAllOffsets("nft_history", {}, "admin").then(res => setNftHistory(res))
        axios
            .post(
                "https://nft-one.art/api/batch",
                {
                    "total-users": {
                        action: "users/list",
                        calc_total: true,
                    },
                    "active-users": {
                        action: "users/list",
                        filters: {
                            is_blocked: false,
                        },
                        calc_total: true,
                    },
                    nfts: {
                        action: "nfts/list",
                        filters: {
                            is_foreign: false
                        },
                        calc_total: true,
                    },
                    collections: {
                        action: "nft_collections/list",
                        calc_total: true,
                    },
                    // "nft-history": {
                    //     action: "nft_history/list"
                    // }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken"),
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setUsersStat({
                    all: response.data["total-users"].total,
                    active: response.data["active-users"].total,
                    banned: response.data["total-users"].total - response.data["active-users"].total,
                });
                setItemsStat({ nfts: response.data["nfts"].total, collections: response.data["collections"].total });     
                // setNftHistory(response.data["nft-history"].items)
            })
            .catch(error => {
                console.log(error);
            });
        axios.get("https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false%2527")
        .then(res => setTONinDollars(res.data["the-open-network"].usd))
        .catch(err => console.log(err))

        axs.post("/nft_fees/list", {
            "filters": {
                "y": 0,
                "m": 0,
                "d": 0
            }
        }).then(({data}) => {
            setMarketplaceTransactions(data.items[0].num)
            setMarketplaceRevenue(data.items[0].fee / 10 ** 9)
        }).catch(err => console.log(err))
    }, []);

    // const getSuccessfulTransanctions = () => {
    //     return nftHistory?.reduce((total, current) => {
    //         if (["buy", "end"].includes(current.event_type)) {
    //             return total += 1
    //         }
    //         return total
    //     }, 0)
    // }

    return (
        <div className="adminPanel__content-statistics">
            <h1>Users</h1>
            <div className="adminPanel__content-statistics-info">
                <div className="adminPanel__content-statistics-info-item" style={{ backgroundColor: "#037fffd9" }}>
                    <img src="/img/adminPanel/users.svg" alt="" />
                    Total users: {usersStat.all}
                </div>
                <div className="adminPanel__content-statistics-info-item" style={{ backgroundColor: "#05e105d1" }}>
                    <img src="/img/adminPanel/active-users.svg" alt="" />
                    Active users: {usersStat.active}
                </div>
                <div className="adminPanel__content-statistics-info-item" style={{ backgroundColor: "#f10505cf" }}>
                    <img src="/img/adminPanel/banned-users.svg" alt="" />
                    Banned users: {usersStat.banned}
                </div>
            </div>
            <h1>NFTs and collections</h1>
            <div className="adminPanel__content-statistics-info">
                <div
                    className="adminPanel__content-statistics-info-item"
                    style={{ backgroundColor: "rgb(255 96 3 / 82%)" }}>
                    <img src="/img/adminPanel/nfts.svg" alt="" />
                    NFTs: {itemsStat.nfts}
                </div>
                <div
                    className="adminPanel__content-statistics-info-item"
                    style={{ backgroundColor: "rgb(207 162 1 / 75%)" }}>
                    <img src="/img/adminPanel/collections.svg" alt="" />
                    Collections: {itemsStat.collections}
                </div>
            </div>
            <h1>Finances</h1>
            <div className="adminPanel__content-statistics-info">
                <div
                    className="adminPanel__content-statistics-info-item"
                    style={{ backgroundColor: "rgb(190 83 203 / 82%)" }}>
                    <img src="/img/adminPanel/nfts.svg" alt="" />
                    Successful transactions: {marketplaceTranscations}
                </div>
                <div
                    className="adminPanel__content-statistics-info-item"
                    style={{ backgroundColor: "rgb(87 40 199 / 75%)" }}>
                    <img src="/img/adminPanel/collections.svg" alt="" />
                    Marketplace revenue: {marketplaceRevenue} TON ({(marketplaceRevenue * TONinDollars).toFixed(2)}$)
                </div>
            </div>
        </div>
    );
}

export default AdminPanelStatistics;