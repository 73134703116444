import axios from "axios";
import { memo, useState, useContext, useEffect } from "react";
import { ContextApp } from "../../Context";
import "./CollectionCard.scss";

const CollectionCard = ({collection, currentUser}) => {
    const { theme, changeTheme, setAuthPopup } = useContext(ContextApp);

    const [likes, setLikes] = useState([0, 0, 0, 0, 0, 0, 0]);

    const [likeProccess, setLikeProccess] = useState(false);

    // set likes on start collection
    useEffect(() => {
        if (collection?.likes?.length > 0 || collection?.anon_likes) {
            setLikeProccess(true)
            let likes_copy = [0, 0, 0, 0, 0, 0, 0];
            if (collection?.likes?.length > 0) {
                collection.likes.map(like => likes_copy[Number(like.type_id) - 1] += 1);
            }
            if (collection?.anon_likes) {
                Object.keys(collection.anon_likes).map(like_id => {
                    likes_copy[Number(like_id) - 1] += Number(collection.anon_likes[like_id])
                })
            }
            setLikes(likes_copy);
        }
        setLikeProccess(false)
    }, [collection]);
    
    const handleUserLike = e => {
        if (!likeProccess) {
            setLikeProccess(true);
            if(localStorage.getItem("auth") === "true") {
                axios
                    .post(
                            "https://nft-one.art/api/nft_likes/list",
                            {
                                filters: {
                                    collection_id: collection?.id,
                                    user_id: currentUser.id,
                                },
                            },
                            {
                                headers: {
                                    Token: localStorage.getItem("tonkeeperToken")
                                        ? localStorage.getItem("tonkeeperToken")
                                        : localStorage.getItem("tonhubToken"),
                                },
                                auth: {
                                    username: "odmen",
                                    password: "NFTflsy",
                                },
                            },
                        )
                    .then(response => {
                        let hasLikes = response.data.items.length > 0
                        let clickTypeID = e.target.getAttribute("data-emoji")
                            
                        // если этот пользователь еще не ставил лайки этой нфт/коллекции
                        if(!hasLikes) {
                                axios
                                    .post(
                                        "https://nft-one.art/api/nft_likes/upsert",
                                        {
                                            items: [
                                                {
                                                    collection_id: collection?.id,
                                                    type_id: clickTypeID,
                                                    user_id: currentUser.id,
                                                },
                                            ],
                                        },
                                        {
                                            headers: {
                                                Token: localStorage.getItem("tonkeeperToken")
                                                    ? localStorage.getItem("tonkeeperToken")
                                                    : localStorage.getItem("tonhubToken"),
                                            },
                                            auth: {
                                                username: "odmen",
                                                password: "NFTflsy",
                                            },
                                        },
                                    )
                                    .then(response => {
                                        let likes_copy = [...likes];
                                        likes_copy[Number(clickTypeID) - 1] += 1;
                                        setLikes([...likes_copy]);
                                        setLikeProccess(false);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                        }
    
                        // если этот пользователь ставил лайки этой нфт/коллекции и выбранный лайк совпадает с уже имеющимся на сервере
                        if(hasLikes && (clickTypeID === response.data.items[0].type_id)) {
                            axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/unlike",
                                    {
                                        collection_id: collection?.id,
                                        type_id: clickTypeID,
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                    let likes_copy = [...likes];
                                    likes_copy[Number(clickTypeID) - 1] -= 1;
                                    setLikes([...likes_copy]);
                                    setLikeProccess(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        }
    
                        // если этот пользователь ставил лайки этой нфт/коллекции и выбранный лайк НЕ совпадает с уже имеющимся на сервере
                        if(hasLikes && !(clickTypeID === response.data.items[0].type_id)) {
                            let needDeleteTypeID = response.data.items[0].type_id
                            let likes_copy = [...likes];
                            likes_copy[needDeleteTypeID - 1] -= 1;
                            likes_copy[clickTypeID - 1] += 1;
                            setLikes([...likes_copy]);
                            const updateLikesOnServer = (() => { axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/unlike",
                                    {
                                        collection_id: collection?.id,
                                        type_id: needDeleteTypeID,
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                            axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/upsert",
                                    {
                                        items: [
                                            {
                                                collection_id: collection?.id,
                                                type_id: clickTypeID,
                                                user_id: currentUser.id,
                                            },
                                        ],
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                    setLikeProccess(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                                
                            })();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                setAuthPopup(true)
            }
        }
    }

    return (
        <div className="collectionCard" style={{background: collection?.img?.hash ? `${`url(https://nft-one.art/api/files/thumb/?hash=${collection.img?.hash}&width=500&height=525) no-repeat center center/cover`}` : `url(/img/card/photo-2.svg) no-repeat center center/cover`}}>
            <a href={`/collection/${collection.id}`} draggable={false}>
                <img className="collectionCard__img" src={`https://nft-one.art/api/files/thumb/?hash=${collection.img?.hash}&width=1&height=1`} alt="" />
                <div className="collectionCard__tag">
                    <div className="collectionCard__tag-bg"></div>
                    <p className="collectionCard__tag-text">Collection</p>
                </div>
                {
                    likes.reduce((total, value) => total += value, 0) > 0 && (
                        <ul className="collectionCard__emoji" style={{ backgroundColor: changeTheme("", "rgba(255, 255, 255, 0.21)") }}>
                            {
                                ["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((emoji, index) =>
                                    <li className={"collectionCard__emoji-item-" + theme} key={index} style={{display: `${likes[index] === 0 ? "none" : ""}`}}>
                                        {emoji}<span style={{color: changeTheme("", "#fff") }}>{likes[index]}</span>
                                    </li>
                                )
                            }
                        </ul>
                    )
                }
                <div className="collectionCard__info">
                    <div className="collectionCard__info-bg"></div>
                    <p className="collectionCard__info-text">{collection?.name}</p>
                </div>
            </a>
            <ul className="collectionCard__menuEmoji"
                style={{backgroundColor: changeTheme("#fff", "#2B3239")}}>
                {
                    ["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((item, index) => <li key={index} data-emoji={index + 1} className="collectionCard__menuEmoji-item" onClick={(e) => handleUserLike(e)}>{item}</li>)
                }
            </ul>
        </div>
    );
}

export default memo(CollectionCard);
