import axios from "axios";

const instance = axios.create({
    baseURL: "https://nft-one.art/api",
});

instance.interceptors.request.use(config => {
    config.headers.Authorization = localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken");
    return config;
});

export default instance;
