import "./Profile.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Filters from "../../components/Filters";
import SimpleCard from "../../components/SimpleCard";
import CollectionCard from "../../components/CollectionCard";
import { useContext, useEffect, useState } from "react";
import { ContextApp } from "../../Context";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useParams } from "react-router";
import { Tooltip } from "react-tooltip";
import { Toaster, toast } from "react-hot-toast";
import uuid from "react-uuid";
import Checkbox from "../../components/Checkbox";

function Profile() {
    const params = useParams();

    const {
        theme,
        changeTheme,
        filterStatus,
        filterQuantity,
        filterPriceAt,
        filterPriceTo,
        filterCategory,
        filterEmotional,
        globalExtraFilters
    } = useContext(ContextApp);

    const [currentUser, setCurrentUser] = useState({});
    const [username, setUsername] = useState("");
    const [info, setInfo] = useState("");
    const [socialLinks, setSocialLinks] = useState({
        vk: "",
        telegram: "",
        twitter: "",
        reddit: "",
        discord: "",
    });
    const [email, setEmail] = useState("");

    const [windowView, setWindowView] = useState("nft");
    const [pricePopup, setPricePopup] = useState(false);
    const [priceCurrent, setPriceCurrent] = useState("Low to High");
    const [NFTs, setNFTs] = useState([]);
    const [collections, setCollections] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortingPopup, setSortingPopup] = useState(false);
    const [filtersMobile, setFiltersMobile] = useState(false);

    const [bannerHash, setBannerHash] = useState(null);
    const [avatarHash, setAvatarHash] = useState(null);

    const [checkes, setCheckes] = useState([]);
    const [bids, setBids] = useState([]);

    const [creationCurrent, setCreationCurrent] = useState("New")
    const [creationPopup, setCreationPopup] = useState(false)

    const [purchasedCurrent, setPurchasedCurrent] = useState("Recently")
    const [purchasedPopup, setPurchasedPopup] = useState(false)

    const [currentSort, setCurrentSort] = useState("Low to high")

    const [seeMoreUserDescription, setSeeMoreUserDescription] = useState(false)

    function OneOrMinusOne(a, b) {
        return a > b ? 1 : a < b ? -1 : 0;
    }

    function checkScore(nft) {
        let typeIDs = [];
        let score = 0;
        filterEmotional?.map((emot) => (
            emot === "Red Heart"
                ? typeIDs.push("1")
                : emot === "Rolling on the Floor Laughing"
                    ? typeIDs.push("2")
                    : emot === "Smiling Face with Heart-Eyes"
                        ? typeIDs.push("3")
                        : emot === "Enraged Face"
                            ? typeIDs.push("4")
                            : emot === "Weary Cat"
                                ? typeIDs.push("5")
                                : emot === "Woozy Face"
                                    ? typeIDs.push("6")
                                    : emot === "Money-Mouth Face"
                                        ? typeIDs.push("7")
                                        : null
        )
        );
        if (nft.likes.length > 0 || nft?.anon_likes) {
            if (nft.likes.length > 0) {
                    nft.likes.map(like => {
                        if (typeIDs.includes(like.type_id)) {
                            score += 1;
                        }
                    }
                )
            }
            if (nft?.anon_likes) {
                Object.keys(nft.anon_likes).map(like_id => {
                    if (typeIDs.includes(like_id)) {
                        score += 1
                    }
                })
            }
        }
        return score;
    }

    const {
        acceptedFiles: acceptedFilesFromBanner,
        getRootProps: getRootPropsFromBanner,
        getInputProps: getInputPropsFromBanner,
    } = useDropzone();
    const {
        acceptedFiles: acceptedFilesFromAvatar,
        getRootProps: getRootPropsFromAvatar,
        getInputProps: getInputPropsFromAvatar,
    } = useDropzone();
    
    useEffect(() => {
        axios
            .post(
                "https://nft-one.art/api/batch",
                {
                    nfts: {
                        action: "nfts/list",
                        filters: {
                            "owner_id": params?.id,
                            // is_foreign: false
                        },
                        subqueries: {
                            img: {},
                            likes: {},
                            creator: {
                                subqueries: {
                                    img: {}
                                }
                            },
                        },
                    },
                    collections: {
                        action: "nft_collections/list",
                        filters: {
                            creator_id: params?.id,
                            is_foreign: false
                        },
                        subqueries: {
                            img: {},
                            likes: {},
                        },
                    },
                    favorites: {
                        action: "nft_favorites/list",
                        filters: {
                            "user_id": params?.id
                        },
                        subqueries: {
                            nft: {
                                subqueries: {
                                    likes: {},
                                    creator: {
                                        subqueries: {
                                            img: {}
                                        }
                                    },
                                    img: {}
                                }
                            },
                            collection: {
                                subqueries: {
                                    likes: {},
                                    creator: {
                                        subqueries: {
                                            img: {}
                                        }
                                    },
                                    img: {}
                                }
                            }
                        },
                    }
                },
                {
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setNFTs(response.data["nfts"].items.reverse());

                let copy = [];
                    response.data["favorites"].items.map(item => {
                        if (item.nft !== null) {
                            copy.push(item.nft)
                        } else {
                            copy.push(item.collection)
                        }
                    })
                setFavorites(copy.reverse());

                setCollections(response.data["collections"].items.reverse());
            })
            .catch(error => {
                console.log(error);
            });
    }, [params?.id]);

    // get my bids
    useEffect(() => {
        if (params?.id) {
            axios
                .post(
                    "https://nft-one.art/api/nft_history/list",
                    {
                        "filters": {
                            "event_type": "bid",
                            "new_owner_id": params?.id
                        }
                    },
                    {
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    let nftIDs = []
                    response.data.items.map(item => !(nftIDs.includes(item?.nft_id)) ? nftIDs.push(item?.nft_id) : null)
                    if (nftIDs.length !== 0) {
                        axios
                        .post(
                            "https://nft-one.art/api/nfts/list",
                            {
                                "filters": {
                                    "id": nftIDs,
                                    is_foreign: false
                                },
                                subqueries: {
                                    img: {},
                                    likes: {},
                                    creator: {
                                        subqueries: {
                                            img: {}
                                        }
                                    },
                                },
                            },
                            {
                                auth: {
                                    username: "odmen",
                                    password: "NFTflsy",
                                },
                            },
                        )
                        .then(res => {
                            setBids(res.data.items.reverse())
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    } else {
                        setBids([])
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [params?.id])

    // users/list
    useEffect(() => {
        axios
            .post(
                "https://nft-one.art/api/users/list",
                {
                    "filters": {
                        "id": params.id
                    },
                    subqueries: {
                        img: {},
                        hdr: {}
                    }
                },
                {
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setCurrentUser(response.data.items[0])
            })
            .catch(error => {
                console.log(error);
            });
    }, [params.id]);

    // Когда объект пользователя загружен, мы устанавливаем все стейты с данными
    useEffect(() => {
        if (JSON.stringify(currentUser) !== "{}") {
            setUsername(currentUser.name);
            setInfo(currentUser.info);
            setSocialLinks(currentUser.social_links);
            setEmail(currentUser.email);
            setAvatarHash(currentUser.img?.hash);
            setBannerHash(currentUser.hdr?.hash);
        }
    }, [currentUser]);

    const getFloorPriceForCollection = (collection_id) => {
        let arr = []
        NFTs.map(nft => nft?.collection_id === collection_id ? arr.push(nft?.price) : null)
        arr = arr.sort((a, b) => a - b)
        return arr[0]
    }

    useEffect(() => {
        if (window.innerWidth <= 768) {
            let filter = document.getElementsByClassName("filters")[0]
            if (filtersMobile) {
                filter.style.opacity = "1"
                filter.style.pointerEvents = "all"
                if (windowView === "bids" || windowView === "favorite") {
                    filter.style.setProperty("left", "-70px", "important")
                }
            } else {
                filter.style.opacity = "0"
                filter.style.pointerEvents = "none"
            }
        }
    }, [filtersMobile])

    return (
        <>
            <Tooltip id="click-me" place="bottom" style={{
                font: "400 12px/100% 'DM Sans'",
                padding: "6px 10px",
                zIndex: 10000
            }} />
            <Toaster />
            <Header />
            <section
                className={`myBanner ${changeTheme("", "myBanner--dark")}`}
                style={{
                    background: `${
                        bannerHash
                            ? `url(https://nft-one.art/api/files/thumb/?hash=${bannerHash}&width=2000&height=380) no-repeat center center/cover`
                            : "linear-gradient(45deg, #2442ad 0%, #2240e0 35.44%, #1fbdeb 67.05%, #f39475 99.49%)"
                    }`,
                    cursor: "default"
                }}>
                <div className="myBanner__download" style={{ opacity: "0"}}>
                    <img src={`/img/sections/myNFT/download-${theme}.svg`} alt="" className="myBanner__download-img" />
                    <p className="myBanner__download-title">Add banner</p>
                    <p className="myBanner__download-text">Optimal dimensions: 2500×650</p>
                </div>
            </section>
            <section className={`myContent ${changeTheme("", "myContent--dark")}`}>
                <div className="myContent__left">
                    <div className="myContent__left-user">
                        <div className="myContent__left-user-box">
                        <div
                                        className="myContent__left-user-avatar"
                                        style={{
                                            background: 
                                            avatarHash
                                                ? `url(https://nft-one.art/api/files/thumb/?hash=${avatarHash}&width=250&height=250) no-repeat center center / cover`
                                                : "url(/img/sections/myNFT/avatar.svg) no-repeat center center / cover"
                                        }}
                                        alt=""
                                    ></div>
                        </div>
                        <h6 className="myContent__left-user-name">
                            {username.length > 18 ? username.slice(0, 18) + "..." : username}
                        </h6>
                        {JSON.stringify(socialLinks) !== "{}" &&
                            JSON.stringify(socialLinks) !==
                                '{"vk":"","reddit":"","discord":"","twitter":"","telegram":""}' && (
                                <div className="myContent__left-user-social">
                                    {Object.keys(socialLinks).map(key => {
                                        const currentLink =
                                            key === "vk"
                                                ? "vk.com/"
                                                : key === "telegram"
                                                ? "t.me/"
                                                : key === "reddit"
                                                ? "reddit.com/"
                                                : key === "twitter"
                                                ? "twitter.com/"
                                                : key === "discord"
                                                ? "discord.gg/"
                                                : null;
                                        if (socialLinks[key] !== "") {
                                            return (
                                                <a
                                                    href={"https://" + currentLink + socialLinks[key]}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <img src={`/img/sections/myNFT/${key}.svg`} alt="" />
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        <p className="myContent__left-user-text">
                            {/* {info === "" ? (
                                <>
                                    Let people find out more about you.
                                    <br />
                                    <br />
                                    Fill out your personal information: nickname, description and social media. Add a
                                    new banner.
                                </>
                            ) : (
                                info
                            )} */}
                            {info === "" ? (
                                <>
                                    Let people find out more about you.
                                    <br />
                                    <br />
                                    Fill out your personal information: nickname, description and social media. Add a
                                    new banner.
                                </>
                            ) : (
                                info.length >= 160 ? (
                                    !seeMoreUserDescription ? (
                                        (<>
                                            <span>{info.slice(0, 157)}</span>
                                            <span style={{
                                                opacity: ".75"
                                            }}>{info.slice(152, 154)}</span>
                                            <span style={{
                                                opacity: ".5"
                                            }}>{info.slice(154, 156)}</span>
                                            <span style={{
                                                opacity: ".35"
                                            }}>{info.slice(156, 158)}</span>
                                            <span style={{
                                                opacity: ".15"
                                            }}>{info.slice(158, 160)}</span>
                                            </>)
                                    ) : info
                                ) : info
                            )}
                            { !seeMoreUserDescription && info.length >= 160 && <span className="myContent__left-user-text-seeMore" onClick={() => setSeeMoreUserDescription(true)}>See More</span> }
                            <br />
                            <br />
                            <span className="myContent__left-user-tonAddr" data-tooltip-id="click-me"
                                data-tooltip-content="Click for copy"
                                style={{
                                    fontWeight: "600",
                                    color: "#004d8c"
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(currentUser?.ton_addr).then(
                                        () => {
                                            toast.success("Copied to Clipboard", {
                                                position: "bottom-right",
                                                style: {
                                                    font: "400 21px/100% 'DM Sans'",
                                                },
                                            });
                                        },
                                        () => {
                                            toast.error("An error has occurred", {
                                                position: "bottom-right",
                                                style: {
                                                    font: "400 21px/100% 'DM Sans'",
                                                },
                                            });
                                        },
                                    );
                                }}>{currentUser?.ton_addr?.slice(0,6) + "..." + currentUser?.ton_addr?.slice(currentUser?.ton_addr?.length - 6, currentUser?.ton_addr?.length)}</span>
                        </p>
                    </div>
                    {
                        window.innerWidth > 768 ? windowView === "nft" ? <Filters handleArray={windowView === "nft" ? NFTs : windowView === "collection" ? collections : windowView === "bids" ? bids : favorites} example={"nft"} /> : windowView === "collection" ? <Filters handleArray={windowView === "nft" ? NFTs : windowView === "collection" ? collections : windowView === "bids" ? bids : favorites} example={"collection"} /> : <Filters handleArray={windowView === "nft" ? NFTs : windowView === "collection" ? collections : windowView === "bids" ? bids : favorites} example={"nft"} /> : null
                    }
                </div>
                <div className="myContent__right">
                    <div className="myContent__right-window">
                        <button
                            className={`myContent__right-window-btn ${
                                windowView === "nft" ? "myContent__right-window-btn--active" : ""
                            }`}
                            onClick={() => setWindowView("nft")}>
                            NFTs
                        </button>
                        <button
                            className={`myContent__right-window-btn ${
                                windowView === "collection" ? "myContent__right-window-btn--active" : ""
                            }`}
                            onClick={() => setWindowView("collection")}>
                            Collections
                        </button>
                        <button
                            className={`myContent__right-window-btn ${
                                windowView === "bids" ? "myContent__right-window-btn--active" : ""
                            }`}
                            onClick={() => setWindowView("bids")}>
                            Bids
                        </button>
                        <button
                            className={`myContent__right-window-btn ${
                                windowView === "favorite" ? "myContent__right-window-btn--active" : ""
                            }`}
                            onClick={() => setWindowView("favorite")}>
                            Favorites
                        </button>
                    </div>
                    <div className="myContent__right-settings">
                        <div className="myContent__right-settings-search">
                            <input
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                placeholder="Search"
                                className="myContent__right-settings-search-input"
                                type="text"
                            />
                            <img
                                className="myContent__right-settings-search-img"
                                alt=""
                                src={`/img/sections/collection/search-${theme}.svg`}
                            />
                        </div>
                            {
                                windowView === "nft" && (
                                    <div className="collection__main-cards-options-checkboxes">
                                        <Checkbox dataID={"not-filter"} text={"Purchased"} onClick={() => checkes.includes("purchased") ? setCheckes(checkes.filter(rar => rar !== "purchased")) : setCheckes([...checkes, "purchased"])} />
                                        <Checkbox dataID={"not-filter"} text={"Created"} onClick={() => checkes.includes("created") ? setCheckes(checkes.filter(rar => rar !== "created")) : setCheckes([...checkes, "created"])} />
                                        <Checkbox dataID={"not-filter"} text={"Synced"} onClick={() => checkes.includes("synced") ? setCheckes(checkes.filter(rar => rar !== "synced")) : setCheckes([...checkes, "synced"])} />
                                    </div>
                                )
                            }
                        <div className="myContent__right-settings-box">
                            {
                                (((windowView === "nft" && ((checkes.length === 2 && !checkes.includes("synced"))  || checkes.length === 0))) || (windowView === "nft" && checkes.length === 1 && checkes.includes("purchased"))) && (
                                    <button
                                        className="myContent__right-settings-box-price"
                                        onClick={() => setPurchasedPopup(!purchasedPopup)}>
                                        Purchased: {purchasedCurrent}
                                        <img
                                            src={`/img/sections/collection/arrow-up-${theme}.svg`}
                                            alt=""
                                            style={{ transform: purchasedPopup ? "rotate(-180deg)" : "rotate(0deg)" }}
                                        />
                                        {purchasedPopup && (
                                            <ul className="catalog__container-content-options-sorting-popup" style={{
                                                backgroundColor: changeTheme("", "#23292F")
                                            }}>
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => {
                                                        setPurchasedCurrent(e.target.innerText)
                                                        setCurrentSort("Recently")
                                                    }}>
                                                    Recently
                                                </li>
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => {
                                                        setPurchasedCurrent(e.target.innerText)
                                                        setCurrentSort("Long ago")
                                                    }}>
                                                    Long ago
                                                </li>
                                            </ul>
                                        )}
                                    </button>
                                )
                            }
                            {
                                ((windowView === "collection") || ((windowView === "nft" && ((checkes.length === 2 && !checkes.includes("synced")) || checkes.length === 0))) || (windowView === "nft" && checkes.length === 1 && checkes.includes("created"))) && (
                                    <button
                                        className="myContent__right-settings-box-price"
                                        onClick={() => setCreationPopup(!creationPopup)}>
                                        Creation: {creationCurrent}
                                        <img
                                            src={`/img/sections/collection/arrow-up-${theme}.svg`}
                                            alt=""
                                            style={{ transform: creationPopup ? "rotate(-180deg)" : "rotate(0deg)" }}
                                        />
                                        {creationPopup && (
                                            <ul className="catalog__container-content-options-sorting-popup" style={{
                                                backgroundColor: changeTheme("", "#23292F")
                                            }}>
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => {
                                                        setCreationCurrent(e.target.innerText)
                                                        setCurrentSort("Old")
                                                    }}>
                                                    Old
                                                </li>
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => {
                                                        setCreationCurrent(e.target.innerText)
                                                        setCurrentSort("New")
                                                    }}>
                                                    New
                                                </li>
                                            </ul>
                                        )}
                                    </button>
                                )
                            }
                            {
                                windowView !== "favorite" && windowView !== "bids" && (
                                    <button
                                        className="myContent__right-settings-box-price"
                                        onClick={() => setPricePopup(!pricePopup)}>
                                        Price: {priceCurrent}
                                        <img
                                            src={`/img/sections/collection/arrow-up-${theme}.svg`}
                                            alt=""
                                            style={{ transform: pricePopup ? "rotate(-180deg)" : "rotate(0deg)" }}
                                        />
                                        {pricePopup && (
                                            <ul className="catalog__container-content-options-sorting-popup" style={{
                                                backgroundColor: changeTheme("", "#23292F")
                                            }}>
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => setPriceCurrent(e.target.innerText)}>
                                                    Low to High
                                                </li>
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => setPriceCurrent(e.target.innerText)}>
                                                    High to Low
                                                </li>
                                            </ul>
                                        )}
                                    </button>
                                )
                            }
                        </div>
                        <div className="myContent__right-settings-boxMobile">
                            <button
                                className="myContent__right-settings-boxMobile-filters"
                                onClick={(e) => {
                                    if (["myContent__right-settings-boxMobile-filters-img", "myContent__right-settings-boxMobile-filters"].includes(e.target.className)) {
                                        setFiltersMobile(!filtersMobile)
                                    }
                                }}>
                                Filters
                                <img
                                    className="myContent__right-settings-boxMobile-filters-img"
                                    src={`/img/sections/collection/filters-${theme}.svg`} alt="" />
                                <Filters handleArray={windowView === "nft" ? NFTs : windowView === "collection" ? collections : windowView === "bids" ? bids : favorites} example={windowView === "nft" || windowView === "favorite" ? "nft" : "collection"}/>
                            </button>
                            {/* <button
                                className="myContent__right-settings-boxMobile-sort"
                                onClick={() => setSortingPopup(!sortingPopup)}>
                                Sort
                                <img src={`/img/sections/collection/arrow-up-${theme}.svg`} alt="" />
                                {sortingPopup && (
                                    <ul className="collection__main-cards-options-filters-sort-popup" style={{
                                        backgroundColor: changeTheme("", "#2B3239"),
                                        marginTop: "3px"
                                    }}>
                                        <li
                                            className="collection__main-cards-options-filters-sort-popup-item"
                                            onClick={e => setPriceCurrent(e.target.innerText)}>
                                            Low to High
                                        </li>
                                        <li
                                            className="collection__main-cards-options-filters-sort-popup-item"
                                            onClick={e => setPriceCurrent(e.target.innerText)}>
                                            High to Low
                                        </li>
                                    </ul>
                                )}
                            </button> */}
                            {
                                windowView !== "bids" && windowView !== "favorite" && (
                                    <button
                                        className="myContent__right-settings-boxMobile-sort"
                                        onClick={() => setSortingPopup(!sortingPopup)}>
                                        Sort
                                        <img src={`/img/sections/collection/arrow-up-${theme}.svg`} alt="" />
                                        {sortingPopup && (
                                            <ul className="collection__main-cards-options-filters-sort-popup" style={{
                                                backgroundColor: changeTheme("", "#2B3239"),
                                                marginTop: "3px"
                                            }}>
                                                <li
                                                    className="collection__main-cards-options-filters-sort-popup-item"
                                                    onClick={() => setCurrentSort("Low to High")}>
                                                    Low price
                                                </li>
                                                <li
                                                    className="collection__main-cards-options-filters-sort-popup-item"
                                                    onClick={() => setCurrentSort("High to Low")}>
                                                    High price
                                                </li>
                                                <li
                                                    className="collection__main-cards-options-filters-sort-popup-item"
                                                    onClick={() => setCurrentSort("Old")}>
                                                    Old create
                                                </li>
                                                <li
                                                    className="collection__main-cards-options-filters-sort-popup-item"
                                                    onClick={() => setCurrentSort("New")}>
                                                    New create
                                                </li>
                                                {
                                                    windowView === "nft" && (
                                                        <>
                                                            <li
                                                                className="collection__main-cards-options-filters-sort-popup-item"
                                                                onClick={() => setCurrentSort("Recently")}>
                                                                Recently take
                                                            </li>
                                                            <li
                                                                className="collection__main-cards-options-filters-sort-popup-item"
                                                                onClick={() => setCurrentSort("Long ago")}>
                                                                Long ago take
                                                            </li>
                                                        </>
                                                    )
                                                }
                                            </ul>
                                        )}
                                    </button>
                                ) 
                            }
                        </div>
                    </div>
                    <div className="myContent__right-items">
                        {windowView === "nft" && (
                            <>
                                {NFTs.length > 0 ? (
                                    NFTs.filter(nft =>
                                        searchQuery !== ""
                                            ? nft.name.toLowerCase().includes(searchQuery.toLowerCase())
                                            : nft,
                                    )
                                    .filter(nft => checkes.length === 0 || checkes.length === 3
                                    ? nft
                                    : (checkes.includes("created") && ((currentUser?.id === nft?.creator?.id) && !nft?.is_foreign))
                                    ? nft
                                    : (checkes.includes("purchased") && currentUser?.id !== nft?.creator?.id && !nft?.is_foreign)
                                    ? nft
                                    : (checkes.includes("synced") && nft?.is_foreign))
                                    .filter(nft =>
                                        filterStatus === "all"
                                            ? nft
                                            : filterStatus === "Buy now"
                                            ? nft.state === "sale"
                                            : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                                    )
                                        .filter(nft =>
                                            filterQuantity === "all"
                                                ? nft
                                                : filterQuantity === "single"
                                                    ? nft.collection_id == null
                                                    : nft.collection_id != null,
                                        )
                                        .filter(nft =>
                                            filterPriceAt !== "" ? Number(nft.price) >= filterPriceAt * (10 ** 9) : nft,
                                        )
                                        .filter(nft =>
                                            filterPriceTo !== "" ? Number(nft.price) <= filterPriceTo * (10 ** 9) : nft,
                                        )
                                        .filter(nft => {
                                            if (filterCategory.length === 0) {
                                                return nft
                                            } else {
                                                let flag = false;
                                                if (nft?.categories) {
                                                    nft.categories.map(cat =>
                                                        filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                    );
                                                }
                                                return flag;
                                            }
                                        })
                                        .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                        .sort((a, b) => {
                                            if (currentSort === "Low to High") {
                                                return Number(a.price) - Number(b.price)
                                            } else if (currentSort === "High to Low") {
                                                return Number(b.price) - Number(a.price)
                                            } else if (currentSort === "Old") {
                                                return Number(a?.add_time) - Number(b?.add_time)
                                            } else if (currentSort === "New") {
                                                return Number(b?.add_time) - Number(a?.add_time)
                                            } else if (currentSort === "Recently") {
                                                return Number(a?.mod_time) - Number(b?.mod_time)
                                            } else if (currentSort === "Long ago") {
                                                return Number(b?.mod_time) - Number(a?.mod_time)
                                            }
                                        }
                                        )
                                        .sort((a, b) => {
                                            return OneOrMinusOne(checkScore(b), checkScore(a));
                                        }).length > 0
                                        ?
                                        NFTs.filter(nft =>
                                            searchQuery !== ""
                                                ? nft.name.toLowerCase().includes(searchQuery.toLowerCase())
                                                : nft,
                                        )
                                        .filter(nft => checkes.length === 0 || checkes.length === 3
                                    ? nft
                                    : (checkes.includes("created") && ((currentUser?.id === nft?.creator?.id) && !nft?.is_foreign))
                                    ? nft
                                    : (checkes.includes("purchased") && currentUser?.id !== nft?.creator?.id && !nft?.is_foreign)
                                    ? nft
                                    : (checkes.includes("synced") && nft?.is_foreign))
                                    .filter(nft =>
                                        filterStatus === "all"
                                            ? nft
                                            : filterStatus === "Buy now"
                                            ? nft.state === "sale"
                                            : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                                    )
                                            .filter(nft =>
                                                filterQuantity === "all"
                                                    ? nft
                                                    : filterQuantity === "single"
                                                        ? nft.collection_id == null
                                                        : nft.collection_id != null,
                                            )
                                            .filter(nft =>
                                                filterPriceAt !== "" ? Number(nft.price) >= filterPriceAt * (10 ** 9) : nft,
                                            )
                                            .filter(nft =>
                                                filterPriceTo !== "" ? Number(nft.price) <= filterPriceTo * (10 ** 9) : nft,
                                            )
                                            .filter(nft => {
                                                if (filterCategory.length === 0) {
                                                    return nft
                                                } else {
                                                    let flag = false;
                                                    if (nft?.categories) {
                                                        nft.categories.map(cat =>
                                                            filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                        );
                                                    }
                                                    return flag;
                                                }
                                            })
                                            .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                            .sort((a, b) => {
                                                if (currentSort === "Low to High") {
                                                    return Number(a.price) - Number(b.price)
                                                } else if (currentSort === "High to Low") {
                                                    return Number(b.price) - Number(a.price)
                                                } else if (currentSort === "Old") {
                                                    return Number(a?.add_time) - Number(b?.add_time)
                                                } else if (currentSort === "New") {
                                                    return Number(b?.add_time) - Number(a?.add_time)
                                                } else if (currentSort === "Recently") {
                                                    return Number(a?.mod_time) - Number(b?.mod_time)
                                                } else if (currentSort === "Long ago") {
                                                    return Number(b?.mod_time) - Number(a?.mod_time)
                                                }
                                            }
                                            )
                                            .sort((a, b) => {
                                                return OneOrMinusOne(checkScore(b), checkScore(a));
                                            })
                                        .map(nft => <SimpleCard foreignProfile={currentUser} nft={nft} avatarHash={avatarHash} showEmoji key={uuid()} />)
                                        : <div className="myContent__right-items-zero">NO ITEMS</div>
                                        
                                ) : (
                                    <div className="myContent__right-items-zero">NO NFTS</div>
                                )}
                            </>
                        )}
                        {windowView === "bids" && (
                            <>
                                {bids.length > 0 ? (
                                    bids.filter(nft =>
                                        searchQuery !== ""
                                            ? nft.name.toLowerCase().includes(searchQuery.toLowerCase())
                                            : nft,
                                    )
                                    .filter(nft =>
                                        filterStatus === "all"
                                            ? nft
                                            : filterStatus === "Buy now"
                                            ? nft.state === "sale"
                                            : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                                    )
                                        .filter(nft =>
                                            filterQuantity === "all"
                                                ? nft
                                                : filterQuantity === "single"
                                                    ? nft.collection_id == null
                                                    : nft.collection_id != null,
                                        )
                                        .filter(nft =>
                                            filterPriceAt !== "" ? Number(nft.price) >= filterPriceAt * (10 ** 9) : nft,
                                        )
                                        .filter(nft =>
                                            filterPriceTo !== "" ? Number(nft.price) <= filterPriceTo * (10 ** 9) : nft,
                                        )
                                        .filter(nft => {
                                            if (filterCategory.length === 0) {
                                                return nft
                                            } else {
                                                let flag = false;
                                                if (nft?.categories) {
                                                    nft.categories.map(cat =>
                                                        filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                    );
                                                }
                                                return flag;
                                            }
                                        })
                                        .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                        .sort((a, b) => {
                                            return OneOrMinusOne(checkScore(b), checkScore(a));
                                        }).length > 0
                                        ?
                                        bids.filter(nft =>
                                            searchQuery !== ""
                                                ? nft.name.toLowerCase().includes(searchQuery.toLowerCase())
                                                : nft,
                                        )
                                        .filter(nft =>
                                        filterStatus === "all"
                                            ? nft
                                            : filterStatus === "Buy now"
                                            ? nft.state === "sale"
                                            : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                                    )
                                            .filter(nft =>
                                                filterQuantity === "all"
                                                    ? nft
                                                    : filterQuantity === "single"
                                                        ? nft.collection_id == null
                                                        : nft.collection_id != null,
                                            )
                                            .filter(nft =>
                                                filterPriceAt !== "" ? Number(nft.price) >= filterPriceAt * (10 ** 9) : nft,
                                            )
                                            .filter(nft =>
                                                filterPriceTo !== "" ? Number(nft.price) <= filterPriceTo * (10 ** 9) : nft,
                                            )
                                            .filter(nft => {
                                                if (filterCategory.length === 0) {
                                                    return nft
                                                } else {
                                                    let flag = false;
                                                    if (nft?.categories) {
                                                        nft.categories.map(cat =>
                                                            filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                        );
                                                    }
                                                    return flag;
                                                }
                                            })
                                            .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                            .sort((a, b) => {
                                                return OneOrMinusOne(checkScore(b), checkScore(a));
                                            })
                                        .map(nft => <SimpleCard nft={nft} avatarHash={avatarHash} showEmoji key={uuid()} />)
                                        : <div className="myContent__right-items-zero">NO ITEMS</div>
                                        
                                ) : (
                                    <div className="myContent__right-items-zero">NO BIDS</div>
                                )}
                            </>
                        )}
                        {windowView === "collection" && (
                            <>
                                {collections.length > 0 ? (
                                    collections
                                        .filter(collection =>
                                            searchQuery !== ""
                                                ? collection.name.toLowerCase().includes(searchQuery.toLowerCase())
                                                : collection,
                                        )
                                        .filter(collection => {
                                            if (filterCategory.length === 0) {
                                                return collection;
                                            } else {
                                                let flag = false;
                                                collection.categories.map(cat =>
                                                    filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                );
                                                return flag;
                                            }
                                        })
                                        .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                        .sort((a, b) => {
                                            if (currentSort === "Low to High") {
                                                return getFloorPriceForCollection(a?.id) - getFloorPriceForCollection(b?.id)
                                            } else if (currentSort === "High to Low") {
                                                return getFloorPriceForCollection(b?.id) - getFloorPriceForCollection(a?.id)
                                            } else if (currentSort === "Old") {
                                                return Number(a?.add_time) - Number(b?.add_time)
                                            } else if (currentSort === "New") {
                                                return Number(b?.add_time) - Number(a?.add_time)
                                            }
                                        })
                                        .sort((a, b) => {
                                            let typeIDs = [];
                                            let scoreA = 0;
                                            let scoreB = 0;
                                            filterEmotional?.map((emot) => (
                                                emot === "Red Heart"
                                                    ? typeIDs.push(1)
                                                    : emot === "Rolling on the Floor Laughing"
                                                        ? typeIDs.push(2)
                                                        : emot === "Smiling Face with Heart-Eyes"
                                                            ? typeIDs.push(3)
                                                            : emot === "Enraged Face"
                                                                ? typeIDs.push(4)
                                                                : emot === "Weary Cat"
                                                                    ? typeIDs.push(5)
                                                                    : emot === "Woozy Face"
                                                                        ? typeIDs.push(6)
                                                                        : emot === "Money-Mouth Face"
                                                                            ? typeIDs.push(7)
                                                                            : null
                                            )
                                            );
                                            if (a.likes.length > 0) {
                                                a.likes.map(like => {
                                                    if (typeIDs.includes(Number(like.type_id))) {
                                                        scoreA += 1;
                                                    }
                                                }
                                                )
                                            }
                                            if (b.likes.length > 0) {
                                                b.likes.map(like => {
                                                    if (typeIDs.includes(Number(like.type_id))) {
                                                        scoreB += 1;
                                                    }
                                                }
                                                )
                                            }
                                            return (scoreB > scoreA ? 1 : scoreB < scoreA ? -1 : 0);
                                        }).length > 0 ? 
                                        collections
                                            .filter(collection =>
                                                searchQuery !== ""
                                                    ? collection.name.toLowerCase().includes(searchQuery.toLowerCase())
                                                    : collection,
                                            )
                                            .filter(collection => {
                                                if (filterCategory.length === 0) {
                                                    return collection;
                                                } else {
                                                    let flag = false;
                                                    collection.categories.map(cat =>
                                                        filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                    );
                                                    return flag;
                                                }
                                            })
                                            .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                            .sort((a, b) => {
                                                if (currentSort === "Low to High") {
                                                    return getFloorPriceForCollection(a?.id) - getFloorPriceForCollection(b?.id)
                                                } else if (currentSort === "High to Low") {
                                                    return getFloorPriceForCollection(b?.id) - getFloorPriceForCollection(a?.id)
                                                } else if (currentSort === "Old") {
                                                    return Number(a?.add_time) - Number(b?.add_time)
                                                } else if (currentSort === "New") {
                                                    return Number(b?.add_time) - Number(a?.add_time)
                                                }
                                            })
                                            .sort((a, b) => {
                                                let typeIDs = [];
                                                let scoreA = 0;
                                                let scoreB = 0;
                                                filterEmotional?.map((emot) => (
                                                    emot === "Red Heart"
                                                        ? typeIDs.push(1)
                                                        : emot === "Rolling on the Floor Laughing"
                                                            ? typeIDs.push(2)
                                                            : emot === "Smiling Face with Heart-Eyes"
                                                                ? typeIDs.push(3)
                                                                : emot === "Enraged Face"
                                                                    ? typeIDs.push(4)
                                                                    : emot === "Weary Cat"
                                                                        ? typeIDs.push(5)
                                                                        : emot === "Woozy Face"
                                                                            ? typeIDs.push(6)
                                                                            : emot === "Money-Mouth Face"
                                                                                ? typeIDs.push(7)
                                                                                : null
                                                )
                                                );
                                                if (a.likes.length > 0) {
                                                    a.likes.map(like => {
                                                        if (typeIDs.includes(Number(like.type_id))) {
                                                            scoreA += 1;
                                                        }
                                                    }
                                                    )
                                                }
                                                if (b.likes.length > 0) {
                                                    b.likes.map(like => {
                                                        if (typeIDs.includes(Number(like.type_id))) {
                                                            scoreB += 1;
                                                        }
                                                    }
                                                    )
                                                }
                                                return (scoreB > scoreA ? 1 : scoreB < scoreA ? -1 : 0);
                                            })
                                        .map(collection => (
                                            <CollectionCard collection={collection} currentUser={currentUser} key={uuid()} />
                                        ))
                                        : <div className="myContent__right-items-zero">NO ITEMS</div>
                                        
                                ) : (
                                    <div className="myContent__right-items-zero">NO COLLECTIONS</div>
                                )}
                            </>
                        )}
                        {windowView === "favorite" && (
                            <>
                                {favorites.length > 0 ? (
                                    favorites
                                        .filter(favorite =>
                                            searchQuery !== ""
                                                ? favorite.name.toLowerCase().includes(searchQuery.toLowerCase())
                                                : favorite,
                                        )
                                        .filter(nft =>
                                        filterStatus === "all"
                                            ? nft
                                            : filterStatus === "Buy now"
                                            ? nft.state === "sale"
                                            : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                                    )
                                        .filter(nft =>
                                            filterQuantity === "all"
                                                ? nft
                                                : filterQuantity === "single"
                                                    ? nft.collection_id == null
                                                    : nft.collection_id != null,
                                        )
                                        .filter(nft =>
                                            filterPriceAt !== "" ? Number(nft.price) >= filterPriceAt * (10 ** 9) : nft,
                                        )
                                        .filter(nft =>
                                            filterPriceTo !== "" ? Number(nft.price) <= filterPriceTo * (10 ** 9) : nft,
                                        )
                                        .filter(nft => {
                                            if (filterCategory.length === 0) {
                                                return nft
                                            } else {
                                                let flag = false;
                                                if (nft?.categories) {
                                                    nft.categories.map(cat =>
                                                        filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                    );
                                                }
                                                return flag;
                                            }
                                        })
                                        .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                        .sort((a, b) => {
                                            return OneOrMinusOne(checkScore(b), checkScore(a));
                                        }).length > 0 ? 
                                        favorites
                                        .filter(favorite =>
                                            searchQuery !== ""
                                                ? favorite.name.toLowerCase().includes(searchQuery.toLowerCase())
                                                : favorite,
                                        )
                                        .filter(nft =>
                                        filterStatus === "all"
                                            ? nft
                                            : filterStatus === "Buy now"
                                            ? nft.state === "sale"
                                            : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                                    )
                                        .filter(nft =>
                                            filterQuantity === "all"
                                                ? nft
                                                : filterQuantity === "single"
                                                    ? nft.collection_id == null
                                                    : nft.collection_id != null,
                                        )
                                        .filter(nft =>
                                            filterPriceAt !== "" ? Number(nft.price) >= filterPriceAt * (10 ** 9) : nft,
                                        )
                                        .filter(nft =>
                                            filterPriceTo !== "" ? Number(nft.price) <= filterPriceTo * (10 ** 9) : nft,
                                        )
                                        .filter(nft => {
                                            if (filterCategory.length === 0) {
                                                return nft
                                            } else {
                                                let flag = false;
                                                if (nft?.categories) {
                                                    nft.categories.map(cat =>
                                                        filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                                    );
                                                }
                                                return flag;
                                            }
                                        })
                                        .filter(nft => {
                                        if (globalExtraFilters?.length > 0) {
                                            if (nft?.attrs) {
                                                if (1) {
                                                    let flag = 0
                                                    globalExtraFilters.map(item => {
                                                        if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                            if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                                flag = 1
                                                            }
                                                        }
                                                    })
                                                    return flag
                                                } else {
                                                    return 0
                                                }
                                            } else {
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    })
                                        .sort((a, b) => {
                                            return OneOrMinusOne(checkScore(b), checkScore(a));
                                        }).map(item => item.hdr_id === null ? <SimpleCard nft={item} avatarHash={avatarHash} showEmoji key={uuid()} /> : <CollectionCard collection={item} currentUser={currentUser} key={uuid()} />)
                                        : <div className="myContent__right-items-zero">NO ITEMS</div>
                                ) : (
                                    <div className="myContent__right-items-zero">NO FAVORITES</div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Profile;