import axios from "axios";
import axs from "../../utils/axios"
import { useEffect } from "react";
import { useContext, useState } from "react";
import { ContextApp } from "../../Context";
import "./DefaultCard.scss";

function DefaultCard({nft, currentUser}) {
    const [diamond, setDiamond] = useState("dark");
    const { theme, changeTheme, setAuthPopup } = useContext(ContextApp);

    const [likes, setLikes] = useState([0, 0, 0, 0, 0, 0, 0]);

    const [likeProccess, setLikeProccess] = useState(false);

    const [lastDoWasPlaceAuction, setLastDoWasPlaceAuction] = useState(false);

    useEffect(() => {
        axs
        .post("/nft_history/list", {
            filters: {
                nft_id: nft.id.toString(),
            },
            subqueries: {
                new_owner: {
                    subqueries: {
                        img: {},
                    },
                },
                old_owner: {
                    subqueries: {
                        img: {},
                    },
                },
            },
        })
        .then(res => {
            setLastDoWasPlaceAuction(res.data.items.reverse()[0]?.event_type === "auction")
            
        })
        .catch(err => console.log(err))
    }, [])

    // set likes on start card
    useEffect(() => {
        if(nft.likes.length > 0 || nft?.anon_likes) {
            let likes_copy = [0, 0, 0, 0, 0, 0, 0];
            if (nft.likes.length > 0) {
                nft.likes.map(like => likes_copy[Number(like.type_id) - 1] += 1);
            }
            if (nft?.anon_likes) {
                Object.keys(nft.anon_likes).map(like_id => {
                    likes_copy[Number(like_id) - 1] += Number(nft.anon_likes[like_id])
                })
            }
            setLikes(likes_copy);
        }
    }, [nft.likes, nft?.anon_likes]);

    const handleUserLike = e => {
        if (!likeProccess) {
            setLikeProccess(true);
            if(localStorage.getItem("auth") === "true") {
                axios
                    .post(
                            "https://nft-one.art/api/nft_likes/list",
                            {
                                filters: {
                                    nft_id: nft?.id,
                                    user_id: currentUser.id,
                                },
                            },
                            {
                                headers: {
                                    Token: localStorage.getItem("tonkeeperToken")
                                        ? localStorage.getItem("tonkeeperToken")
                                        : localStorage.getItem("tonhubToken"),
                                },
                                auth: {
                                    username: "odmen",
                                    password: "NFTflsy",
                                },
                            },
                        )
                    .then(response => {
                        let hasLikes = response.data.items.length > 0
                        let clickTypeID = e.target.getAttribute("data-emoji")
                            
                        // если этот пользователь еще не ставил лайки этой нфт/коллекции
                        if(!hasLikes) {
                                axios
                                    .post(
                                        "https://nft-one.art/api/nft_likes/upsert",
                                        {
                                            items: [
                                                {
                                                    nft_id: nft?.id,
                                                    type_id: clickTypeID,
                                                    user_id: currentUser.id,
                                                },
                                            ],
                                        },
                                        {
                                            headers: {
                                                Token: localStorage.getItem("tonkeeperToken")
                                                    ? localStorage.getItem("tonkeeperToken")
                                                    : localStorage.getItem("tonhubToken"),
                                            },
                                            auth: {
                                                username: "odmen",
                                                password: "NFTflsy",
                                            },
                                        },
                                    )
                                    .then(response => {
                                        let likes_copy = [...likes];
                                        likes_copy[Number(clickTypeID) - 1] += 1;
                                        setLikes([...likes_copy]);
                                        setLikeProccess(false);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                        }
    
                        // если этот пользователь ставил лайки этой нфт/коллекции и выбранный лайк совпадает с уже имеющимся на сервере
                        if(hasLikes && (clickTypeID === response.data.items[0].type_id)) {
                            axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/unlike",
                                    {
                                        nft_id: nft?.id,
                                        type_id: clickTypeID,
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                    let likes_copy = [...likes];
                                    likes_copy[Number(clickTypeID) - 1] -= 1;
                                    setLikes([...likes_copy]);
                                    setLikeProccess(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        }
    
                        // если этот пользователь ставил лайки этой нфт/коллекции и выбранный лайк НЕ совпадает с уже имеющимся на сервере
                        if(hasLikes && !(clickTypeID === response.data.items[0].type_id)) {
                            let needDeleteTypeID = response.data.items[0].type_id
                            let likes_copy = [...likes];
                            likes_copy[needDeleteTypeID - 1] -= 1;
                            likes_copy[clickTypeID - 1] += 1;
                            setLikes([...likes_copy]);
                            const updateLikesOnServer = (() => { axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/unlike",
                                    {
                                        nft_id: nft?.id,
                                        type_id: needDeleteTypeID,
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                            axios
                                .post(
                                    "https://nft-one.art/api/nft_likes/upsert",
                                    {
                                        items: [
                                            {
                                                nft_id: nft?.id,
                                                type_id: clickTypeID,
                                                user_id: currentUser.id,
                                            },
                                        ],
                                    },
                                    {
                                        headers: {
                                            Token: localStorage.getItem("tonkeeperToken")
                                                ? localStorage.getItem("tonkeeperToken")
                                                : localStorage.getItem("tonhubToken"),
                                        },
                                        auth: {
                                            username: "odmen",
                                            password: "NFTflsy",
                                        },
                                    },
                                )
                                .then(response => {
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                                setLikeProccess(false);
                            })();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                setAuthPopup(true)
            }
        }
    }

    // const likesRender = () => {
    //     if(likes.every(el => el === 0)) {
    //         return (<li className={"cardDefault__info-right-emoji-item-" + theme} style={{opacity: "0"}}>
    //             😡<span>0</span>
    //         </li>)
    //     } else if (likes.reduce((total, value) => total += value)  < 5) {
    //         return (
    //             ["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((emoji, index) =>
    //                 <li className={"cardDefault__info-right-emoji-item-" + theme} key={index} style={{display: `${likes[index] === 0 ? "none" : ""}`}}>
    //                     {emoji}<span>{likes[index]}</span>
    //                 </li>
    //                 )
    //         )
    //     } else {
    //         let i = 0;
    //         return (
    //             ["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((emoji, index) => 
    //                 {
    //                     if(likes[index] === 1) {
    //                         i += 1
    //                     }
    //                     if(i < 4) {
    //                         return (<li className={"cardDefault__info-right-emoji-item-" + theme} key={index} style={{display: `${likes[index] === 0 ? "none" : ""}`}}>
    //                                     {emoji}<span>{likes[index]}</span>
    //                             </li>)
    //                     }
    //                     if(index === 6) {
    //                         return (<li className={"cardDefault__info-right-emoji-itemCount"}>
    //                             +{i - 2}
    //                         </li>)
    //                     }
    //                 }
    //             )
    //         )
    //     }
    // }

    return (
        <div
            className={`${theme === "light" ? "cardDefault" : "cardDefault cardDefault-dark"}`}
            onMouseOver={() => setDiamond("light")}
            onMouseOut={() => setDiamond("dark")}
            style={{ background: theme === "light" ? "#fff" : "#2B3239", color: theme === "light" ? "#000" : "#fff" }}>
            <a href={`/nft/${nft?.id}`}>
            <div className="cardDefault__photo" style={{background: nft?.img?.hash ? `${`url(https://nft-one.art/api/files/thumb/?hash=${nft?.img?.hash}&width=450&height=390) no-repeat center center/cover`}` : `url(/img/card/photo-2.svg) no-repeat center center/cover`}} alt="Card"></div>
            <div className="cardDefault__info">
                <div className="cardDefault__info-left">
                    <h6 className="cardDefault__info-left-title">{nft?.name?.length > 16 ? nft?.name?.slice(0, 14) + "..." : nft?.name}</h6>
                    { ["sale", "auction"].includes(nft?.state) && <p
                        className="cardDefault__info-left-price"
                        style={{ color: theme === "light" ? "#0088cc" : "#fff" }}>
                        <img src={`./img/card/diamond-${diamond}.png`} alt="Price" />
                        {
                            (nft?.state !== "sale" && !lastDoWasPlaceAuction) ? ((Number(nft?.price) - 0.01 * 10 ** 9) / 10 ** 9) :
                            Number(nft?.price) / (10 ** 9)
                        }
                    </p>}
                </div>
                <div className="cardDefault__info-right">
                    <div className="cardDefault__info-right-user">
                        <div className="cardDefault__info-right-user-avatar" style={{background: nft?.creator?.img?.hash ? `url(https://nft-one.art/api/files/thumb/?hash=${nft?.creator?.img?.hash}&width=45&height=45) no-repeat center center/cover` : "url(/img/sections/myNFT/avatar.svg) no-repeat center center / cover"}}></div>
                        <p className="cardDefault__info-right-user-name">by {nft?.creator?.name?.length > 10 ? nft?.creator?.name.slice(0, 10) + "..." : nft?.creator?.name}</p>
                    </div>
                    <button className="cardDefault__info-right-auction">{nft?.state}</button>
                </div>
            </div>
                    {
                            likes.every(el => el === 0) ? (
                                <ul className="cardDefault__emoji" style={{opacity: "0"}}>
                                    <li className={"cardDefault__emoji-item-" + theme} style={{ opacity: "0" }}>
                                        😡<span>0</span>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="cardDefault__emoji">
                                    {
                                        ["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((emoji, index) => (
                                            <li
                                                className={"cardDefault__emoji-item-" + theme}
                                                key={index}
                                                style={{ display: `${likes[index] === 0 ? "none" : ""}` }}>
                                                {emoji}
                                                <span>{likes[index]}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            )
                        }
            </a>
            <ul className="cardDefault__menuEmoji" style={{backgroundColor: changeTheme("#fff", "#2B3239")}}>
                {
                    ["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map((item, index) => <li key={index} data-emoji={index + 1} className="cardDefault__menuEmoji-item" onClick={(e) => handleUserLike(e)}>{item}</li>)
                }
            </ul>
        </div>
    );
}

export default DefaultCard;