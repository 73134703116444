import { useState, useEffect, useContext } from "react";
import Draggable from "react-draggable";
import { toast, Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import { ContextApp } from "../../../../Context";
import axios from "axios";

function AdminPanelUsers() {

    const { getAllOffsets } = useContext(ContextApp)

    const [searchQuery, setSearchQuery] = useState("");

    const [showItems, setShowItems] = useState(true);

    const [selectedAll, setSelectedAll] = useState(false);
    const [selects, setSelects] = useState([]);

    const [users, setUsers] = useState([]);
    const [usersFiltered, setUsersFiltered] = useState([])

    const [currentSort, setCurrentSort] = useState("");

    const [showAcceptDeleteUser, setShowAcceptDeleteUser] = useState(false);
    const [acceptDeleteUserUsername, setAcceptDeleteUserUsername] = useState("");
    const [acceptDeleteUserID, setAcceptDeleteUserID] = useState("");

    const [editing, setEditing] = useState("");
    const [editingStatus, setEditingStatus] = useState("");
    const [editingRole, setEditingRole] = useState("");
    const [editingRolePopup, setEditingRolePopup] = useState(false);

    const [showAcceptDeleteUsers, setShowAcceptDeleteUsers] = useState(false);

    useEffect(() => {
        getAllOffsets("users", {}, "admin").then(res => setUsers(res))
    }, [getAllOffsets])

    useEffect(() => {
        setUsersFiltered(users.filter(user => {
                if(searchQuery.includes("@")) {
                    let userQuery = searchQuery.split("@")
                    if(userQuery[0] === "name") {
                        return (user.name.toLowerCase().includes(userQuery[1].toLowerCase()))
                    } else if (userQuery[0] === "date") {
                        return (renderUserCreateDate(user).toLowerCase().includes(userQuery[1].toLowerCase()))
                    } else if (userQuery[0] === "id") {
                        return (user.id === (userQuery[1].toLowerCase()))
                    } else if (userQuery[0] === "ton_addr") {
                        return (user.ton_addr?.toLowerCase().includes(userQuery[1].toLowerCase()))
                    } else if (userQuery[0] === "email") {
                        return (user.email?.toLowerCase().includes(userQuery[1].toLowerCase()))
                    } else if (userQuery[0] === "role") {
                        return (user.user_role?.toLowerCase().includes(userQuery[1].toLowerCase()))
                    } else if (userQuery[0] === "status") {
                        let userStatus = user.is_blocked ? "banned" : "active"
                        return (userStatus.toLowerCase().includes(userQuery[1].toLowerCase()))
                    }
                } else {
                    return (user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            renderUserCreateDate(user).toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.id.includes(searchQuery.toLowerCase()) ||
                            user.ton_addr?.toLowerCase().includes(searchQuery.toLowerCase())  ||
                            user.email?.toLowerCase().includes(searchQuery.toLowerCase())  ||
                            user.user_role?.toLowerCase().includes(searchQuery.toLowerCase())) ||
                        (user.is_blocked ? "banned" : "active").toLowerCase().includes(searchQuery.toLowerCase())
                }
            }
        )
            .sort((a, b) => {
                    if(currentSort === "name-atLow") {
                        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "name-atHigh") {
                        return a.name.toUpperCase() > b.name.toUpperCase() ? -1 : a.name.toUpperCase() < b.name.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "date-atLow") {
                        return Number(a.add_time) - Number(b.add_time)
                    } else if(currentSort === "date-atHigh") {
                        return Number(b.add_time) - Number(a.add_time)
                    } else if(currentSort === "id-atLow") {
                        return Number(a.id) - Number(b.id)
                    } else if(currentSort === "id-atHigh") {
                        return Number(b.id) - Number(a.id)
                    } else if(currentSort === "addr-atLow") {
                        return a?.ton_addr?.toUpperCase() < b?.ton_addr?.toUpperCase() ? -1 : a?.ton_addr?.toUpperCase() > b?.ton_addr?.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "addr-atHigh") {
                        return a?.ton_addr?.toUpperCase() > b?.ton_addr?.toUpperCase() ? -1 : a?.ton_addr?.toUpperCase() < b?.ton_addr?.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "email-atLow") {
                        return a?.email?.toUpperCase() < b?.email?.toUpperCase() ? -1 : a?.email?.toUpperCase() > b?.email?.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "email-atHigh") {
                        return a?.email?.toUpperCase() > b?.email?.toUpperCase() ? -1 : a?.email?.toUpperCase() < b?.email?.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "role-atLow") {
                        return a?.user_role?.toUpperCase() < b?.user_role?.toUpperCase() ? -1 : a?.user_role?.toUpperCase() > b?.user_role?.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "role-atHigh") {
                        return a?.user_role?.toUpperCase() > b?.user_role?.toUpperCase() ? -1 : a?.user_role?.toUpperCase() < b?.user_role?.toUpperCase() ? 1 : 0;
                    } else if(currentSort === "status-atLow") {
                        const statusA = a.is_blocked ? "banned" : "active"
                        const statusB = b.is_blocked ? "banned" : "active"
                        return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
                    } else if(currentSort === "status-atHigh") {
                        const statusA = a.is_blocked ? "banned" : "active"
                        const statusB = b.is_blocked ? "banned" : "active"
                        return statusA > statusB ? -1 : statusA < statusB ? 1 : 0;
                    }
                }
            ))
    }, [currentSort, searchQuery, users]);

    useEffect(() => {
        setSelects([])
        setSelectedAll(false)
    }, [usersFiltered])

    const setCheckbox = e => {
        let currentID = e.target.getAttribute("data-id")
        if(selects.includes(currentID)) {
            let copy = [...selects]
            setSelects(copy.filter(id => id !== currentID))
        } else {
            setSelects([...selects, currentID])
        }
    }

    const setCheckboxesAll = () => {
        setSelectedAll(!selectedAll);
        if(!selectedAll) {
            let users_ids = []
            usersFiltered.map(user => users_ids.push(user.id))
            setSelects(users_ids);
        } else {
            setSelects([])
        }
    }

    const renderUserCreateDate = (user) => {
        let createDate = new Date(user.add_time * 1000)
        let endDate = ("0" + createDate.getDate()).slice(-2) + "." + ("0" + (Number(createDate.getMonth()) + 1)).slice(-2) + "." + createDate.getFullYear() + " " + ("0" + createDate.getHours()).slice(-2) + ":" + ("0" + createDate.getMinutes()).slice(-2) + ` (UTC +${createDate.getTimezoneOffset() / -60})`
        return (endDate)
    }

    const renderAcceptDeleteUser = () => {
        const deleteUser = () => {
            axios
                .post(
                    "https://nft-one.art/api/users/delete",
                    {
                        ids: [
                            acceptDeleteUserID
                        ]
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("adminToken")
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    getAllOffsets("users", {}, "admin").then(res => {
                        setAcceptDeleteUserUsername("")
                                setAcceptDeleteUserID("")
                                setShowAcceptDeleteUser(false);
                            setUsers(res);
                            toast.success("User deleted", {
                                position: "bottom-right",
                                style: {
                                    font: "400 18px/100% 'DM Sans'",
                                },
                            })
                    })
                    // axios
                    //     .post(
                    //         "https://nft-one.art/api/users/list",
                    //         {
                    //         },
                    //         {
                    //             headers: {
                    //                 Token: localStorage.getItem("adminToken")
                    //             },
                    //             auth: {
                    //                 username: "odmen",
                    //                 password: "NFTflsy",
                    //             },
                    //         },
                    //     )
                    //     .then(response => {
                    //         setAcceptDeleteUserUsername("")
                    //             setAcceptDeleteUserID("")
                    //             setShowAcceptDeleteUser(false);
                    //         setUsers(response.data.items);
                    //         toast.success("User deleted", {
                    //             position: "bottom-right",
                    //             style: {
                    //                 font: "400 18px/100% 'DM Sans'",
                    //             },
                    //         })
                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });     
                })
                .catch(error => {
                    console.log(error);
                });
        }
        return (
            <div className="acceptDeleteUser">
                <div className="acceptDeleteUser__content">
                    <img className="acceptDeleteUser__content-close" src="./img/header/close.png" alt="" onClick={() => {
                        setAcceptDeleteUserUsername("")
                        setAcceptDeleteUserID("")
                        setShowAcceptDeleteUser(false);
                    }}/>
                    <h1 className="acceptDeleteUser__content-title">Attention!</h1>
                    <p className="acceptDeleteUser__content-text">Are you sure you want to delete the user <span>{acceptDeleteUserUsername.length > 16 ? acceptDeleteUserUsername.slice(0, 16) + "..." : acceptDeleteUserUsername}</span>? <br/><br/>This action cannot be undone!</p>
                    <button className="acceptDeleteUser__content-button" onClick={deleteUser}>Yes, I know what I'm doing</button>
                </div>
            </div>
        )
    }

    const renderAcceptDeleteUserWithCheckboxes = () => {
        const deleteUsers = () => {
            axios
                .post(
                    "https://nft-one.art/api/users/delete",
                    {
                        ids: [
                            ...selects
                        ]
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("adminToken")
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    getAllOffsets("users", {}, "admin").then(res => {
                        setSelects([])
                        setShowAcceptDeleteUsers(false);
                        setUsers(res);
                        toast.success("Users deleted", {
                            position: "bottom-right",
                            style: {
                                font: "400 18px/100% 'DM Sans'",
                            },
                        })
                    })
                    // axios
                    //     .post(
                    //         "https://nft-one.art/api/users/list",
                    //         {
                    //         },
                    //         {
                    //             headers: {
                    //                 Token: localStorage.getItem("adminToken")
                    //             },
                    //             auth: {
                    //                 username: "odmen",
                    //                 password: "NFTflsy",
                    //             },
                    //         },
                    //     )
                    //     .then(response => {
                    //         setSelects([])
                    //         setShowAcceptDeleteUsers(false);
                    //         setUsers(response.data.items);
                    //         toast.success("Users deleted", {
                    //             position: "bottom-right",
                    //             style: {
                    //                 font: "400 18px/100% 'DM Sans'",
                    //             },
                    //         })
                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });     
                })
                .catch(error => {
                    console.log(error);
                });
        }
        
        return (
            <div className="acceptDeleteUser">
                <div className="acceptDeleteUser__content">
                    <img className="acceptDeleteUser__content-close" src="./img/header/close.png" alt="" onClick={() => {
                        setShowAcceptDeleteUsers(false);
                    }}/>
                    <h1 className="acceptDeleteUser__content-title">Attention!</h1>
                    <p className="acceptDeleteUser__content-text">Are you sure you want to delete <span>{selects.length + " users"}</span>? <br/><br/>This action cannot be undone!</p>
                    <button className="acceptDeleteUser__content-button" onClick={deleteUsers}>Yes, I know what I'm doing</button>
                </div>
            </div>
        )
    }

    const editUser = (userID, newUserRole, newUserStatus) => {
        axios
            .post(
                "https://nft-one.art/api/users/upsert",
                {
                    items: [
                        {
                            id: userID,
                            is_blocked: newUserStatus === "Active" ? false : true,
                            user_role: newUserRole
                        }
                    ]
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                getAllOffsets("users", {}, "admin").then(res => setUsers(res))
                toast.success("User changed", {
                    position: "bottom-right",
                    style: {
                        font: "400 18px/100% 'DM Sans'",
                    },
                })
            })
            .catch(error => {
                toast.error("Server error", {
                    position: "bottom-right",
                    style: {
                        font: "400 18px/100% 'DM Sans'",
                    },
                })
            });
    }

    return (
        <>
        <Tooltip id="click-me" place="bottom" style={{
            font: "400 12px/100% 'DM Sans'",
            padding: "6px 10px"
        }}/>
        <Toaster/>
        {
        window.innerWidth <= 768 ? (
            <Draggable axis="x" handle=".adminPanel__content-table-header">
                <div>
                    <div class="adminPanel__content-table">
                        <div className="adminPanel__content-table-header">
                            <h1 className="adminPanel__content-table-header-title">
                                Users 
                                <span style={{color: "red"}}>
                                    {selects.length > 0 ? " (" + selects.length + " selected)" : ""}
                                    {
                                        selects.length > 0 && <img src="/img/adminPanel/delete.svg" alt="" onClick={() => {
                                            setShowAcceptDeleteUsers(true)
                                        }}/>
                                    }
                                </span>
                            </h1>
                            <button className="adminPanel__content-table-header-show" onClick={() => setShowItems(!showItems)}>
                                {showItems ? `Hide ${usersFiltered.length} items` : `Show ${usersFiltered.length} items`}
                                <img
                                    className="adminPanel__content-table-header-show-img"
                                    src="./img/adminPanel/arrow.svg"
                                    alt=""
                                    style={{ transform: showItems ? "rotate(180deg)" : "rotate(0deg)" }}
                                />
                            </button>
                        </div>
                        {showItems && (
                            <>
                                <div className="adminPanel__content-table-search">
                                    <img src="./img/adminPanel/search.svg" alt="" />
                                    <input
                                        placeholder="Search Name, Date, Number..."
                                        type="text"
                                        className="adminPanel__content-table-search-input"
                                        value={searchQuery}
                                        onChange={e => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="adminPanel__content-table-main">
                                    <div class="adminPanel__content-table-main-head">
                                        <div class="adminPanel__content-table-main-head-title">
                                            <div className={selectedAll ? "adminPanel__content-table-main-head-title--checked" : null}>
                                                <input type="checkbox" onClick={setCheckboxesAll}/>
                                            </div>
                                            <span onClick={() => {
                                                if(currentSort !== "name-atLow" && currentSort !== "name-atHigh") {
                                                    setCurrentSort("name-atLow")
                                                } else if(currentSort === "name-atLow") {
                                                    setCurrentSort("name-atHigh")
                                                } else if(currentSort === "name-atHigh") {
                                                    setCurrentSort("name-atLow")
                                                }
                                            }}>
                                                First Name Last Name
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span onClick={() => {
                                                if(currentSort !== "date-atLow" && currentSort !== "date-atHigh") {
                                                    setCurrentSort("date-atLow")
                                                } else if(currentSort === "date-atLow") {
                                                    setCurrentSort("date-atHigh")
                                                } else if(currentSort === "date-atHigh") {
                                                    setCurrentSort("date-atLow")
                                                }
                                            }}>
                                                Date
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span onClick={() => {
                                                if(currentSort !== "id-atLow" && currentSort !== "id-atHigh") {
                                                    setCurrentSort("id-atLow")
                                                } else if(currentSort === "id-atLow") {
                                                    setCurrentSort("id-atHigh")
                                                } else if(currentSort === "id-atHigh") {
                                                    setCurrentSort("id-atLow")
                                                }
                                            }}>
                                                ID
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span onClick={() => {
                                                if(currentSort !== "addr-atLow" && currentSort !== "addr-atHigh") {
                                                    setCurrentSort("addr-atLow")
                                                } else if(currentSort === "addr-atLow") {
                                                    setCurrentSort("addr-atHigh")
                                                } else if(currentSort === "addr-atHigh") {
                                                    setCurrentSort("addr-atLow")
                                                }
                                            }}>
                                            Ton addr
                                            <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span onClick={() => {
                                                if(currentSort !== "email-atLow" && currentSort !== "email-atHigh") {
                                                    setCurrentSort("email-atLow")
                                                } else if(currentSort === "email-atLow") {
                                                    setCurrentSort("email-atHigh")
                                                } else if(currentSort === "email-atHigh") {
                                                    setCurrentSort("email-atLow")
                                                }
                                            }}>
                                            E-mail
                                            <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span onClick={() => {
                                                if(currentSort !== "role-atLow" && currentSort !== "role-atHigh") {
                                                    setCurrentSort("role-atLow")
                                                } else if(currentSort === "role-atLow") {
                                                    setCurrentSort("role-atHigh")
                                                } else if(currentSort === "role-atHigh") {
                                                    setCurrentSort("role-atLow")
                                                }
                                            }}>
                                            Role
                                            <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                        <span onClick={() => {
                                                if(currentSort !== "status-atLow" && currentSort !== "status-atHigh") {
                                                    setCurrentSort("status-atLow")
                                                } else if(currentSort === "status-atLow") {
                                                    setCurrentSort("status-atHigh")
                                                } else if(currentSort === "status-atHigh") {
                                                    setCurrentSort("status-atLow")
                                                }
                                            }}>
                                        Status
                                            <img src="./img/adminPanel/sort.svg" alt="" />
                                        </span>
                                        </div>
                                    </div>
                                    {usersFiltered.map(user => (
                                        <div class="adminPanel__content-table-main-row">
                                            <div class="adminPanel__content-table-main-row-name">
                                                <div className={selects.includes(user.id) ? "adminPanel__content-table-main-head-title--checked" : null}>
                                                    <input data-id={user.id} type="checkbox" onClick={(e) => setCheckbox(e)}/>
                                                </div>
                                                <a href={`/profile/${user.id}`} target="_blank" rel="noreferrer">{user.name.length > 16 ? user.name.slice(0, 16) + "..." : user.name}</a>
                                            </div>
                                            <div class="adminPanel__content-table-main-row-date">{renderUserCreateDate(user)}</div>
                                            <div class="adminPanel__content-table-main-row-title">{"User ID: " + user.id}</div>
                                            <div class="adminPanel__content-table-main-row-title"><a href="#"
                                                                                                     data-tooltip-id="click-me"
                                                                                                     data-tooltip-content="Click for copy"
                                                                                                     onClick={() => {
                                                                                                         navigator.clipboard.writeText(user?.ton_addr).then(
                                                                                                             () => {
                                                                                                                 toast.success("Copied to Clipboard", {
                                                                                                                     position: "bottom-right",
                                                                                                                     style: {
                                                                                                                         font: "400 21px/100% 'DM Sans'",
                                                                                                                     },
                                                                                                                 });
                                                                                                             },
                                                                                                             () => {
                                                                                                                 toast.error("An error has occurred", {
                                                                                                                     position: "bottom-right",
                                                                                                                     style: {
                                                                                                                         font: "400 21px/100% 'DM Sans'",
                                                                                                                     },
                                                                                                                 });
                                                                                                             },
                                                                                                         );
                                                                                                     }}>{user?.ton_addr == null ? "—" : user.ton_addr.length > 16 ? user.ton_addr.slice(0, 16) + "..." : user.ton_addr}</a></div>
                                            <div class="adminPanel__content-table-main-row-title">{user.email === "" ? "—" : user.email}</div>
                                            <div class="adminPanel__content-table-main-row-title">
                                                {
                                                    editing === user.id ? (
                                                        <span onClick={() => setEditingRolePopup(!editingRolePopup)}>
                                                                {editingRole}
                                                            <img src="/img/adminPanel/arrow-down.svg" alt=""/>
                                                            </span>
                                                    ) : user.user_role
                                                }
                                                {
                                                    editing === user.id && editingRolePopup && (
                                                        <ul className="adminPanel__content-table-main-row-title-popup">
                                                            {["admin", "editor", "moderator", "user"].map(role => role !== editingRole && (
                                                                <li className="adminPanel__content-table-main-row-title-popup-role" onClick={(e) => {setEditingRole(e.target.innerText)
                                                                    setEditingRolePopup(false)}}>{role}</li>
                                                            ))}
                                                        </ul>
                                                    )
                                                }
                                            </div>
                                            <div class="adminPanel__content-table-main-row-status">
                                                {editing !== user.id && (
                                                    <div className={`adminPanel__content-table-main-row-status-${!user.is_blocked ? "active" : "banned"}`}>
                                                        {!user.is_blocked ? "Active" : "Banned"}
                                                    </div>
                                                )
                                                }
                                                {editing === user.id && (
                                                    <div className={`adminPanel__content-table-main-row-status-${editingStatus.toLowerCase()}`} style={{cursor: "pointer"}} onClick={() => setEditingStatus(editingStatus === "Active" ? "Banned" : "Active")}>
                                                        {editingStatus}
                                                    </div>
                                                )
                                                }
                                            </div>
                                            <div className="adminPanel__content-table-main-row-btns">
                                                {
                                                    (editing !== user.id) ? (<>
                                                            <img src="./img/adminPanel/delete.svg" alt="Delete user" onClick={() => {
                                                                setAcceptDeleteUserID(user.id);
                                                                setAcceptDeleteUserUsername(user.name);
                                                                setShowAcceptDeleteUser(true)
                                                            }}/>
                                                            <img  src="./img/adminPanel/edit.svg" alt="Edit user" onClick={() => {
                                                                setEditing(user.id)
                                                                setEditingStatus(!user.is_blocked ? "Active" : "Banned")
                                                                setEditingRole(user.user_role)
                                                            }}/>
                                                        </>) :
                                                        (<>
                                                            <img className="adminPanel__content-table-main-row-btns-yes" src="./img/adminPanel/yes-new.svg" alt="Yes"
                                                                 onClick={() => {
                                                                     editUser(user.id, editingRole, editingStatus)
                                                                     setEditing("")
                                                                     setEditingStatus("")
                                                                     setEditingRole("")
                                                                     setEditingRolePopup(false)
                                                                 }
                                                                 }
                                                            />
                                                            <img className="adminPanel__content-table-main-row-btns-no" src="./img/adminPanel/no-new.svg" alt="No" onClick={() => {
                                                                setEditing("")
                                                                setEditingStatus("")
                                                                setEditingRole("")
                                                                setEditingRolePopup(false)
                                                            }}/>
                                                        </>)
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Draggable>
        ) : (<div class="adminPanel__content-table">
            <div className="adminPanel__content-table-header">
                <h1 className="adminPanel__content-table-header-title">
                    Users 
                    <span style={{color: "red"}}>
                        {selects.length > 0 ? " (" + selects.length + " selected)" : ""}
                        {
                            selects.length > 0 && <img src="/img/adminPanel/delete.svg" alt="" onClick={() => {
                                setShowAcceptDeleteUsers(true)
                            }}/>
                        }
                    </span>
                </h1>
                <button className="adminPanel__content-table-header-show" onClick={() => setShowItems(!showItems)}>
                    {showItems ? `Hide ${usersFiltered.length} items` : `Show ${usersFiltered.length} items`}
                    <img
                        className="adminPanel__content-table-header-show-img"
                        src="./img/adminPanel/arrow.svg"
                        alt=""
                        style={{ transform: showItems ? "rotate(180deg)" : "rotate(0deg)" }}
                    />
                </button>
            </div>
            {showItems && (
                <>
                    <div className="adminPanel__content-table-search">
                        <img src="./img/adminPanel/search.svg" alt="" />
                        <input
                            placeholder="Search Name, Date, Number..."
                            type="text"
                            class="adminPanel__content-table-search-input"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className="adminPanel__content-table-main">
                        <div class="adminPanel__content-table-main-head">
                            <div class="adminPanel__content-table-main-head-title">
                                <div className={selectedAll ? "adminPanel__content-table-main-head-title--checked" : null}>
                                    <input type="checkbox" onClick={setCheckboxesAll}/>
                                </div>
                                <span onClick={() => {
                                    if(currentSort !== "name-atLow" && currentSort !== "name-atHigh") {
                                        setCurrentSort("name-atLow")
                                    } else if(currentSort === "name-atLow") {
                                        setCurrentSort("name-atHigh")
                                    } else if(currentSort === "name-atHigh") {
                                        setCurrentSort("name-atLow")
                                    }
                                }}>
                                    First Name Last Name
                                    <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "date-atLow" && currentSort !== "date-atHigh") {
                                        setCurrentSort("date-atLow")
                                    } else if(currentSort === "date-atLow") {
                                        setCurrentSort("date-atHigh")
                                    } else if(currentSort === "date-atHigh") {
                                        setCurrentSort("date-atLow")
                                    }
                                }}>
                                    Date
                                    <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "id-atLow" && currentSort !== "id-atHigh") {
                                        setCurrentSort("id-atLow")
                                    } else if(currentSort === "id-atLow") {
                                        setCurrentSort("id-atHigh")
                                    } else if(currentSort === "id-atHigh") {
                                        setCurrentSort("id-atLow")
                                    }
                                }}>
                                    ID
                                    <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "addr-atLow" && currentSort !== "addr-atHigh") {
                                        setCurrentSort("addr-atLow")
                                    } else if(currentSort === "addr-atLow") {
                                        setCurrentSort("addr-atHigh")
                                    } else if(currentSort === "addr-atHigh") {
                                        setCurrentSort("addr-atLow")
                                    }
                                }}>
                                Ton addr
                                <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "email-atLow" && currentSort !== "email-atHigh") {
                                        setCurrentSort("email-atLow")
                                    } else if(currentSort === "email-atLow") {
                                        setCurrentSort("email-atHigh")
                                    } else if(currentSort === "email-atHigh") {
                                        setCurrentSort("email-atLow")
                                    }
                                }}>
                                E-mail
                                <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "role-atLow" && currentSort !== "role-atHigh") {
                                        setCurrentSort("role-atLow")
                                    } else if(currentSort === "role-atLow") {
                                        setCurrentSort("role-atHigh")
                                    } else if(currentSort === "role-atHigh") {
                                        setCurrentSort("role-atLow")
                                    }
                                }}>
                                Role
                                <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                               <span onClick={() => {
                                    if(currentSort !== "status-atLow" && currentSort !== "status-atHigh") {
                                        setCurrentSort("status-atLow")
                                    } else if(currentSort === "status-atLow") {
                                        setCurrentSort("status-atHigh")
                                    } else if(currentSort === "status-atHigh") {
                                        setCurrentSort("status-atLow")
                                    }
                                }}>
                               Status
                                <img src="./img/adminPanel/sort.svg" alt="" />
                               </span>
                            </div>
                        </div>
                        {usersFiltered
                            .map(user => (
                                <div class="adminPanel__content-table-main-row">
                                    <div class="adminPanel__content-table-main-row-name">
                                        <div className={selects.includes(user.id) ? "adminPanel__content-table-main-head-title--checked" : null}>
                                            <input data-id={user.id} type="checkbox" onClick={(e) => setCheckbox(e)}/>
                                        </div>
                                        <a href={`/profile/${user.id}`} target="_blank" rel="noreferrer">{user?.name?.length > 16 ? user?.name?.slice(0, 16) + "..." : user?.name}</a>
                                    </div>
                                    <div class="adminPanel__content-table-main-row-date">{renderUserCreateDate(user)}</div>
                                    <div class="adminPanel__content-table-main-row-title">{"User ID: " + user.id}</div>
                                    <div class="adminPanel__content-table-main-row-title"><a href="#"
                                                data-tooltip-id="click-me"
                                                data-tooltip-content="Click for copy"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(user?.ton_addr).then(
                                                        () => {
                                                            toast.success("Copied to Clipboard", {
                                                                position: "bottom-right",
                                                                style: {
                                                                    font: "400 21px/100% 'DM Sans'",
                                                                },
                                                            });
                                                        },
                                                        () => {
                                                            toast.error("An error has occurred", {
                                                                position: "bottom-right",
                                                                style: {
                                                                    font: "400 21px/100% 'DM Sans'",
                                                                },
                                                            });
                                                        },
                                                    );
                                                }}>{user?.ton_addr == null ? "—" : user.ton_addr.length > 16 ? user.ton_addr.slice(0, 16) + "..." : user.ton_addr}</a></div>
                                    <div class="adminPanel__content-table-main-row-title">{user.email === "" ? "—" : user.email}</div>
                                    <div class="adminPanel__content-table-main-row-title">
                                        {
                                            editing === user.id ? (
                                                <span onClick={() => setEditingRolePopup(!editingRolePopup)}>
                                                    {editingRole}
                                                    <img src="/img/adminPanel/arrow-down.svg" alt=""/>
                                                </span>
                                            ) : user.user_role
                                        }
                                        {
                                            editing === user.id && editingRolePopup && (
                                                <ul className="adminPanel__content-table-main-row-title-popup">
                                                    {["admin", "editor", "moderator", "user"].map(role => role !== editingRole && (
                                                        <li className="adminPanel__content-table-main-row-title-popup-role" onClick={(e) => {setEditingRole(e.target.innerText)
                                                        setEditingRolePopup(false)}}>{role}</li>
                                                    ))}
                                                </ul>
                                            )
                                        }
                                    </div>
                                    <div class="adminPanel__content-table-main-row-status">
                                        {editing !== user.id && (
                                            <div className={`adminPanel__content-table-main-row-status-${!user.is_blocked ? "active" : "banned"}`}>
                                                {!user.is_blocked ? "Active" : "Banned"}
                                            </div>
                                        )
                                        }
                                        {editing === user.id && (
                                                <div className={`adminPanel__content-table-main-row-status-${editingStatus.toLowerCase()}`} style={{cursor: "pointer"}} onClick={() => setEditingStatus(editingStatus === "Active" ? "Banned" : "Active")}>
                                                    {editingStatus}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="adminPanel__content-table-main-row-btns">
                                        {
                                            (editing !== user.id) ? (<>
                                                <img src="./img/adminPanel/delete.svg" alt="Delete user" onClick={() => {
                                                    setAcceptDeleteUserID(user.id);
                                                    setAcceptDeleteUserUsername(user.name);
                                                    setShowAcceptDeleteUser(true)
                                                }}/>
                                                <img  src="./img/adminPanel/edit.svg" alt="Edit user" onClick={() => {
                                                    setEditing(user.id)
                                                    setEditingStatus(!user.is_blocked ? "Active" : "Banned")
                                                    setEditingRole(user.user_role)
                                                }}/>
                                            </>) :
                                            (<>
                                                <img className="adminPanel__content-table-main-row-btns-yes" src="./img/adminPanel/yes-new.svg" alt="Yes"
                                                    onClick={() => {
                                                            editUser(user.id, editingRole, editingStatus)
                                                            setEditing("")
                                                            setEditingStatus("")
                                                            setEditingRole("")
                                                            setEditingRolePopup(false)
                                                        }
                                                    }
                                                />
                                                <img className="adminPanel__content-table-main-row-btns-no" src="./img/adminPanel/no-new.svg" alt="No" onClick={() => {
                                                    setEditing("")
                                                    setEditingStatus("")
                                                    setEditingRole("")
                                                    setEditingRolePopup(false)
                                                }}/>
                                            </>)
                                        }
                                    </div>
                                </div>
                            ))}
                    </div>
                </>
            )}
        </div>)
        }
        {showAcceptDeleteUser && renderAcceptDeleteUser()}
        {showAcceptDeleteUsers && renderAcceptDeleteUserWithCheckboxes()}
        </>
    );
}

export default AdminPanelUsers