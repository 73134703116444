import { useContext } from "react";
import { ContextApp } from "../../Context";
import styles from "./Popup.module.scss";

export const Popup = ({ children, show, onShow, extraClass }) => {
    const { changeTheme } = useContext(ContextApp);

    return (
        <>
            {show && (
                <div className={styles.wrapper + " " + (extraClass ? extraClass : "")}>
                    <div className={styles.popup + ` ${changeTheme("", "popup__dark")}`} style={{
                        backgroundColor: changeTheme("#fff", "#1C2026"),
                        color: changeTheme("#000", "#fff")
                    }}>
                        <img
                            className={styles.close}
                            onClick={() => {
                                onShow(false);
                            }}
                            src={`/img/header/${changeTheme("close", "close-white")}.png`}
                            alt="Close"
                        />
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};
