import { useContext, useEffect, useState } from "react";
import "./AdminPanel.scss";
import { ContextApp } from "../../Context";
import NotFound from "./../NotFound";
import Draggable from "react-draggable";
import AdminPanelHeader from "./components/AdminPanelHeader";
import AdminPanelUsers from "./components/AdminPanelUsers";
import AdminPanelNFT from "./components/AdminPanelNFT";
import AdminPanelCollections from "./components/AdminPanelCollections";
import AdminPanelStatistics from "./components/AdminPanelStatistics";
import AdminPanelSettings from "./components/AdminPanelSettings";
import { AdminPanelHome } from "./components/AdminPanelHome";
import { AdminPanelFinance } from "./components/AdminPanelFinance";
import AdminPanelAuth from "./components/AdminPanelAuth";
import AdminPanelFAQ from "./components/AdminPanelFAQ";

function AdminPanel() {
    const { changeTheme, currentUser } = useContext(ContextApp);
    const [menu, setMenu] = useState(true);
    const [currentPage, setCurrentPage] = useState("home");
    const [adminPanelAuth, setAdminPanelAuth] = useState(true);

    const [isAdmin, setIsAdmin] = useState(true);

    // проверка на то, админ перед нами или модер
    useEffect(() => {
        if (localStorage.getItem("adminToken")) {
            if (currentUser?.user_role === "admin") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        }
    }, [currentUser])

    useEffect(() => {
        setAdminPanelAuth(localStorage.getItem("adminToken") ? false : true);
    }, []);

    return (
        <>

            {
                ((Object.keys(currentUser).length === 0) || ((currentUser?.user_role !== "admin") && (currentUser?.user_role !== "moderator"))) ? <NotFound/> : 
                <>
                    {
                        !adminPanelAuth ? (
                            <>
                                <AdminPanelHeader setAdminPanelAuth={setAdminPanelAuth} />
                                <section className={`adminPanel ${changeTheme("", "adminPanel--dark")}`}>
                                    <div class={`adminPanel__menu ${!menu ? "adminPanel__menu--low" : ""}`}>
                                        <img
                                            className="adminPanel__menu-burger"
                                            onClick={() => setMenu(!menu)}
                                            src="./img/header/burger.svg"
                                            alt=""
                                        />
                                        {(isAdmin ? ["Home", "Finance", "Users", "Collections", "NFT", "FAQ", "Statistics", "Settings"] : ["Home", "Collections", "NFT"]).map(
                                            item => (
                                                <div
                                                    className={`adminPanel__menu-item ${
                                                        currentPage === item.toLowerCase() ? "adminPanel__menu--active" : null
                                                    }`}
                                                    data-item={item.toLowerCase()}
                                                    onClick={e => {
                                                        setCurrentPage(e.target.getAttribute("data-item"));
                                                    }}>
                                                    <img
                                                        src={`/img/header/${item.toLowerCase()}.svg`}
                                                        alt=""
                                                        data-item={item.toLowerCase()}
                                                        onClick={e => {
                                                            setCurrentPage(e.target.getAttribute("data-item"));
                                                        }}
                                                    />
                                                    <a
                                                        className="adminPanel__menu-item-text"
                                                        href={"#"}
                                                        data-item={item.toLowerCase()}
                                                        onClick={e => {
                                                            setCurrentPage(e.target.getAttribute("data-item"));
                                                        }}>
                                                        {menu && item}
                                                    </a>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div class="adminPanel__mobileMenu">
                                        {(isAdmin ? ["Home", "Finance", "Users", "Collections", "NFT", "FAQ", "Statistics", "Settings"] : ["Home", "Collections", "NFT"]).map(
                                            item => (
                                                <div
                                                    className={
                                                        currentPage === item.toLowerCase() ? "adminPanel__mobileMenu--active" : null
                                                    }
                                                    >
                                                    <img src={`./img/header/${item.toLowerCase()}.svg`} alt="" />
                                                    <a
                                                        className="adminPanel__mobileMenu-link"
                                                        href={"#"}
                                                        data-item={item.toLowerCase()}
                                                        onClick={e => {
                                                            setCurrentPage(e.target.getAttribute("data-item"));
                                                        }}>
                                                        {item}
                                                    </a>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div class="adminPanel__content">
                                        {currentPage === "users" ? (
                                                <AdminPanelUsers />
                                        ) : currentPage === "statistics" ? (
                                            <AdminPanelStatistics />
                                        ) : currentPage === "faq" ? (
                                            <AdminPanelFAQ />
                                        ) : currentPage === "nft" ? (
                                            window.innerWidth <= 768 ? (
                                                        <AdminPanelNFT />
                                            ) : (
                                                <AdminPanelNFT />
                                            )
                                        ) : currentPage === "collections" ? (
                                            window.innerWidth <= 768 ? (
                                                        <AdminPanelCollections />
                                            ) : (
                                                <AdminPanelCollections />
                                            )
                                        ) : currentPage === "settings" ? (
                                            <AdminPanelSettings />
                                        ) : currentPage === "home" ? (
                                            <AdminPanelHome isAdmin={isAdmin} setPage={arg => setCurrentPage(arg)} />
                                        ) : currentPage === "finance" ? (
                                            window.innerWidth <= 768 ? (
                                                <Draggable axis="x" handle=".adminPanel__content-table-header">
                                                    <div>
                                                        <AdminPanelFinance />
                                                    </div>
                                                </Draggable>
                                            ) : (
                                                <AdminPanelFinance />
                                            )
                                        ) : null}
                                    </div>
                                </section>
                            </>
                        ) : (
                            <AdminPanelAuth />
                        )
                    }
                </>
            }
        </>
    );
}

export default AdminPanel;