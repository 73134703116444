import { useState, useEffect, useContext } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { ContextApp } from "../../../../Context";

export const AdminPanelFinance = () => {

    const { getAllOffsets } = useContext(ContextApp)

    const [searchQuery, setSearchQuery] = useState("");

    const [showItems, setShowItems] = useState(true);

    const [operations, setOperations] = useState([]);

    const [currentSort, setCurrentSort] = useState("");

    useEffect(() => {
        getAllOffsets("nft_history", {}, "admin").then(res => setOperations(res))
    }, [getAllOffsets])

    const renderUserCreateDate = (user) => {
        let createDate = new Date(user.add_time * 1000)
        let endDate = ("0" + createDate.getDate()).slice(-2) + "." + ("0" + (Number(createDate.getMonth()) + 1)).slice(-2) + "." + createDate.getFullYear() + " " + ("0" + createDate.getHours()).slice(-2) + ":" + ("0" + createDate.getMinutes()).slice(-2) + ` (UTC +${createDate.getTimezoneOffset() / -60})`
        return (endDate)
    }
    
    const getOldOrNewOwnerAddr = (operation, is_short_str = false, type = "old") => {
        let str = null;

        if (operation.event_type === "deploy") {
            str = operation?.new_owner_addr;
        } else if (["sale", "auction"].includes(operation.event_type)) {
            str = operation?.old_owner_addr;
        } else if (["end", "transfer", "buy"].includes(operation.event_type)) {
            str = type === "old" ? operation?.old_owner_addr : operation?.new_owner_addr;
        }

        return is_short_str && str && str.length > 16? str.substring(0, 16) + '...' : str;
    }

    return (
        <>
        <Tooltip id="click-me" place="bottom" style={{
            font: "400 12px/100% 'DM Sans'",
            padding: "6px 10px"
        }}/>
        <Toaster/>
        <div class="adminPanel__content-table">
            <div className="adminPanel__content-table-header">
                <h1 className="adminPanel__content-table-header-title">
                    Finance operations
                </h1>
                <button className="adminPanel__content-table-header-show" onClick={() => setShowItems(!showItems)}>
                    {showItems ? `Hide ${operations.length} items` : `Show ${operations.length} items`}
                    <img
                        className="adminPanel__content-table-header-show-img"
                        src="./img/adminPanel/arrow.svg"
                        alt=""
                        style={{ transform: showItems ? "rotate(180deg)" : "rotate(0deg)" }}
                    />
                </button>
            </div>
            {showItems && (
                <>
                    <div className="adminPanel__content-table-search">
                        <img src="./img/adminPanel/search.svg" alt="" />
                        <input
                            placeholder="Search Name, Date, Number..."
                            type="text"
                            class="adminPanel__content-table-search-input"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className="adminPanel__content-table-main">
                        <div class="adminPanel__content-table-main-head">
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "date-atLow" && currentSort !== "date-atHigh") {
                                        setCurrentSort("date-atLow")
                                    } else if(currentSort === "date-atLow") {
                                        setCurrentSort("date-atHigh")
                                    } else if(currentSort === "date-atHigh") {
                                        setCurrentSort("date-atLow")
                                    }
                                }}>
                                    Date
                                    <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "name-atLow" && currentSort !== "name-atHigh") {
                                        setCurrentSort("name-atLow")
                                    } else if(currentSort === "name-atLow") {
                                        setCurrentSort("name-atHigh")
                                    } else if(currentSort === "name-atHigh") {
                                        setCurrentSort("name-atLow")
                                    }
                                }}>
                                    Operation
                                    <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "id-atLow" && currentSort !== "id-atHigh") {
                                        setCurrentSort("id-atLow")
                                    } else if(currentSort === "id-atLow") {
                                        setCurrentSort("id-atHigh")
                                    } else if(currentSort === "id-atHigh") {
                                        setCurrentSort("id-atLow")
                                    }
                                }}>
                                    Operation ID
                                    <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "collection-atLow" && currentSort !== "collection-atHigh") {
                                        setCurrentSort("collection-atLow")
                                    } else if(currentSort === "collection-atLow") {
                                        setCurrentSort("collection-atHigh")
                                    } else if(currentSort === "collection-atHigh") {
                                        setCurrentSort("collection-atLow")
                                    }
                                }}>
                                NFT ID:
                                <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "creator-atLow" && currentSort !== "creator-atHigh") {
                                        setCurrentSort("creator-atLow")
                                    } else if(currentSort === "creator-atLow") {
                                        setCurrentSort("creator-atHigh")
                                    } else if(currentSort === "creator-atHigh") {
                                        setCurrentSort("creator-atLow")
                                    }
                                }}>
                                From
                                <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                                <span onClick={() => {
                                    if(currentSort !== "owner-atLow" && currentSort !== "owner-atHigh") {
                                        setCurrentSort("owner-atLow")
                                    } else if(currentSort === "owner-atLow") {
                                        setCurrentSort("owner-atHigh")
                                    } else if(currentSort === "owner-atHigh") {
                                        setCurrentSort("owner-atLow")
                                    }
                                }}>
                                To
                                <img src="./img/adminPanel/sort.svg" alt="" />
                                </span>
                            </div>
                            <div class="adminPanel__content-table-main-head-title">
                               <span onClick={() => {
                                    if(currentSort !== "state-atLow" && currentSort !== "state-atHigh") {
                                        setCurrentSort("state-atLow")
                                    } else if(currentSort === "state-atLow") {
                                        setCurrentSort("state-atHigh")
                                    } else if(currentSort === "state-atHigh") {
                                        setCurrentSort("state-atLow")
                                    }
                                }}>
                               State
                                <img src="./img/adminPanel/sort.svg" alt="" />
                               </span>
                            </div>
                        </div>
                        {operations
                            .filter(
                                operation => {
                                    if(searchQuery.includes("@")) {
                                        let userQuery = searchQuery.split("@")
                                        if(userQuery[0] === "operation") {
                                            return (operation.event_type.toLowerCase().includes(userQuery[1].toLowerCase()))
                                        } else if (userQuery[0] === "date") {
                                            return (renderUserCreateDate(operation).toLowerCase().includes(userQuery[1].toLowerCase()))
                                        } else if (userQuery[0] === "id") {
                                            return (operation.id === (userQuery[1].toLowerCase()))
                                        } else if (userQuery[0] === "nftId") {
                                            return (operation.nft_id === (userQuery[1].toLowerCase()))
                                        }
                                    } else {
                                        return (
                                        renderUserCreateDate(operation).toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        operation?.nft_id.includes(searchQuery.toLowerCase()) ||
                                        operation.event_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        operation.id.includes(searchQuery.toLowerCase())
                                        )
                                    }
                                }
                            )
                            .sort((a, b) => {
                                    if(currentSort === "name-atLow") {
                                        return a?.event_type.toUpperCase() < b?.event_type.toUpperCase() ? -1 : a?.event_type.toUpperCase() > b?.event_type.toUpperCase() ? 1 : 0; 
                                    } else if(currentSort === "name-atHigh") {
                                        return a?.event_type.toUpperCase() > b?.event_type.toUpperCase() ? -1 : a?.event_type.toUpperCase() < b?.event_type.toUpperCase() ? 1 : 0;
                                    } else if(currentSort === "date-atLow") {
                                        return Number(a.add_time) - Number(b.add_time)
                                    } else if(currentSort === "date-atHigh") {
                                        return Number(b.add_time) - Number(a.add_time)
                                    } else if(currentSort === "id-atLow") {
                                        return Number(a.id) - Number(b.id)
                                    } else if(currentSort === "id-atHigh") {
                                        return Number(b.id) - Number(a.id)
                                    } else if(currentSort === "collection-atLow") {
                                        return Number(a.nft_id) - Number(b.nft_id)
                                    } else if(currentSort === "collection-atHigh") {
                                        return Number(b.nft_id) - Number(a.nft_id)
                                    } else if(currentSort === "creator-atLow") {
                                        let creatorNameA = "—";
                                        if (a?.event_type === "deploy") { 
                                            creatorNameA = a?.props?.from?.length > 16 ? a?.props?.from?.slice(0, 16) + "..." : a?.props?.from
                                        } else if (a?.event_type === "sale" || a?.event_type === "buy") {
                                            creatorNameA = a?.from_addr?.length > 16 ?  a?.from_addr?.slice(0, 16) + "..." :  a?.from_addr
                                        }
                                        let creatorNameB = "—";
                                        if (b?.event_type === "deploy") { 
                                            creatorNameB = b?.props?.from?.length > 16 ? b?.props?.from?.slice(0, 16) + "..." : b?.props?.from
                                        } else if (b?.event_type === "sale" || a?.event_type === "buy") {
                                            creatorNameB = b?.from_addr?.length > 16 ?  b?.from_addr?.slice(0, 16) + "..." :  b?.from_addr
                                        }
                                        return creatorNameA < creatorNameB ? -1 : creatorNameA > creatorNameB ? 1 : 0;
                                    } else if(currentSort === "creator-atHigh") {
                                        let creatorNameA = "—";
                                        if (a?.event_type === "deploy") { 
                                            creatorNameA = a?.props?.from?.length > 16 ? a?.props?.from?.slice(0, 16) + "..." : a?.props?.from
                                        } else if (a?.event_type === "sale" || a?.event_type === "buy") {
                                            creatorNameA = a?.from_addr?.length > 16 ?  a?.from_addr?.slice(0, 16) + "..." :  a?.from_addr
                                        }
                                        let creatorNameB = "—";
                                        if (b?.event_type === "deploy") { 
                                            creatorNameB = b?.props?.from?.length > 16 ? b?.props?.from?.slice(0, 16) + "..." : b?.props?.from
                                        } else if (b?.event_type === "sale" || b?.event_type === "buy") {
                                            creatorNameB = b?.from_addr?.length > 16 ?  b?.from_addr?.slice(0, 16) + "..." :  b?.from_addr
                                        }
                                        return creatorNameA > creatorNameB ? -1 : creatorNameA < creatorNameB ? 1 : 0;
                                    } else if(currentSort === "owner-atLow") {
                                        return a?.creator?.name?.toUpperCase() < b?.creator?.name?.toUpperCase() ? -1 : a?.creator?.name?.toUpperCase() > b?.creator?.name?.toUpperCase() ? 1 : 0; 
                                    } else if(currentSort === "owner-atHigh") {
                                        return a?.creator?.name?.toUpperCase() > b?.creator?.name?.toUpperCase() ? -1 : a?.creator?.name?.toUpperCase() < b?.creator?.name?.toUpperCase() ? 1 : 0; 
                                    }
                                }
                            )
                            .map(operation => (
                                <div className="adminPanel__content-table-main-row">
                                    <div className="adminPanel__content-table-main-row-name">
                                        {renderUserCreateDate(operation)}
                                    </div>
                                    <div className="adminPanel__content-table-main-row-date"><span style={{
                                            color: operation?.event_type === "deploy" ? "#CC00CC" : operation?.event_type === "sale" || operation?.event_type === "buy" ? "#00CC00" : "#0033FF"
                                        }}>{operation?.event_type?.toUpperCase()}</span>
                                    </div>
                                    <div className="adminPanel__content-table-main-row-title">
                                        {operation?.id}
                                    </div>
                                    <div className="adminPanel__content-table-main-row-title">
                                        <a href={`/nft/${operation?.nft_id}`}>{operation?.nft_id}</a>
                                    </div>
                                    <div className="adminPanel__content-table-main-row-title">
                                        {
                                            getOldOrNewOwnerAddr(operation) ? (
                                                <a href="#"
                                                   data-tooltip-id="click-me"
                                                   data-tooltip-content="Click for copy"
                                                   onClick={() => {
                                                       navigator.clipboard.writeText(getOldOrNewOwnerAddr(operation)).then(
                                                           () => {
                                                               toast.success("Copied to Clipboard", {
                                                                   position: "bottom-right",
                                                                   style: {
                                                                       font: "400 21px/100% 'DM Sans'",
                                                                   },
                                                               });
                                                           },
                                                           () => {
                                                               toast.error("An error has occurred", {
                                                                   position: "bottom-right",
                                                                   style: {
                                                                       font: "400 21px/100% 'DM Sans'",
                                                                   },
                                                               });
                                                           },
                                                       );
                                                   }}>{getOldOrNewOwnerAddr(operation, true)}</a>
                                            ) : "—"
                                        }
                                    </div>
                                    <div className="adminPanel__content-table-main-row-title">
                                        {
                                            getOldOrNewOwnerAddr(operation, false, "new") ? (
                                                <a href="#"
                                                   data-tooltip-id="click-me"
                                                   data-tooltip-content="Click for copy"
                                                   onClick={() => {
                                                       navigator.clipboard.writeText(getOldOrNewOwnerAddr(operation, false, "new")).then(
                                                           () => {
                                                               toast.success("Copied to Clipboard", {
                                                                   position: "bottom-right",
                                                                   style: {
                                                                       font: "400 21px/100% 'DM Sans'",
                                                                   },
                                                               });
                                                           },
                                                           () => {
                                                               toast.error("An error has occurred", {
                                                                   position: "bottom-right",
                                                                   style: {
                                                                       font: "400 21px/100% 'DM Sans'",
                                                                   },
                                                               });
                                                           },
                                                       );
                                                   }}>{getOldOrNewOwnerAddr(operation, true, "new")}</a>
                                            ) : "—"
                                        }
                                    </div>
                                    <div className="adminPanel__content-table-main-row-status">
                                        {
                                            operation?.event_type !== "" && operation?.event_type ? (
                                                <div className="adminPanel__content-table-main-row-status-sale">
                                                    Successful
                                                </div>
                                            ) : (
                                                <div className="adminPanel__content-table-main-row-status-banned">
                                                    Error
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ))}
                    </div>
                </>
            )}
        </div>
        </>
    );
};
