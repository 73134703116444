import { useContext, useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ContextApp } from "../../Context";
import "./Collection.scss";
import Filters from "../../components/Filters";
import SimpleCard from "../../components/SimpleCard";
import { Line } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import useScript from "../../hooks/useScript";
import { Popup } from "../../components/Popup";
import { QRCodeSVG } from "qrcode.react";
import uuid from "react-uuid";
import $, { ready } from "jquery";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
} from "chart.js";
import { Tooltip as RTooltip } from "react-tooltip";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
);

// Options for chart.js
export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			enabled: true,
			titleAlign: "center",
			bodyAlign: "center",
			caretPadding: 5,
			usePointStyle: true,
			boxPadding: 4,
		},
	},
};

function Collection() {
	const params = useParams();
	const navigate = useNavigate();

	useScript(
		"https://comments.app/js/widget.js?3",
		`collections/${params.id}`,
		"collectionComments",
		localStorage.getItem("theme"),
	);

	const {
		currentUser,
		theme,
		changeTheme,
		filterStatus,
		filterQuantity,
		filterCategory,
		filterPriceAt,
		filterPriceTo,
		filterEmotional,
		globalExtraFilters,
		setGlobalExtraFilters,
		getAllOffsets,
	} = useContext(ContextApp);
	const [graphView, setGraphView] = useState("volume");
	const [sortingCurrent, setSortingCurrent] = useState("New");
	const [sortingPopup, setSortingPopup] = useState(false);
	const [priceCurrent, setPriceCurrent] = useState("Low to High");
	const [pricePopup, setPricePopup] = useState(false);
	const [graphPopup, setGraphPopup] = useState(false);
	const [filtersMobile, setFiltersMobile] = useState(false);

	const [fp, setFp] = useState("");
	const [floorPrice, setFloorPrice] = useState([]);

	const [showDeleteCollection, setShowDeleteCollection] = useState(false);

	const [searchQuery, setSearchQuery] = useState("");

	const [NFTs, setNFTs] = useState([]);

	const [collectionCreateDate, setCollectionCreateDate] = useState("");

	const [favorite, setFavorite] = useState(false);
	const [favoriteID, setFavoriteID] = useState(-1);

	const [currentSort, setCurrentSort] = useState("");

	const [deployPopup, setDeployPopup] = useState("");
	const [deployUrl, setDeployUrl] = useState("");

	const [volumePriceHistory, setVolumePriceHistory] = useState([]);
	const [floorPriceHistory, setFloorPriceHistory] = useState([]);

	const [likes, setLikes] = useState([0, 0, 0, 0, 0, 0, 0]);
	const [currentCollection, setCurrentCollection] = useState({});

	function OneOrMinusOne(a, b) {
		return a > b ? 1 : a < b ? -1 : 0;
	}

	function checkScore(nft) {
		let typeIDs = [];
		let score = 0;
		filterEmotional?.map((emot) =>
			emot === "Red Heart"
				? typeIDs.push("1")
				: emot === "Rolling on the Floor Laughing"
				? typeIDs.push("2")
				: emot === "Smiling Face with Heart-Eyes"
				? typeIDs.push("3")
				: emot === "Enraged Face"
				? typeIDs.push("4")
				: emot === "Weary Cat"
				? typeIDs.push("5")
				: emot === "Woozy Face"
				? typeIDs.push("6")
				: emot === "Money-Mouth Face"
				? typeIDs.push("7")
				: null,
		);
		if (nft.likes.length > 0 || nft?.anon_likes) {
			if (nft.likes.length > 0) {
				nft.likes.map((like) => {
					if (typeIDs.includes(like.type_id)) {
						score += 1;
					}
				});
			}
			if (nft?.anon_likes) {
				Object.keys(nft.anon_likes).map((like_id) => {
					if (typeIDs.includes(like_id)) {
						score += 1;
					}
				});
			}
		}
		return score;
	}

	useEffect(() => {
		axios
			.post(
				"https://nft-one.art/api/batch",
				{
					"current-collection": {
						action: "nft_collections/list",
						filters: {
							id: params.id,
						},
						subqueries: {
							img: {},
							likes: {},
							hdr: {},
							creator: {
								subqueries: {
									img: {},
								},
							},
						},
					},
				},
				{
					headers: {
						Token: localStorage.getItem("adminToken")
							? localStorage.getItem("adminToken")
							: localStorage.getItem("tonhubToken")
							? localStorage.getItem("tonhubToken")
							: localStorage.getItem("tonkeeperToken"),
					},
					auth: {
						username: "odmen",
						password: "NFTflsy",
					},
				},
			)
			.then((response) => {
				setCurrentCollection(
					response.data["current-collection"].items[0],
				);
				let likes_copy = [0, 0, 0, 0, 0, 0, 0];
				response.data["current-collection"].items[0].likes.map(
					(like) => (likes_copy[Number(like.type_id) - 1] += 1),
				);
				if (response.data["current-collection"].items[0]?.anon_likes) {
					Object.keys(
						response.data["current-collection"].items[0].anon_likes,
					).map((like_id) => {
						likes_copy[Number(like_id) - 1] += Number(
							response.data["current-collection"].items[0]
								.anon_likes[like_id],
						);
					});
				}
				setLikes([...likes_copy]);
				const createDate = new Date(
					response.data["current-collection"].items[0].add_time *
						1000,
				);
				const formatCreateDate = `${
					createDate.getDate() < 10
						? "0" + createDate.getDate().toString()
						: createDate.getDate()
				}.${
					createDate.getMonth() + 1 < 10
						? "0" + (createDate.getMonth() + 1).toString()
						: createDate.getMonth() + 1
				}.${createDate.getFullYear()}`;
				setCollectionCreateDate(formatCreateDate);

				getAllOffsets(
					"nfts",
					{
						filters: {
							collection_id: params.id,
							is_foreign:
								response.data["current-collection"].items[0]
									.is_foreign,
							is_hidden: false,
						},
						subqueries: {
							likes: {},
							img: {},
							creator: {
								subqueries: {
									img: {},
								},
							},
						},
					},
					"user",
				).then((res) => setNFTs(res));

				/*                axios
                    .post(
                        "https://nft-one.art/api/nfts/list",
                        {
                            filters: {
                                collection_id: params.id,
                                is_foreign: response.data["current-collection"].items[0].is_foreign,
                                is_hidden: false
                            },
                            subqueries: {
                                likes: {},
                                img: {},
                                creator: {
                                    subqueries: {
                                        img: {},
                                    },
                                },
                            },
                        },
                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                                    ? localStorage.getItem("adminToken")
                                    : localStorage.getItem("tonhubToken")
                                    ? localStorage.getItem("tonhubToken")
                                    : localStorage.getItem("tonkeeperToken"),
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setNFTs(response.data.items.reverse());
                    })
                    .catch(error => {
                        console.log(error);
                    });*/
			})
			.catch((error) => {
				console.log(error);
			});
		if (localStorage.getItem("auth")) {
			axios
				.post(
					"https://nft-one.art/api/auth/current",
					{},
					{
						headers: {
							Token: localStorage.getItem("tonkeeperToken")
								? localStorage.getItem("tonkeeperToken")
								: localStorage.getItem("tonhubToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					axios
						.post(
							"https://nft-one.art/api/nft_favorites/list",
							{
								filters: {
									collection_id: params.id,
									user_id: response.data.user.id,
								},
							},
							{
								auth: {
									username: "odmen",
									password: "NFTflsy",
								},
							},
						)
						.then((res) => {
							if (res.data.items.length > 0) {
								setFavorite(true);
								setFavoriteID(res.data.items[0].id);
							}
						})
						.catch((error) => {
							console.log(error);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		}
		if (
			window.location.href.split("#")[1] &&
			window.location.href.split("#")[2]
		) {
			setGlobalExtraFilters([
				...globalExtraFilters,
				{
					[window.location.href.split("#")[1]]:
						window.location.href.split("#")[2],
				},
			]);
		}
	}, []);

	useEffect(() => {
		if (NFTs.length !== 0) {
			let nftIDs = [];
			let nftIDsForFloorPrice = [];
			NFTs.map((nft) => {
				nftIDs.push(nft?.id);
				if (nft?.state === "sale") {
					nftIDsForFloorPrice.push(nft?.id);
				}
				if (
					nft?.state === "auction" &&
					Number(nft?.end_time - Date.now() / 1000) <= 0 &&
					Number(nft?.end_time) > 0
				) {
					nftIDsForFloorPrice.push(nft?.id);
				}
			});
			axios
				.post(
					"https://nft-one.art/api/batch",
					{
						"volume-price-history": {
							action: "nft_history/list",
							filters: {
								nft_id: [...nftIDs],
								event_type: ["buy", "end"],
							},
						},
						"floor-price-history": {
							action: "nft_history/list",
							filters: {
								nft_id: [...nftIDs],
								event_type: ["buy", "end"],
							},
							subqueries: {
								nft: {},
							},
						},
					},
					{
						headers: {
							Token: localStorage.getItem("adminToken")
								? localStorage.getItem("adminToken")
								: localStorage.getItem("tonhubToken")
								? localStorage.getItem("tonhubToken")
								: localStorage.getItem("tonkeeperToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					setVolumePriceHistory(
						response.data["volume-price-history"].items,
					);
					setFloorPriceHistory(
						response.data["floor-price-history"].items,
					);
				})
				.catch((error) => {
					console.log(error);
				});
			if (nftIDsForFloorPrice.length > 0) {
				axios
					.post(
						"https://nft-one.art/api/nft_history/list",
						{
							filters: {
								nft_id: [...nftIDsForFloorPrice],
								event_type: ["sale", "auction", "bid"],
							},
						},
						{
							headers: {
								Token: localStorage.getItem("adminToken")
									? localStorage.getItem("adminToken")
									: localStorage.getItem("tonhubToken")
									? localStorage.getItem("tonhubToken")
									: localStorage.getItem("tonkeeperToken"),
							},
							auth: {
								username: "odmen",
								password: "NFTflsy",
							},
						},
					)
					.then((response) => {
						setFloorPrice(response.data.items);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	}, [NFTs]);

	useEffect(() => {
		let prices = [];
		if (floorPrice.length > 0) {
			floorPrice.map((item) => {
				if (item?.price && Number(item?.price) > 0) {
					prices.push(Number(item?.price) / 10 ** 9);
				}
			});
			if (prices.length > 0) {
				prices = prices.sort((a, b) => a - b);
				setFp(`${prices[0]} TON`);
			} else {
				setFp("—");
			}
		} else {
			setFp("—");
		}
	}, [floorPrice]);

	useEffect(() => {
		if (
			params?.id &&
			currentCollection?.creator?.id === currentUser?.id &&
			currentCollection?.state?.toLowerCase() !== "active"
		) {
			axios
				.post(
					"https://nft-one.art/api/nft_collections/upsert",
					{
						items: [
							{
								id: params?.id,
								// state: "new"
							},
						],
					},
					{
						headers: {
							Token: localStorage.getItem("adminToken")
								? localStorage.getItem("adminToken")
								: localStorage.getItem("tonhubToken")
								? localStorage.getItem("tonhubToken")
								: localStorage.getItem("tonkeeperToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [currentCollection, params?.id]);

	const addOrDeleteFavorite = () => {
		if (favorite) {
			axios
				.post(
					"https://nft-one.art/api/nft_favorites/delete",
					{
						ids: [favoriteID],
					},
					{
						headers: {
							Token: localStorage.getItem("tonkeeperToken")
								? localStorage.getItem("tonkeeperToken")
								: localStorage.getItem("tonhubToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					setFavorite(false);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			axios
				.post(
					"https://nft-one.art/api/nft_favorites/upsert",
					{
						items: [
							{
								collection_id: params.id,
							},
						],
					},
					{
						headers: {
							Token: localStorage.getItem("tonkeeperToken")
								? localStorage.getItem("tonkeeperToken")
								: localStorage.getItem("tonhubToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					setFavorite(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const deployCollection = () => {
		setDeployPopup(true);
		if (localStorage.getItem("tonkeeperToken")) {
			axios
				.post(
					"https://nft-one.art/api/nft_collections/deploy_tonkeeper",
					{
						id: params?.id,
					},
					{
						headers: {
							Token: localStorage.getItem("tonkeeperToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					setDeployUrl(response.data.url);
					const checkDeploy = setInterval(() => {
						axios
							.post(
								"https://nft-one.art/api/nft_collections/upsert",
								{
									items: [
										{
											id: params?.id,
										},
									],
								},
								{
									headers: {
										Token: localStorage.getItem(
											"tonkeeperToken",
										),
									},
									auth: {
										username: "odmen",
										password: "NFTflsy",
									},
								},
							)
							.then(function (res) {
								if (res.data.items[0].state !== "deploying") {
									setDeployPopup(false);
									clearInterval(checkDeploy);
									window.location.reload();
								}
							})
							.catch(function (error) {
								console.log(error);
							});
					}, 2000);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			axios
				.post(
					"https://nft-one.art/api/nft_collections/deploy_tonhub",
					{
						id: params?.id,
					},
					{
						headers: {
							Token: localStorage.getItem("tonhubToken"),
						},
						auth: {
							username: "odmen",
							password: "NFTflsy",
						},
					},
				)
				.then((response) => {
					const checkDeploy = setInterval(() => {
						axios
							.post(
								"https://nft-one.art/api/nft_collections/upsert",
								{
									items: [
										{
											id: params?.id,
										},
									],
								},
								{
									headers: {
										Token: localStorage.getItem(
											"tonhubToken",
										),
									},
									auth: {
										username: "odmen",
										password: "NFTflsy",
									},
								},
							)
							.then(function (res) {
								if (res.data.items[0].state !== "deploying") {
									setDeployPopup(false);
									clearInterval(checkDeploy);
									window.location.reload();
								}
							})
							.catch(function (error) {
								console.log(error);
							});
					}, 2000);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const getCollectionCommission = () => {
		if (NFTs.length > 0) {
			let arr = [];
			NFTs.map((nft) => {
				if (nft.royalty_pct > 0) {
					arr.push(nft.royalty_pct);
				}
			});
			arr = arr.sort((a, b) => a - b);
			if (arr.length > 0) {
				if (arr.length === 1) {
					return `${arr[0]}%`;
				} else {
					return `${arr[0]}-${arr[arr.length - 1]}%`;
				}
			}
		}
		return "—";
	};

	const getCollectionTotalPrice = () => {
		let total = 0;
		floorPriceHistory.map((item) => (total += Number(item?.price)));
		if (total === 0) {
			return "—";
		} else {
			total /= 10 ** 9;
			return `${Number(total.toFixed(3))} TON`;
		}
	};

	useEffect(() => {
		if (window.innerWidth <= 768) {
			let filter = document.getElementsByClassName("filters")[0];
			if (filtersMobile) {
				filter.style.opacity = "1";
				filter.style.pointerEvents = "all";
			} else {
				filter.style.opacity = "0";
				filter.style.pointerEvents = "none";
			}
		}
	}, [filtersMobile]);

	const getVolumeLabels = () => {
		let labels = [];

		// Добавляем в начало дату создания этой коллекции
		let monthWhenCollectionCreated = new Date(
			currentCollection?.add_time * 1000,
		)
			.toDateString()
			.split(" ")[1];
		labels.push(monthWhenCollectionCreated);

		// Заполняем данными в какие месяца происходили продажи
		volumePriceHistory.map((operation) => {
			let price = Number(operation?.price) / 10 ** 9;
			let date = new Date(operation?.add_time * 1000)
				.toDateString()
				.split(" ")[1];
			if (price > 0 && !labels.includes(date)) {
				labels.push(date);
			}
		});

		// Добавляем текущий месяц
		let lastMonth = new Date().toDateString().split(" ")[1];
		if (!labels.includes(lastMonth)) {
			labels.push(lastMonth);
		}

		return labels;
	};

	const getVolumeData = () => {
		let data = [];
		getVolumeLabels().map((month) => {
			let volumePrice = 0;
			volumePriceHistory.map((operation) => {
				if (
					new Date(operation?.add_time * 1000)
						.toDateString()
						.split(" ")[1] === month
				) {
					volumePrice += Number(operation?.price / 10 ** 9);
				}
			});
			data.push(volumePrice);
		});
		return data;
	};

	const getFloorLabels = () => {
		let labels = [];

		// Добавляем в начало дату создания этой коллекции
		let monthWhenCollectionCreated = new Date(
			currentCollection?.add_time * 1000,
		)
			.toDateString()
			.split(" ")[1];
		labels.push(monthWhenCollectionCreated);

		// Заполняем данными в какие месяца происходили продажи
		floorPriceHistory.map((operation) => {
			let price = Number(operation?.price) / 10 ** 9;
			let date = new Date(operation?.add_time * 1000)
				.toDateString()
				.split(" ")[1];
			if (price > 0 && !labels.includes(date)) {
				labels.push(date);
			}
		});

		// Добавляем текущий месяц
		let lastMonth = new Date().toDateString().split(" ")[1];
		if (!labels.includes(lastMonth)) {
			labels.push(lastMonth);
		}

		return labels;
	};

	const getFloorData = () => {
		let data = [];
		getFloorLabels().map((month) => {
			let currentPrices = [];

			floorPriceHistory.map((operation) => {
				if (
					new Date(operation?.add_time * 1000)
						.toDateString()
						.split(" ")[1] === month
				) {
					currentPrices.push(Number(operation?.price / 10 ** 9));
				}
			});
			if (currentPrices.length <= 0) {
				data.push(0);
			} else {
				currentPrices = currentPrices.sort((a, b) => b - a);
				data.push(currentPrices[0]);
			}
			data.push();
		});
		return data;
	};

	const isShowVolumeGraph = () => {
		let flag = getVolumeData().every((item) => item === 0);
		return !flag;
	};

	const isShowFloorGraph = () => {
		let flag = getFloorData().every((item) => item === 0);
		return !flag;
	};

	// useEffect(() => {
	// 	const onPageLoad = () => {
	// 		if (window.innerWidth <= 768) {
	// 			$(".banner__content-info").css(
	// 				"bottom",
	// 				0 - $(".banner__content-info").height() - 20,
	// 			);
	// 			$(".banner__social").css(
	// 				"margin-top",
	// 				$(".banner__content-info").height() + 36,
	// 			);
	// 			$(".collection").css(
	// 				"padding-top",
	// 				$(".banner__content-card").height() -
	// 					$(".banner__content-card-img").height() +
	// 					38 +
	// 					($(".banner__content-info").height() + 30) +
	// 					($(".banner__social").height() + 30),
	// 			);
	// 		}
	// 	};

	// 	if (document.readyState === "complete") {
	// 		onPageLoad();
	// 	} else {
	// 		window.addEventListener("load", onPageLoad, false);
	// 		return () => window.removeEventListener("load", onPageLoad);
	// 	}
	// }, []);

	return (
		<>
			<Header />
			<Toaster />
			<RTooltip
				id="click-me"
				place="bottom"
				style={{
					font: "400 12px/120% 'DM Sans'",
					padding: "6px 10px",
					maxWidth: "200px",
					zIndex: 100000,
				}}
			/>
			<section
				className="banner"
				style={{
					background: currentCollection?.hdr?.hash
						? `${`url(https://nft-one.art/api/files/thumb/?hash=${currentCollection?.hdr?.hash}&width=2000&height=665) no-repeat center center/cover`}`
						: "",
				}}>
				<div className="banner__content">
					<div
						className="banner__content-card"
						style={{
							backgroundColor: changeTheme("#fff", "#1C2026"),
						}}>
						<div
							className="banner__content-card-img"
							style={{
								background: currentCollection?.img?.hash
									? `${`url(https://nft-one.art/api/files/thumb/?hash=${currentCollection?.img?.hash}) no-repeat center center/cover`}`
									: "",
							}}></div>
						{!likes.every((item) => item === 0) && (
							<ul className="banner__content-card-emoji">
								{["❤️", "🤣", "😍", "😡", "🙀", "🥴", "🤑"].map(
									(item, index) => (
										<li
											key={index}
											className="banner__content-card-emoji-item"
											style={{
												backgroundColor: changeTheme(
													"rgba(0, 77, 140, 0.1)",
													"rgba(255, 255, 255, 0.1)",
												),
												display: `${
													likes[index] === 0
														? "none"
														: ""
												}`,
											}}>
											{item}
											<span
												style={{
													color: changeTheme(
														"rgba(0, 0, 0, 0.7)",
														"rgba(255, 255, 255, 0.7)",
													),
												}}>
												{likes[index]}
											</span>
										</li>
									),
								)}
							</ul>
						)}
					</div>
					{window.innerWidth > 768 && <div
						className="banner__content-info"
						style={{ color: changeTheme("", "#fff") }}>
						<h1 className="banner__content-info-title">
							{currentCollection?.name}
							{currentCollection?.is_hidden && (
								<span style={{ color: "rgb(189 189 189)" }}>
									{" "}
									[INACTIVE]
								</span>
							)}
						</h1>
						{currentCollection?.categories?.length > 0 && (
							<ul className="banner__content-info-categories">
								{currentCollection?.categories.map(
									(category, index) => (
										<li
											key={index}
											className="banner__content-info-categories-category">
											{category}
										</li>
									),
								)}
							</ul>
						)}
						<p className="banner__content-info-text">
							{currentCollection?.info !== ""
								? currentCollection?.info
								: "Unfortunately, the author of this nft did not take care of its content. Do not despair, look at the works of other authors with a detailed description of the cards or buy this one and add the necessary information to it."}
							{currentCollection?.tonstorage_url && (
								<>
									<br />
									<span
										onClick={() => {
											navigator.clipboard
												.writeText(
													currentCollection?.tonstorage_url,
												)
												.then(
													() => {
														toast.success(
															"Copied to Clipboard",
															{
																position:
																	"bottom-right",
																style: {
																	font: "400 21px/100% 'DM Sans'",
																},
															},
														);
													},
													() => {
														toast.error(
															"An error has occurred",
															{
																position:
																	"bottom-right",
																style: {
																	font: "400 21px/100% 'DM Sans'",
																},
															},
														);
													},
												);
										}}
										className="nft__up-right-info-extra-text-link"
										style={{
											filter: changeTheme(
												"invert(100%) sepia(73%) saturate(607%) hue-rotate(149deg) brightness(115%) contrast(109%)",
												"invert(100%) sepia(73%) saturate(607%) hue-rotate(149deg) brightness(115%) contrast(109%)",
											),
										}}>
										TON Storage URL (click me for copy)
									</span>
								</>
							)}
						</p>
						{!currentCollection?.is_hidden &&
							currentCollection?.state === "new" &&
							currentCollection?.creator?.id ===
								currentUser?.id && (
								<div className="banner__content-info-btns">
									<button
										className="banner__content-info-btns-deploy"
										onClick={() => {
											deployCollection();
										}}>
										Deploy
									</button>
									<a
										className="banner__content-info-btns-edit"
										href={`/edit-collection/${params?.id}`}>
										Edit
									</a>
									{!currentCollection?.is_hidden &&
										currentCollection?.creator_id ===
											currentUser?.id &&
										currentCollection?.state === "new" && (
											<button
												onClick={() =>
													setShowDeleteCollection(
														true,
													)
												}
												className="nft__up-right-info-extra-delete"
												style={{
													marginLeft: "0.85vw",
												}}>
												Delete
											</button>
										)}
								</div>
							)}
					</div>}
				</div>
				{window.innerWidth > 768 && <div className="banner__social">
					{!currentCollection?.is_foreign &&
					currentCollection?.social_links
						? Object.keys(currentCollection?.social_links).map(
								(social, index) => {
									const currentLink =
										social === "vk"
											? "vk.com/"
											: social === "tg"
											? "t.me/"
											: social === "reddit"
											? "reddit.com/"
											: social === "twitter"
											? "twitter.com/"
											: social === "discord"
											? "discord.gg/"
											: social === "tik-tok"
											? "tiktok.com/"
											: social === "youtube"
											? "youtube.com/"
											: social === "facebook"
											? "facebook.com/"
											: social === "instagram"
											? "instagram.com/"
											: null;
									return (
										currentCollection.social_links[
											social
										] !== "" && (
											<a
												key={index}
												className="banner__social-link"
												href={
													"https://" +
													currentLink +
													currentCollection
														.social_links[social]
												}>
												<img
													className="banner__social-link-img"
													src={`/img/sections/collection/social-icons/${social}.svg`}
													alt=""
												/>
											</a>
										)
									);
								},
						  )
						: null}
				</div>}
			</section>
			<section
				style={{paddingTop: window.innerWidth <= 768 ? likes.every(val => val === 0) ? "60px" : "120px" : ""}}
				className={`collection ${changeTheme(
					"#f4f6fa",
					"collection--dark",
				)}`}>
					{window.innerWidth <= 768 && <div
						className="banner__content-info"
						style={{ color: changeTheme("", "#fff"), position: "relative" }}>
						<h1 className="banner__content-info-title">
							{currentCollection?.name}
							{currentCollection?.is_hidden && (
								<span style={{ color: "rgb(189 189 189)" }}>
									{" "}
									[INACTIVE]
								</span>
							)}
						</h1>
						{currentCollection?.categories?.length > 0 && (
							<ul className="banner__content-info-categories">
								{currentCollection?.categories.map(
									(category, index) => (
										<li
											key={index}
											className="banner__content-info-categories-category">
											{category}
										</li>
									),
								)}
							</ul>
						)}
						<p className="banner__content-info-text">
							{currentCollection?.info !== ""
								? currentCollection?.info
								: "Unfortunately, the author of this nft did not take care of its content. Do not despair, look at the works of other authors with a detailed description of the cards or buy this one and add the necessary information to it."}
							{currentCollection?.tonstorage_url && (
								<>
									<br />
									<span
										onClick={() => {
											navigator.clipboard
												.writeText(
													currentCollection?.tonstorage_url,
												)
												.then(
													() => {
														toast.success(
															"Copied to Clipboard",
															{
																position:
																	"bottom-right",
																style: {
																	font: "400 21px/100% 'DM Sans'",
																},
															},
														);
													},
													() => {
														toast.error(
															"An error has occurred",
															{
																position:
																	"bottom-right",
																style: {
																	font: "400 21px/100% 'DM Sans'",
																},
															},
														);
													},
												);
										}}
										className="nft__up-right-info-extra-text-link"
										style={{
											filter: changeTheme(
												"invert(100%) sepia(73%) saturate(607%) hue-rotate(149deg) brightness(115%) contrast(109%)",
												"invert(100%) sepia(73%) saturate(607%) hue-rotate(149deg) brightness(115%) contrast(109%)",
											),
										}}>
										TON Storage URL (click me for copy)
									</span>
								</>
							)}
						</p>
						{!currentCollection?.is_hidden &&
							currentCollection?.state === "new" &&
							currentCollection?.creator?.id ===
								currentUser?.id && (
								<div className="banner__content-info-btns">
									<button
										className="banner__content-info-btns-deploy"
										onClick={() => {
											deployCollection();
										}}>
										Deploy
									</button>
									<a
										className="banner__content-info-btns-edit"
										href={`/edit-collection/${params?.id}`}>
										Edit
									</a>
									{!currentCollection?.is_hidden &&
										currentCollection?.creator_id ===
											currentUser?.id &&
										currentCollection?.state === "new" && (
											<button
												onClick={() =>
													setShowDeleteCollection(
														true,
													)
												}
												className="nft__up-right-info-extra-delete"
												style={{
													marginLeft: "0.85vw",
												}}>
												Delete
											</button>
										)}
								</div>
							)}
					</div>}
					{window.innerWidth <= 768 && <div style={{marginBottom: "20px"}} className="banner__social">
					{!currentCollection?.is_foreign &&
					currentCollection?.social_links
						? Object.keys(currentCollection?.social_links).map(
								(social, index) => {
									const currentLink =
										social === "vk"
											? "vk.com/"
											: social === "tg"
											? "t.me/"
											: social === "reddit"
											? "reddit.com/"
											: social === "twitter"
											? "twitter.com/"
											: social === "discord"
											? "discord.gg/"
											: social === "tik-tok"
											? "tiktok.com/"
											: social === "youtube"
											? "youtube.com/"
											: social === "facebook"
											? "facebook.com/"
											: social === "instagram"
											? "instagram.com/"
											: null;
									return (
										currentCollection.social_links[
											social
										] !== "" && (
											<a
												key={index}
												className="banner__social-link"
												href={
													"https://" +
													currentLink +
													currentCollection
														.social_links[social]
												}>
												<img
													className="banner__social-link-img"
													src={`/img/sections/collection/social-icons/${social}.svg`}
													alt=""
												/>
											</a>
										)
									);
								},
						  )
						: null}
				</div>}
				{window.innerWidth > 768 && (
					<Filters
						extraFiltersForURL={{
							[window.location.href.split("#")[1]]:
								window.location.href.split("#")[2],
						}}
						searchBottom
						handleArray={NFTs}
						example="collection-page"
					/>
				)}
				<div
					className="collection__filtersMobile"
					onClick={(e) => {
						if (
							[
								"collection__filtersMobile",
								"collection__filtersMobile-img",
							].includes(e.target.className)
						) {
							setFiltersMobile(!filtersMobile);
						}
					}}
					style={{
						backgroundColor: changeTheme("", "#23292F"),
						color: changeTheme("", "#fff"),
						cursor: "pointer",
					}}>
					Filters
					<img
						className="collection__filtersMobile-img"
						src={`/img/sections/collection/filters-${theme}.svg`}
						alt=""
					/>
					<Filters
						extraFiltersForURL={{
							[window.location.href.split("#")[1]]:
								window.location.href.split("#")[2],
						}}
						searchBottom
						handleArray={NFTs}
						example="collection-page"
					/>
				</div>
				<div className="collection__main">
					<div className="collection__main-info">
						<div className="collection__main-info-box">
							<h6 className="collection__main-info-box-title">
								Create date
							</h6>
							<p className="collection__main-info-box-text">
								{collectionCreateDate}
							</p>
						</div>
						<div className="collection__main-info-box">
							<h6 className="collection__main-info-box-title">
								Commission
							</h6>
							<p className="collection__main-info-box-text">
								{getCollectionCommission()}
							</p>
						</div>
						<div className="collection__main-info-box">
							<h6 className="collection__main-info-box-title">
								Floor price
							</h6>
							<p className="collection__main-info-box-text">
								{fp}
							</p>
						</div>
						<div className="collection__main-info-box">
							<h6 className="collection__main-info-box-title">
								Total volume
							</h6>
							<p className="collection__main-info-box-text">
								{getCollectionTotalPrice()}
							</p>
						</div>
						<div className="collection__main-info-buttons">
							<img
								className="collection__main-info-buttons-open"
								src={`/img/sections/nft/copy-${theme}.svg`}
								alt=""
								onClick={() => {
									navigator.clipboard
										.writeText(
											`Check my collection: ${window.location.href}`,
										)
										.then(
											() => {
												toast.success(
													"Copied to Clipboard",
													{
														position:
															"bottom-right",
														style: {
															font: "400 21px/100% 'DM Sans'",
														},
													},
												);
											},
											() => {
												toast.error(
													"An error has occurred",
													{
														position:
															"bottom-right",
														style: {
															font: "400 21px/100% 'DM Sans'",
														},
													},
												);
											},
										);
								}}
							/>
							<a
								target="_blank"
								href={`https://t.me/share/url?url=https://nft-one.art/collection/${params.id}&text=Check out this cool collection!`}
								rel="noreferrer">
								<img
									className="collection__main-info-buttons-repost"
									src={`/img/sections/collection/repost-${theme}.svg`}
									alt=""
								/>
							</a>
							{localStorage.getItem("auth") && (
								<img
									className="collection__main-info-buttons-flag"
									src={`/img/sections/collection/flag-${theme}.svg`}
									alt=""
									onClick={addOrDeleteFavorite}
									style={{
										filter: favorite
											? "invert(15%) sepia(67%) saturate(6235%) hue-rotate(357deg) brightness(103%) contrast(119%)"
											: "",
									}}
								/>
							)}
						</div>
					</div>
					<div className="collection__main-people">
						<a
							className="collection__main-people-owner"
							href={`/profile/${currentCollection?.creator?.id}`}>
							<h6 className="collection__main-people-owner-title">
								Owner
							</h6>
							<div className="collection__main-people-owner-left">
								<div
									className="collection__main-people-owner-left-avatar"
									style={{
										background: currentCollection?.creator
											?.img?.hash
											? `url(https://nft-one.art/api/files/thumb/?hash=${currentCollection?.creator?.img?.hash}&width=70&height=70) no-repeat center center / cover`
											: "url(/img/sections/myNFT/avatar.svg) no-repeat center center / cover",
									}}></div>
								<p className="collection__main-people-owner-left-name">
									{currentCollection?.is_foreign
										? currentCollection?.creator_addr
												?.length > 14
											? currentCollection?.creator_addr?.slice(
													0,
													12,
											  ) + "..."
											: currentCollection?.creator_addr
										: currentCollection?.creator?.name
												?.length > 14
										? currentCollection?.creator?.name?.slice(
												0,
												12,
										  ) + "..."
										: currentCollection?.creator?.name}
								</p>
							</div>
							<img
								className="collection__main-people-owner-arrow"
								src={`/img/sections/collection/arrow-right-${theme}.svg`}
								alt=""
							/>
						</a>
						<a
							className="collection__main-people-creator"
							href={`/profile/${currentCollection?.creator?.id}`}>
							<h6 className="collection__main-people-creator-title">
								Creator
							</h6>
							<div className="collection__main-people-creator-left">
								<div
									className="collection__main-people-owner-left-avatar"
									style={{
										background: currentCollection?.creator
											?.img?.hash
											? `url(https://nft-one.art/api/files/thumb/?hash=${currentCollection?.creator?.img?.hash}&width=70&height=70) no-repeat center center / cover`
											: "url(/img/sections/myNFT/avatar.svg) no-repeat center center / cover",
									}}></div>
								<p className="collection__main-people-creator-left-name">
									{currentCollection?.is_foreign
										? currentCollection?.creator_addr
												?.length > 14
											? currentCollection?.creator_addr?.slice(
													0,
													12,
											  ) + "..."
											: currentCollection?.creator_addr
										: currentCollection?.creator?.name
												?.length > 14
										? currentCollection?.creator?.name?.slice(
												0,
												12,
										  ) + "..."
										: currentCollection?.creator?.name}
								</p>
							</div>
							<img
								className="collection__main-people-creator-arrow"
								src={`/img/sections/collection/arrow-right-${theme}.svg`}
								alt=""
							/>
						</a>
					</div>
					<div className="collection__main-other">
						<h6 className="collection__main-other-title">Other</h6>
						<div className="collection__main-other-box">
							<p className="collection__main-other-box-title">
								Contract Address
							</p>
							<p className="collection__main-other-box-text">
								{window.innerWidth <= 768
									? currentCollection?.ton_addr?.slice(
											0,
											15,
									  ) + "..."
									: currentCollection?.ton_addr}
							</p>
							<div className="nft__up-right-other-box-imgs">
								<img
									className="nft__up-right-other-box-imgs-img nft__up-right-other-box-imgs-img-1"
									src={`/img/sections/nft/copy-${theme}.svg`}
									alt=""
									onClick={() => {
										navigator.clipboard
											.writeText(
												currentCollection?.ton_addr,
											)
											.then(
												() => {
													toast.success(
														"Copied to Clipboard",
														{
															position:
																"bottom-right",
															style: {
																font: "400 21px/100% 'DM Sans'",
															},
														},
													);
												},
												() => {
													toast.error(
														"An error has occurred",
														{
															position:
																"bottom-right",
															style: {
																font: "400 21px/100% 'DM Sans'",
															},
														},
													);
												},
											);
									}}
								/>
								<a
									href={`https://tonscan.org/address/${currentCollection?.ton_addr}`}
									target="_blank"
									rel="noreferrer">
									<img
										className="nft__up-right-other-box-imgs-img nft__up-right-other-box-imgs-img-2"
										src={`/img/sections/nft/go-${theme}.svg`}
										alt=""
									/>
								</a>
							</div>
						</div>
					</div>
					<div className="collection__main-graph">
						{isShowVolumeGraph() && isShowFloorGraph() && (
							<>
								<div className="collection__main-graph-up">
									<h6 className="collection__main-graph-up-title">
										Graph
									</h6>
									<div className="collection__main-graph-up-settings">
										<button
											className={`collection__main-graph-up-settings-volume ${
												graphView === "volume"
													? "collection__main-graph-up-settings-volume--active"
													: ""
											}`}
											onClick={() =>
												setGraphView("volume")
											}>
											Volume
										</button>
										<button
											className={`collection__main-graph-up-settings-floor ${
												graphView === "floor"
													? "collection__main-graph-up-settings-floor--active"
													: ""
											}`}
											onClick={() =>
												setGraphView("floor")
											}>
											Floor price
										</button>
										<img
											className="collection__main-graph-up-settings-resize"
											src={`/img/sections/nft/resize-${theme}.svg`}
											alt=""
											onClick={() =>
												setGraphPopup(!graphPopup)
											}
										/>
									</div>
								</div>
								<div className="collection__main-graph-down">
									{graphView === "volume" ? (
										<Line
											options={options}
											data={{
												labels: [...getVolumeLabels()],
												datasets: [
													{
														data: [
															...getVolumeData(),
														],
														borderColor: "#0088cc",
														backgroundColor:
															"#0088cc",
														tension: 0.5,
														pointHitRadius: 12,
													},
												],
											}}
										/>
									) : (
										<Line
											options={options}
											data={{
												labels: [...getFloorLabels()],
												datasets: [
													{
														data: [
															...getFloorData(),
														],
														borderColor: "#0088cc",
														backgroundColor:
															"#0088cc",
														tension: 0.5,
														pointHitRadius: 12,
													},
												],
											}}
										/>
									)}
								</div>
							</>
						)}
						{isShowVolumeGraph() && !isShowFloorGraph() && (
							<>
								<div className="collection__main-graph-up">
									<h6 className="collection__main-graph-up-title">
										Graph
									</h6>
									<div className="collection__main-graph-up-settings">
										<button
											className={`collection__main-graph-up-settings-volume ${
												graphView === "volume"
													? "collection__main-graph-up-settings-volume--active"
													: ""
											}`}
											onClick={() =>
												setGraphView("volume")
											}>
											Volume
										</button>
										<img
											className="collection__main-graph-up-settings-resize"
											src={`/img/sections/nft/resize-${theme}.svg`}
											alt=""
											onClick={() =>
												setGraphPopup(!graphPopup)
											}
										/>
									</div>
								</div>
								<div className="collection__main-graph-down">
									{graphView === "volume" ? (
										<Line
											options={options}
											data={{
												labels: [...getVolumeLabels()],
												datasets: [
													{
														data: [
															...getVolumeData(),
														],
														borderColor: "#0088cc",
														backgroundColor:
															"#0088cc",
														tension: 0.5,
														pointHitRadius: 12,
													},
												],
											}}
										/>
									) : (
										<Line
											options={options}
											data={{
												labels: [...getFloorLabels()],
												datasets: [
													{
														data: [
															...getFloorData(),
														],
														borderColor: "#0088cc",
														backgroundColor:
															"#0088cc",
														tension: 0.5,
														pointHitRadius: 12,
													},
												],
											}}
										/>
									)}
								</div>
							</>
						)}
						{!isShowVolumeGraph() && isShowFloorGraph() && (
							<>
								<div className="collection__main-graph-up">
									<h6 className="collection__main-graph-up-title">
										Graph
									</h6>
									<div className="collection__main-graph-up-settings">
										<button
											className={`collection__main-graph-up-settings-floor ${
												graphView === "volume"
													? "collection__main-graph-up-settings-floor--active"
													: ""
											}`}
											onClick={() =>
												setGraphView("volume")
											}>
											Floor price
										</button>
										<img
											className="collection__main-graph-up-settings-resize"
											src={`/img/sections/nft/resize-${theme}.svg`}
											alt=""
											onClick={() =>
												setGraphPopup(!graphPopup)
											}
										/>
									</div>
								</div>
								<div className="collection__main-graph-down">
									{graphView === "volume" ? (
										<Line
											options={options}
											data={{
												labels: [...getVolumeLabels()],
												datasets: [
													{
														data: [
															...getFloorData(),
														],
														borderColor: "#0088cc",
														backgroundColor:
															"#0088cc",
														tension: 0.5,
														pointHitRadius: 12,
													},
												],
											}}
										/>
									) : (
										<Line
											options={options}
											data={{
												labels: [...getFloorLabels()],
												datasets: [
													{
														data: [
															...getVolumeData(),
														],
														borderColor: "#0088cc",
														backgroundColor:
															"#0088cc",
														tension: 0.5,
														pointHitRadius: 12,
													},
												],
											}}
										/>
									)}
								</div>
							</>
						)}
						{!isShowVolumeGraph() && !isShowFloorGraph() && (
							<>
								<div className="collection__main-graph-up">
									<h6 className="collection__main-graph-up-title">
										Graph
									</h6>
								</div>
								<div className="collection__main-graph-zero">
									INSUFFICIENT DATA
								</div>
							</>
						)}
					</div>
					<div className="collection__main-cards">
						<div className="collection__main-cards-options">
							<div className="collection__main-cards-options-search">
								<input
									placeholder="Search"
									value={searchQuery}
									onChange={(e) =>
										setSearchQuery(e.target.value)
									}
									type="text"
									className="collection__main-cards-options-search-input"
								/>
								<img
									className="collection__main-cards-options-search-img"
									src={`/img/sections/collection/search-${theme}.svg`}
									alt=""
								/>
							</div>
							{window.innerWidth > 768 ? (
								<div className="collection__main-cards-options-filters">
									<div
										className="collection__main-cards-options-filters-sort"
										onClick={() =>
											setSortingPopup(!sortingPopup)
										}>
										{`Creation: ${sortingCurrent}`}
										{sortingPopup && (
											<ul className="collection__main-cards-options-filters-sort-popup">
												<li
													className="collection__main-cards-options-filters-sort-popup-item"
													onClick={(e) => {
														setSortingCurrent(
															e.target.innerText,
														);
														setCurrentSort("Old");
													}}>
													Old
												</li>
												<li
													className="collection__main-cards-options-filters-sort-popup-item"
													onClick={(e) => {
														setSortingCurrent(
															e.target.innerText,
														);
														setCurrentSort("New");
													}}>
													New
												</li>
											</ul>
										)}
										<img
											src={`/img/sections/collection/arrow-up-${theme}.svg`}
											alt=""
											style={{
												transform: sortingPopup
													? "rotate(-180deg)"
													: "rotate(0deg)",
											}}
										/>
									</div>
									<div
										className="collection__main-cards-options-filters-price"
										onClick={() =>
											setPricePopup(!pricePopup)
										}>
										{window.innerWidth <= 768
											? "Price"
											: `Price: ${priceCurrent}`}
										{pricePopup && (
											<ul className="collection__main-cards-options-filters-price-popup">
												<li
													className="collection__main-cards-options-filters-price-popup-item"
													onClick={(e) => {
														setPriceCurrent(
															e.target.innerText,
														);
														setCurrentSort(
															"Low to High",
														);
													}}>
													Low to High
												</li>
												<li
													className="collection__main-cards-options-filters-price-popup-item"
													onClick={(e) => {
														setPriceCurrent(
															e.target.innerText,
														);
														setCurrentSort(
															"High to Low",
														);
													}}>
													Hign to Low
												</li>
											</ul>
										)}
										<img
											src={`/img/sections/collection/arrow-up-${theme}.svg`}
											alt=""
											style={{
												transform: pricePopup
													? "rotate(-180deg)"
													: "rotate(0deg)",
											}}
										/>
									</div>
								</div>
							) : (
								<button
									className="myContent__right-settings-boxMobile-sort"
									onClick={() =>
										setSortingPopup(!sortingPopup)
									}>
									Sort
									<img
										src={`/img/sections/collection/arrow-up-${theme}.svg`}
										alt=""
									/>
									{sortingPopup && (
										<ul
											className="collection__main-cards-options-filters-sort-popup"
											style={{
												backgroundColor: changeTheme(
													"",
													"#2B3239",
												),
												marginTop: "3px",
											}}>
											<li
												className="collection__main-cards-options-filters-sort-popup-item"
												onClick={() =>
													setCurrentSort(
														"Low to High",
													)
												}>
												Low price
											</li>
											<li
												className="collection__main-cards-options-filters-sort-popup-item"
												onClick={() =>
													setCurrentSort(
														"High to Low",
													)
												}>
												High price
											</li>
											<li
												className="collection__main-cards-options-filters-sort-popup-item"
												onClick={() =>
													setCurrentSort("Old")
												}>
												Old
											</li>
											<li
												className="collection__main-cards-options-filters-sort-popup-item"
												onClick={() =>
													setCurrentSort("New")
												}>
												New
											</li>
										</ul>
									)}
								</button>
							)}
						</div>
						<div className="collection__main-cards-items">
							{NFTs.filter((nft) =>
								filterStatus === "all"
									? nft
									: filterStatus === "Buy now"
									? nft.state === "sale"
									: filterStatus === "In auction"
									? nft.state === "auction" &&
									  !(
											Number(
												nft?.end_time -
													Date.now() / 1000,
											) <= 0 && Number(nft?.end_time) > 0
									  )
									: nft.state === "sale" ||
									  (nft.state === "auction" &&
											!(
												Number(
													nft?.end_time -
														Date.now() / 1000,
												) <= 0 &&
												Number(nft?.end_time) > 0
											)),
							)

								.filter((nft) =>
									filterQuantity === "all"
										? nft
										: filterQuantity === "single"
										? nft.collection_id == null
										: nft.collection_id != null,
								)
								.filter((nft) =>
									searchQuery !== ""
										? nft.name
												.toLowerCase()
												.includes(
													searchQuery.toLowerCase(),
												)
										: nft,
								)
								.filter((nft) => {
									if (filterCategory.length === 0) {
										return nft;
									} else {
										let flag = false;
										if (nft?.categories) {
											nft.categories.map((cat) =>
												filterCategory.includes(
													cat.toLowerCase(),
												)
													? (flag = true)
													: null,
											);
										}
										return flag;
									}
								})
								.filter((nft) =>
									filterPriceAt !== ""
										? Number(nft.price) >= filterPriceAt
										: nft,
								)
								.filter((nft) =>
									filterPriceTo !== ""
										? Number(nft.price) <= filterPriceTo
										: nft,
								)
								.filter((nft) => {
									if (globalExtraFilters?.length > 0) {
										if (nft?.attrs) {
											if (1) {
												let flag = 0;
												globalExtraFilters.map(
													(item) => {
														if (
															nft?.attrs?.hasOwnProperty(
																Object.keys(
																	item,
																)[0],
															)
														) {
															if (
																item[
																	Object.keys(
																		item,
																	)[0]
																].toLowerCase() ===
																nft?.attrs[
																	Object.keys(
																		item,
																	)[0]
																].toLowerCase()
															) {
																flag = 1;
															}
														}
													},
												);
												return flag;
											} else {
												return 0;
											}
										} else {
											return 0;
										}
									} else {
										return 1;
									}
								})
								.sort((a, b) => {
									if (currentSort === "Low to High") {
										return (
											Number(a.price) - Number(b.price)
										);
									} else if (currentSort === "High to Low") {
										return (
											Number(b.price) - Number(a.price)
										);
									} else if (currentSort === "Old") {
										return (
											Number(a.add_time) -
											Number(b.add_time)
										);
									} else if (currentSort === "New") {
										return (
											Number(b.add_time) -
											Number(a.add_time)
										);
									}
								})
								.sort((a, b) => {
									return OneOrMinusOne(
										checkScore(b),
										checkScore(a),
									);
								}).length > 0 ? (
								NFTs.filter((nft) =>
									filterStatus === "all"
										? nft
										: filterStatus === "Buy now"
										? nft.state === "sale"
										: filterStatus === "In auction"
										? nft.state === "auction" &&
										  !(
												Number(
													nft?.end_time -
														Date.now() / 1000,
												) <= 0 &&
												Number(nft?.end_time) > 0
										  )
										: nft.state === "sale" ||
										  (nft.state === "auction" &&
												!(
													Number(
														nft?.end_time -
															Date.now() / 1000,
													) <= 0 &&
													Number(nft?.end_time) > 0
												)),
								)

									.filter((nft) =>
										filterQuantity === "all"
											? nft
											: filterQuantity === "single"
											? nft.collection_id == null
											: nft.collection_id != null,
									)
									.filter((nft) =>
										searchQuery !== ""
											? nft.name
													.toLowerCase()
													.includes(
														searchQuery.toLowerCase(),
													)
											: nft,
									)
									.filter((nft) => {
										if (filterCategory.length === 0) {
											return nft;
										} else {
											let flag = false;
											if (nft?.categories) {
												nft.categories.map((cat) =>
													filterCategory.includes(
														cat.toLowerCase(),
													)
														? (flag = true)
														: null,
												);
											}
											return flag;
										}
									})
									.filter((nft) =>
										filterPriceAt !== ""
											? Number(nft.price) >= filterPriceAt
											: nft,
									)
									.filter((nft) =>
										filterPriceTo !== ""
											? Number(nft.price) <= filterPriceTo
											: nft,
									)
									.filter((nft) => {
										if (globalExtraFilters?.length > 0) {
											if (nft?.attrs) {
												if (1) {
													let flag = 0;
													globalExtraFilters.map(
														(item) => {
															if (
																nft?.attrs?.hasOwnProperty(
																	Object.keys(
																		item,
																	)[0],
																)
															) {
																if (
																	item[
																		Object.keys(
																			item,
																		)[0]
																	].toLowerCase() ===
																	nft?.attrs[
																		Object.keys(
																			item,
																		)[0]
																	].toLowerCase()
																) {
																	flag = 1;
																}
															}
														},
													);
													return flag;
												} else {
													return 0;
												}
											} else {
												return 0;
											}
										} else {
											return 1;
										}
									})
									.sort((a, b) => {
										if (currentSort === "Low to High") {
											return (
												Number(a.price) -
												Number(b.price)
											);
										} else if (
											currentSort === "High to Low"
										) {
											return (
												Number(b.price) -
												Number(a.price)
											);
										} else if (currentSort === "Old") {
											return (
												Number(a.add_time) -
												Number(b.add_time)
											);
										} else if (currentSort === "New") {
											return (
												Number(b.add_time) -
												Number(a.add_time)
											);
										}
									})
									.sort((a, b) => {
										return OneOrMinusOne(
											checkScore(b),
											checkScore(a),
										);
									})
									.map((nft, index) => (
										<SimpleCard
											isOptimizedSizes={false}
											nft={nft}
											currentItem={index + 1}
											maxItems={NFTs?.length}
											key={uuid()}
										/>
									))
							) : (
								<div className="myContent__right-items-zero">
									NO ITEMS
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			<section
				className="collectionComments"
				style={{
					backgroundColor: changeTheme("", "#15191e"),
				}}></section>
			{graphPopup && (
				<div
					className="graph-popup"
					onClick={(e) =>
						e.target.getAttribute("class") === "graph-popup" &&
						setGraphPopup(!graphPopup)
					}>
					<div className="graph-popup-two">
						{graphView === "volume" ? (
							<Line
								options={options}
								data={{
									labels: [...getVolumeLabels()],
									datasets: [
										{
											data: [...getVolumeData()],
											borderColor: "#0088cc",
											backgroundColor: "#0088cc",
											tension: 0.5,
											pointHitRadius: 12,
										},
									],
								}}
							/>
						) : (
							<Line
								options={options}
								data={{
									labels: [...getFloorLabels()],
									datasets: [
										{
											data: [...getFloorData()],
											borderColor: "#0088cc",
											backgroundColor: "#0088cc",
											tension: 0.5,
											pointHitRadius: 12,
										},
									],
								}}
							/>
						)}
					</div>
				</div>
			)}
			<Popup
				show={deployPopup}
				onShow={(arg) => {
					setDeployPopup(arg);
					axios
						.post(
							"https://nft-one.art/api/nft_collections/upsert",
							{
								items: [
									{
										id: params?.id,
										state: "new",
									},
								],
							},
							{
								headers: {
									Token: localStorage.getItem(
										"tonkeeperToken",
									)
										? localStorage.getItem("tonkeeperToken")
										: localStorage.getItem("tonhubToken"),
								},
								auth: {
									username: "odmen",
									password: "NFTflsy",
								},
							},
						)
						.then((response) => {})
						.catch((error) => {
							console.log(error);
						});
				}}>
				<>
					{localStorage.getItem("tonkeeperToken") ? (
						<>
							<h6
								className="connect__popup-title-qr"
								style={{
									color: theme === "light" ? "#000" : "#fff",
									maxWidth: "180px",
									margin: "0 auto 20px auto",
								}}>
								Click or scan with Tonkeeper
							</h6>
							<a href={deployUrl}>
								<QRCodeSVG
									fgColor={changeTheme("#000", "#fff")}
									bgColor={changeTheme("#fff", "#0088CC")}
									value={deployUrl}
									size={200}
								/>
							</a>
						</>
					) : (
						<div
							style={{
								maxWidth: "365px",
								textAlign: "center",
							}}>
							<h6
								style={{
									fontWeight: "600",
									lineHeight: "120%",
								}}>
								Confirm the transaction in the Tonhub app (the
								page will update automatically)
							</h6>
						</div>
					)}
				</>
			</Popup>
			{showDeleteCollection && (
				<div className="acceptDeleteUser">
					<div className="acceptDeleteUser__content">
						<img
							className="acceptDeleteUser__content-close"
							src="/img/header/close.png"
							alt=""
							onClick={() => {
								setShowDeleteCollection(false);
							}}
						/>
						<h1 className="acceptDeleteUser__content-title">
							Attention!
						</h1>
						<p className="acceptDeleteUser__content-text">
							Are you sure you want to delete this collection?{" "}
							<br />
							<br />
							This action cannot be undone!
						</p>
						<button
							className="acceptDeleteUser__content-button"
							onClick={() =>
								navigate(`/#delete-collection#${params?.id}`)
							}>
							Yes, I know what I'm doing
						</button>
					</div>
				</div>
			)}
			<Footer />
		</>
	);
}

export default Collection;
