import { useEffect, useState, useContext } from "react";
import { ContextApp } from "../../Context";
import "./Footer.scss";
import { memo } from "react";

function Footer() {
    const { changeTheme, cfg } = useContext(ContextApp);

    const [config, setConfig] = useState({});
    const [configFooterSocialLinks, setConfigFooterSocialLinks] = useState("");

    useEffect(() => {
        if (cfg) {
            setConfig(cfg);
            setConfigFooterSocialLinks(cfg?.values?.footer_social_links?.split(","));
        }
    }, [cfg]);
    
    return (
        <footer className="footer" style={{ backgroundColor: changeTheme("#004f87", "#1C2026") }}>
            <div className="footer__info">
                <div className="footer__info-about">
                    <a href="/
                    "><img src="/img/footer/footer-logo.svg" alt="Logo" className="footer__info-about-logo" /></a>
                    <p className="footer__info-about-text">{config?.values?.footer_about}</p>
                </div>
                <div
                    className="footer__info-box"
                    style={{ width: localStorage.getItem("auth") === "false" ? "unset" : "" }}>
                    <ul className="footer__info-box-list">
                        <li className="footer__info-box-list-title">Marketplace</li>
                        <li className="footer__info-box-list-item">
                            <a href="/catalog">Catalog</a>
                        </li>
                        <li className="footer__info-box-list-item">
                            <a href="/faq">FAQ</a>
                        </li>
                    </ul>
                    {localStorage.getItem("auth") === "true" && (
                        <ul className="footer__info-box-list">
                            <li className="footer__info-box-list-title">My Account</li>
                            <li className="footer__info-box-list-item">
                                <a href="/edit-profile">Profile</a>
                            </li>
                            <li className="footer__info-box-list-item">
                                <a href="/edit-profile#my-collections">My Collections</a>
                            </li>
                            <li className="footer__info-box-list-item">
                                <a href="/edit-profile#my-nfts">My NFTs</a>
                            </li>
                        </ul>
                    )}
                </div>
                <div className="footer__info-social">
                    <h6 className="footer__info-social-title">Follow Us On</h6>
                    <div className="footer__info-social-items">
                        {configFooterSocialLinks !== "" &&
                            ["vk", "telegram", "twitter", "reddit", "discord"].map((social, index) => {
                                if (configFooterSocialLinks[index] !== "0") {
                                    let hrefA =
                                        "https://" +
                                        (index === 0
                                            ? "vk.com/"
                                            : index === 1
                                            ? "t.me/"
                                            : index === 2
                                            ? "twitter.com/"
                                            : index === 3
                                            ? "reddit.com/"
                                            : index === 4
                                            ? "discord.gg/"
                                            : null) +
                                        configFooterSocialLinks[index];
                                    return (
                                        <a href={hrefA} target="_blank" rel="noreferrer" key={index}>
                                            <img
                                                src={`/img/footer/${social}.svg`}
                                                alt={social}
                                                className="footer__info-social-items-item"
                                            />
                                        </a>
                                    );
                                }
                            })}
                    </div>
                </div>
            </div>
            <div className="footer__copyright">Copyright © 2023 NFTONE</div>
        </footer>
    );
}

export default memo(Footer);
