import axios from "axios";
import { useState, useEffect, useContext } from "react";
import Draggable from "react-draggable";
import { toast, Toaster } from "react-hot-toast";
import { ContextApp } from "../../../../Context";

function AdminPanelCollections() {

    const { getAllOffsets } = useContext(ContextApp)

    const [searchQuery, setSearchQuery] = useState("");

    const [showItems, setShowItems] = useState(true);

    const [selectedAll, setSelectedAll] = useState(false);
    const [selects, setSelects] = useState([]);

    const [collections, setCollections] = useState([]);
    const [collectionsFiltered, setCollectionsFiltered] = useState([]);

    const [currentSort, setCurrentSort] = useState("");

    const [showAcceptDeleteUser, setShowAcceptDeleteUser] = useState(false);
    const [acceptDeleteUserUsername, setAcceptDeleteUserUsername] = useState("");
    const [acceptDeleteUserID, setAcceptDeleteUserID] = useState("");

    const [showAcceptDeleteUsers, setShowAcceptDeleteUsers] = useState(false);

    useEffect(() => {
        getAllOffsets("nft_collections", {
            filters: {
                is_foreign: false,
            },
            subqueries: {
                img: {},
                collection: {
                    subqueries: {
                        img: {},
                    },
                },
                creator: {
                    subqueries: {
                        img: {},
                    },
                },
                owner: {},
            },
        }, "admin").then(res => setCollections(res))
    }, [getAllOffsets]);

    useEffect(() => {
        setCollectionsFiltered(collections.filter(nft => {
            if (searchQuery.includes("@")) {
                let userQuery = searchQuery.split("@");
                if (userQuery[0] === "name") {
                    return nft.name.toLowerCase().includes(userQuery[1].toLowerCase());
                } else if (userQuery[0] === "date") {
                    return renderUserCreateDate(nft)
                        .toLowerCase()
                        .includes(userQuery[1].toLowerCase());
                } else if (userQuery[0] === "id") {
                    return nft.id === userQuery[1].toLowerCase();
                } else if (userQuery[0] === "creator") {
                    return nft?.creator.name.toLowerCase().includes(userQuery[1].toLowerCase());
                } else if (userQuery[0] === "categories") {
                    return nft?.categories?.includes(
                        userQuery[1][0]?.toUpperCase() + userQuery[1].slice(1)?.toLowerCase(),
                    );
                } else if (userQuery[0] === "state") {
                    return nft.state.toLowerCase().includes(userQuery[1].toLowerCase());
                }
            } else {
                return (
                    nft.name.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                    renderUserCreateDate(nft)
                        .toLowerCase()
                        ?.includes(searchQuery.toLowerCase()) ||
                    nft.id?.includes(searchQuery.toLowerCase()) ||
                    nft?.creator.name.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                    nft?.categories?.includes(searchQuery.toLowerCase()) ||
                    nft.state.toLowerCase()?.includes(searchQuery.toLowerCase())
                );
            }
        })
            .sort((a, b) => {
                if (currentSort === "name-atLow") {
                    return a.name.toUpperCase() < b.name.toUpperCase()
                        ? -1
                        : a.name.toUpperCase() > b.name.toUpperCase()
                            ? 1
                            : 0;
                } else if (currentSort === "name-atHigh") {
                    return a.name.toUpperCase() > b.name.toUpperCase()
                        ? -1
                        : a.name.toUpperCase() < b.name.toUpperCase()
                            ? 1
                            : 0;
                } else if (currentSort === "date-atLow") {
                    return Number(a.add_time) - Number(b.add_time);
                } else if (currentSort === "date-atHigh") {
                    return Number(b.add_time) - Number(a.add_time);
                } else if (currentSort === "id-atLow") {
                    return Number(a.id) - Number(b.id);
                } else if (currentSort === "id-atHigh") {
                    return Number(b.id) - Number(a.id);
                } else if (currentSort === "collection-atLow") {
                    return a?.is_hidden < b?.is_hidden ? -1 : a?.is_hidden > b?.is_hidden ? 1 : 0;
                } else if (currentSort === "collection-atHigh") {
                    return a?.is_hidden > b?.is_hidden ? -1 : a?.is_hidden < b?.is_hidden ? 1 : 0;
                } else if (currentSort === "creator-atLow") {
                    let creatorNameA = a?.creator?.name?.toUpperCase()
                        ? a?.creator?.name?.toUpperCase()
                        : "0";
                    let creatorNameB = b?.creator?.name?.toUpperCase()
                        ? b?.creator?.name?.toUpperCase()
                        : "0";
                    return creatorNameA < creatorNameB ? -1 : creatorNameA > creatorNameB ? 1 : 0;
                } else if (currentSort === "creator-atHigh") {
                    let creatorNameA = a?.creator?.name?.toUpperCase()
                        ? a?.creator?.name?.toUpperCase()
                        : "0";
                    let creatorNameB = b?.creator?.name?.toUpperCase()
                        ? b?.creator?.name?.toUpperCase()
                        : "0";
                    return creatorNameA > creatorNameB ? -1 : creatorNameA < creatorNameB ? 1 : 0;
                } else if (currentSort === "owner-atLow") {
                    return a.categories?.length < b.categories?.length
                        ? 1
                        : a.categories?.length > b.categories?.length
                            ? -1
                            : 0;
                } else if (currentSort === "owner-atHigh") {
                    return a.categories?.length > b.categories?.length
                        ? 1
                        : a.categories?.length < b.categories?.length
                            ? -1
                            : 0;
                } else if (currentSort === "state-atLow") {
                    return a?.state < b?.state ? -1 : a?.state > b?.state ? 1 : 0;
                } else if (currentSort === "state-atHigh") {
                    return a?.state > b?.state ? -1 : a?.state < b?.state ? 1 : 0;
                }
            }))
    }, [collections, currentSort, searchQuery]);

    useEffect(() => {
        setSelects([])
        setSelectedAll(false)
    }, [collectionsFiltered])

    const setCheckbox = e => {
        let currentID = e.target.getAttribute("data-id");
        if (selects.includes(currentID)) {
            let copy = [...selects];
            setSelects(copy.filter(id => id !== currentID));
        } else {
            setSelects([...selects, currentID]);
        }
    };

    const setCheckboxesAll = () => {
        setSelectedAll(!selectedAll);
        if (!selectedAll) {
            let users_ids = [];
            collectionsFiltered.map(user => users_ids.push(user.id));
            setSelects(users_ids);
        } else {
            setSelects([]);
        }
    };

    const renderUserCreateDate = user => {
        let createDate = new Date(user.add_time * 1000);
        let endDate =
            ("0" + createDate.getDate()).slice(-2) +
            "." +
            ("0" + (Number(createDate.getMonth()) + 1)).slice(-2) +
            "." +
            createDate.getFullYear() +
            " " +
            ("0" + createDate.getHours()).slice(-2) +
            ":" +
            ("0" + createDate.getMinutes()).slice(-2) +
            ` (UTC +${createDate.getTimezoneOffset() / -60})`;
        return endDate;
    };

    const renderAcceptDeleteUser = () => {
        const deleteUser = () => {
            axios
                .post(
                    "https://nft-one.art/api/nft_likes/list",
                    {
                        filters: {
                            collection_id: acceptDeleteUserID,
                        },
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("adminToken"),
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    if (response.data.items.length > 0) {
                        let currentLikesIDs = [];
                        response.data.items.map(like => currentLikesIDs.push(like.id));
                        axios
                            .post(
                                "https://nft-one.art/api/nft_likes/delete",
                                {
                                    ids: [...currentLikesIDs],
                                },
                                {
                                    headers: {
                                        Token: localStorage.getItem("adminToken"),
                                    },
                                    auth: {
                                        username: "odmen",
                                        password: "NFTflsy",
                                    },
                                },
                            )
                            .then(response => {
                                axios
                                    .post(
                                        "https://nft-one.art/api/nft_collections/delete",
                                        {
                                            ids: [acceptDeleteUserID],
                                        },
                                        {
                                            headers: {
                                                Token: localStorage.getItem("adminToken"),
                                            },
                                            auth: {
                                                username: "odmen",
                                                password: "NFTflsy",
                                            },
                                        },
                                    )
                                    .then(response => {
                                        getAllOffsets("nft_collections", {
                                            filters: {
                                                is_foreign: false,
                                            },
                                            subqueries: {
                                                img: {},
                                                collection: {
                                                    subqueries: {
                                                        img: {},
                                                    },
                                                },
                                                creator: {
                                                    subqueries: {
                                                        img: {},
                                                    },
                                                },
                                                owner: {},
                                            },
                                        }, "admin").then(res => {
                                            setAcceptDeleteUserUsername("");
                                                setAcceptDeleteUserID("");
                                                setShowAcceptDeleteUser(false);
                                                setCollections(res);
                                                toast.success("NFT deleted", {
                                                    position: "bottom-right",
                                                    style: {
                                                        font: "400 18px/100% 'DM Sans'",
                                                    },
                                                });
                                        })
                                        // axios
                                        //     .post(
                                        //         "https://nft-one.art/api/nft_collections/list",
                                        //         {
                                        //             filters: {
                                        //                 is_foreign: false,
                                        //             },
                                        //         },
                                        //         {
                                        //             headers: {
                                        //                 Token: localStorage.getItem("adminToken"),
                                        //             },
                                        //             auth: {
                                        //                 username: "odmen",
                                        //                 password: "NFTflsy",
                                        //             },
                                        //         },
                                        //     )
                                        //     .then(response => {
                                        //         setAcceptDeleteUserUsername("");
                                        //         setAcceptDeleteUserID("");
                                        //         setShowAcceptDeleteUser(false);
                                        //         setCollections(response.data.items.reverse());
                                        //         toast.success("NFT deleted", {
                                        //             position: "bottom-right",
                                        //             style: {
                                        //                 font: "400 18px/100% 'DM Sans'",
                                        //             },
                                        //         });
                                        //     })
                                        //     .catch(error => {
                                        //         console.log(error);
                                        //     });
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    } else {
                        axios
                            .post(
                                "https://nft-one.art/api/nft_collections/delete",
                                {
                                    ids: [acceptDeleteUserID],
                                },
                                {
                                    headers: {
                                        Token: localStorage.getItem("adminToken"),
                                    },
                                    auth: {
                                        username: "odmen",
                                        password: "NFTflsy",
                                    },
                                },
                            )
                            .then(response => {
                                getAllOffsets("nft_collections", {
                                    filters: {
                                        is_foreign: false,
                                    },
                                    subqueries: {
                                        img: {},
                                        collection: {
                                            subqueries: {
                                                img: {},
                                            },
                                        },
                                        creator: {
                                            subqueries: {
                                                img: {},
                                            },
                                        },
                                        owner: {},
                                    },
                                }, "admin").then(res => {
                                    setAcceptDeleteUserUsername("");
                                        setAcceptDeleteUserID("");
                                        setShowAcceptDeleteUser(false);
                                        setCollections(res);
                                        toast.success("Collection deleted", {
                                            position: "bottom-right",
                                            style: {
                                                font: "400 18px/100% 'DM Sans'",
                                            },
                                        });
                                        });
                                // axios
                                //     .post(
                                //         "https://nft-one.art/api/nft_collections/list",
                                //         {
                                //             filters: {
                                //                 is_foreign: false,
                                //             },
                                //         },
                                //         {
                                //             headers: {
                                //                 Token: localStorage.getItem("adminToken"),
                                //             },
                                //             auth: {
                                //                 username: "odmen",
                                //                 password: "NFTflsy",
                                //             },
                                //         },
                                //     )
                                //     .then(response => {
                                //         setAcceptDeleteUserUsername("");
                                //         setAcceptDeleteUserID("");
                                //         setShowAcceptDeleteUser(false);
                                //         setCollections(response.data.items.reverse());
                                //         toast.success("Collection deleted", {
                                //             position: "bottom-right",
                                //             style: {
                                //                 font: "400 18px/100% 'DM Sans'",
                                //             },
                                //         });
                                //     })
                                //     .catch(error => {
                                //         console.log(error);
                                //     });
                            })
                            .catch(error => {
                                if (error.response.data.error === "has-references") {
                                    toast.error("There are dependents", {
                                        position: "bottom-right",
                                        style: {
                                            font: "400 18px/100% 'DM Sans'",
                                        },
                                    });
                                    console.log(error)
                                } else {
                                    console.log(error);
                                }
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        return (
            <div className="acceptDeleteUser">
                <Toaster />
                <div className="acceptDeleteUser__content">
                    <img
                        className="acceptDeleteUser__content-close"
                        src="./img/header/close.png"
                        alt=""
                        onClick={() => {
                            setAcceptDeleteUserUsername("");
                            setAcceptDeleteUserID("");
                            setShowAcceptDeleteUser(false);
                        }}
                    />
                    <h1 className="acceptDeleteUser__content-title">Attention!</h1>
                    <p className="acceptDeleteUser__content-text">
                        Are you sure you want to delete the collection{" "}
                        <span>
                            {acceptDeleteUserUsername?.length > 16
                                ? acceptDeleteUserUsername.slice(0, 16) + "..."
                                : acceptDeleteUserUsername}
                        </span>
                        ? <br />
                        <br />
                        This action cannot be undone!
                    </p>
                    <button className="acceptDeleteUser__content-button" onClick={deleteUser}>
                        Yes, I know what I'm doing
                    </button>
                </div>
            </div>
        );
    };

    const renderAcceptDeleteUserWithCheckboxes = () => {
        const deleteUsers = () => {
            axios
                .post(
                    "https://nft-one.art/api/nfts/delete",
                    {
                        ids: [...selects],
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("adminToken"),
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    getAllOffsets("nfts", {filters: {
                        is_foreign: false,
                    },}, "admin").then(res => {
                        setSelects([]);
                        setShowAcceptDeleteUsers(false);
                        setCollections(response.data.items.reverse());
                        toast.success("collections deleted", {
                            position: "bottom-right",
                            style: {
                                font: "400 18px/100% 'DM Sans'",
                            },
                        });
                    })
                    // axios
                    //     .post(
                    //         "https://nft-one.art/api/nfts/list",
                    //         {
                    //             filters: {
                    //                 is_foreign: false,
                    //             },
                    //         },
                    //         {
                    //             headers: {
                    //                 Token: localStorage.getItem("adminToken"),
                    //             },
                    //             auth: {
                    //                 username: "odmen",
                    //                 password: "NFTflsy",
                    //             },
                    //         },
                    //     )
                    //     .then(response => {
                    //         setSelects([]);
                    //         setShowAcceptDeleteUsers(false);
                    //         setCollections(response.data.items.reverse());
                    //         toast.success("collections deleted", {
                    //             position: "bottom-right",
                    //             style: {
                    //                 font: "400 18px/100% 'DM Sans'",
                    //             },
                    //         });
                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });
                })
                .catch(error => {
                    if (error.response.data.error === "has-references") {
                        toast.error("There are dependent NFTs", {
                            position: "bottom-right",
                            style: {
                                font: "400 18px/100% 'DM Sans'",
                            },
                        });
                    } else {
                        console.log(error);
                    }
                });
        };

        return (
            <div className="acceptDeleteUser">
                <Toaster />
                <div className="acceptDeleteUser__content">
                    <img
                        className="acceptDeleteUser__content-close"
                        src="./img/header/close.png"
                        alt=""
                        onClick={() => {
                            setShowAcceptDeleteUsers(false);
                        }}
                    />
                    <h1 className="acceptDeleteUser__content-title">Attention!</h1>
                    <p className="acceptDeleteUser__content-text">
                        Are you sure you want to delete <span>{selects.length + " collections"}</span>? <br />
                        <br />
                        This action cannot be undone!
                    </p>
                    <button className="acceptDeleteUser__content-button" onClick={deleteUsers}>
                        Yes, I know what I'm doing
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <Toaster />
            {
                window.innerWidth <= 768 ? (
                    <Draggable axis="x" handle=".adminPanel__content-table-header">
                        <div>
                            <div class="adminPanel__content-table">
                        <div className="adminPanel__content-table-header">
                            <h1 className="adminPanel__content-table-header-title">
                                Collections
                                <span style={{ color: "red" }}>
                                    {selects.length > 0 ? " (" + selects.length + " selected)" : ""}
                                    {selects.length > 0 && (
                                        <img
                                            src="/img/adminPanel/delete.svg"
                                            alt=""
                                            onClick={() => {
                                                setShowAcceptDeleteUsers(true);
                                            }}
                                        />
                                    )}
                                </span>
                            </h1>
                            <button className="adminPanel__content-table-header-show" onClick={() => setShowItems(!showItems)}>
                                {showItems ? `Hide ${collectionsFiltered.length} items` : `Show ${collectionsFiltered.length} items`}
                                <img
                                    className="adminPanel__content-table-header-show-img"
                                    src="./img/adminPanel/arrow.svg"
                                    alt=""
                                    style={{ transform: showItems ? "rotate(180deg)" : "rotate(0deg)" }}
                                />
                            </button>
                        </div>
                        {showItems && (
                            <>
                                <div className="adminPanel__content-table-search">
                                    <img src="./img/adminPanel/search.svg" alt="" />
                                    <input
                                        placeholder="Search Name, Date, Number..."
                                        type="text"
                                        class="adminPanel__content-table-search-input"
                                        value={searchQuery}
                                        onChange={e => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="adminPanel__content-table-main">
                                    <div class="adminPanel__content-table-main-head">
                                        <div class="adminPanel__content-table-main-head-title">
                                            <div
                                                className={
                                                    selectedAll ? "adminPanel__content-table-main-head-title--checked" : null
                                                }>
                                                <input type="checkbox" onClick={setCheckboxesAll} />
                                            </div>
                                            <span
                                                onClick={() => {
                                                    if (currentSort !== "name-atLow" && currentSort !== "name-atHigh") {
                                                        setCurrentSort("name-atLow");
                                                    } else if (currentSort === "name-atLow") {
                                                        setCurrentSort("name-atHigh");
                                                    } else if (currentSort === "name-atHigh") {
                                                        setCurrentSort("name-atLow");
                                                    }
                                                }}>
                                                Name
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span
                                                onClick={() => {
                                                    if (currentSort !== "date-atLow" && currentSort !== "date-atHigh") {
                                                        setCurrentSort("date-atLow");
                                                    } else if (currentSort === "date-atLow") {
                                                        setCurrentSort("date-atHigh");
                                                    } else if (currentSort === "date-atHigh") {
                                                        setCurrentSort("date-atLow");
                                                    }
                                                }}>
                                                Create date
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span
                                                onClick={() => {
                                                    if (currentSort !== "id-atLow" && currentSort !== "id-atHigh") {
                                                        setCurrentSort("id-atLow");
                                                    } else if (currentSort === "id-atLow") {
                                                        setCurrentSort("id-atHigh");
                                                    } else if (currentSort === "id-atHigh") {
                                                        setCurrentSort("id-atLow");
                                                    }
                                                }}>
                                                ID
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span
                                                onClick={() => {
                                                    if (
                                                        currentSort !== "collection-atLow" &&
                                                        currentSort !== "collection-atHigh"
                                                    ) {
                                                        setCurrentSort("collection-atLow");
                                                    } else if (currentSort === "collection-atLow") {
                                                        setCurrentSort("collection-atHigh");
                                                    } else if (currentSort === "collection-atHigh") {
                                                        setCurrentSort("collection-atLow");
                                                    }
                                                }}>
                                                Status
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span
                                                onClick={() => {
                                                    if (currentSort !== "creator-atLow" && currentSort !== "creator-atHigh") {
                                                        setCurrentSort("creator-atLow");
                                                    } else if (currentSort === "creator-atLow") {
                                                        setCurrentSort("creator-atHigh");
                                                    } else if (currentSort === "creator-atHigh") {
                                                        setCurrentSort("creator-atLow");
                                                    }
                                                }}>
                                                Creator
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span
                                                onClick={() => {
                                                    if (currentSort !== "owner-atLow" && currentSort !== "owner-atHigh") {
                                                        setCurrentSort("owner-atLow");
                                                    } else if (currentSort === "owner-atLow") {
                                                        setCurrentSort("owner-atHigh");
                                                    } else if (currentSort === "owner-atHigh") {
                                                        setCurrentSort("owner-atLow");
                                                    }
                                                }}>
                                                Categories
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                        <div class="adminPanel__content-table-main-head-title">
                                            <span
                                                onClick={() => {
                                                    if (currentSort !== "state-atLow" && currentSort !== "state-atHigh") {
                                                        setCurrentSort("state-atLow");
                                                    } else if (currentSort === "state-atLow") {
                                                        setCurrentSort("state-atHigh");
                                                    } else if (currentSort === "state-atHigh") {
                                                        setCurrentSort("state-atLow");
                                                    }
                                                }}>
                                                State
                                                <img src="./img/adminPanel/sort.svg" alt="" />
                                            </span>
                                        </div>
                                    </div>
                                    {collectionsFiltered
                                        .map(nft => (
                                            <div className="adminPanel__content-table-main-row">
                                                <div className="adminPanel__content-table-main-row-name">
                                                    <div
                                                        className={
                                                            selects.includes(nft?.id)
                                                                ? "adminPanel__content-table-main-head-title--checked"
                                                                : null
                                                        }>
                                                        <input
                                                            data-id={nft?.id}
                                                            type="checkbox"
                                                            onClick={e => setCheckbox(e)}
                                                        />
                                                    </div>
                                                    <a href={`/collection/${nft?.id}`} target="_blank" rel="noreferrer">
                                                        <section
                                                            className="adminPanel__content-table-main-row-name-nftImg"
                                                            style={{
                                                                background: `${`url(https://nft-one.art/api/files/thumb/?hash=${nft?.img?.hash}&width=45&height=33) no-repeat center center/cover`}`,
                                                            }}></section>
                                                        <span>
                                                            {nft?.name?.length > 16 ? nft.name.slice(0, 16) + "..." : nft?.name}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="adminPanel__content-table-main-row-date">
                                                    {renderUserCreateDate(nft)}
                                                </div>
                                                <div className="adminPanel__content-table-main-row-title">
                                                    {"Collection ID: " + nft.id}
                                                </div>
                                                <div className="adminPanel__content-table-main-row-status">
                                                    <div
                                                        className={`adminPanel__content-table-main-row-status-${
                                                            !nft?.is_hidden ? "active" : "banned"
                                                        }`}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            axios
                                                                .post(
                                                                    "https://nft-one.art/api/nft_collections/upsert",
                                                                    {
                                                                        items: [
                                                                            {
                                                                                id: nft?.id,
                                                                                is_hidden: !nft?.is_hidden,
                                                                            },
                                                                        ],
                                                                    },
                                                                    {
                                                                        headers: {
                                                                            Token: localStorage.getItem("adminToken"),
                                                                        },
                                                                        auth: {
                                                                            username: "odmen",
                                                                            password: "NFTflsy",
                                                                        },
                                                                    },
                                                                )
                                                                .then(response => {
                                                                    getAllOffsets("nft_collections", {
            filters: {
                is_foreign: false,
            },
            subqueries: {
                img: {},
                collection: {
                    subqueries: {
                        img: {},
                    },
                },
                creator: {
                    subqueries: {
                        img: {},
                    },
                },
                owner: {},
            },
        }, "admin").then(res => setCollections(res))
                                                                })
                                                                .catch(error => {
                                                                    console.log(error);
                                                                });
                                                        }}>
                                                        {nft?.is_hidden ? "Inactive" : "Active"}
                                                    </div>
                                                </div>
                                                <div className="adminPanel__content-table-main-row-title">
                                                    {nft?.creator?.name ? (
                                                        <a href={`/profile/${nft.creator_id}`}>
                                                                <div
                                                                    className="adminPanel__content-table-main-row-title-avatar"
                                                                    style={{
                                                                        background: nft?.creator?.img?.hash ? `${`url(https://nft-one.art/api/files/thumb/?hash=${nft?.creator?.img?.hash}&width=32&height=32) no-repeat center center/cover`}` : `${`url(/img/sections/myNFT/avatar.svg) no-repeat center center/cover`}`,
                                                                    }}></div>
                                                            <span>
                                                                {nft?.creator?.name?.length > 16
                                                                    ? nft?.creator?.name.slice(0, 16)
                                                                    : nft?.creator?.name}
                                                            </span>
                                                        </a>
                                                    ) : (
                                                        "—"
                                                    )}
                                                </div>
                                                <div className="adminPanel__content-table-main-row-title">
                                                    {nft.categories?.length > 0
                                                        ? nft.categories.map((category, index) =>
                                                            index !== nft.categories?.length - 1 ? (
                                                                <div>{`${category},`}&nbsp;</div>
                                                            ) : (
                                                                <div>{category}</div>
                                                            ),
                                                        )
                                                        : "—"}
                                                </div>
                                                <div className="adminPanel__content-table-main-row-status">
                                                    <div className={`adminPanel__content-table-main-row-status-${nft.state}`}>
                                                        {nft.state[0].toUpperCase() + nft.state.slice(1)}
                                                    </div>
                                                </div>
                                                <div className="adminPanel__content-table-main-row-btns">
                                                    {nft.state === "new" ? (
                                                        <>
                                                            <img
                                                                src="./img/adminPanel/delete.svg"
                                                                alt="Delete nft"
                                                                onClick={() => {
                                                                    setAcceptDeleteUserID(nft.id);
                                                                    setAcceptDeleteUserUsername(nft.name);
                                                                    setShowAcceptDeleteUser(true);
                                                                }}
                                                            />
                                                            <img
                                                                src="./img/adminPanel/edit.svg"
                                                                alt="Edit nft"
                                                                onClick={() => {
                                                                    window.location.href = `/edit-collection/${nft.id}`;
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <img
                                                            src="./img/adminPanel/delete.svg"
                                                            alt="Delete nft"
                                                            onClick={() => {
                                                                setAcceptDeleteUserID(nft.id);
                                                                setAcceptDeleteUserUsername(nft.name);
                                                                setShowAcceptDeleteUser(true);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                            </div>
                        </div>
                    </Draggable>
                ) : (
                <div class="adminPanel__content-table">
                <div className="adminPanel__content-table-header">
                    <h1 className="adminPanel__content-table-header-title">
                        Collections
                        <span style={{ color: "red" }}>
                            {selects.length > 0 ? " (" + selects.length + " selected)" : ""}
                            {selects.length > 0 && (
                                <img
                                    src="/img/adminPanel/delete.svg"
                                    alt=""
                                    onClick={() => {
                                        setShowAcceptDeleteUsers(true);
                                    }}
                                />
                            )}
                        </span>
                    </h1>
                    <button className="adminPanel__content-table-header-show" onClick={() => setShowItems(!showItems)}>
                        {showItems ? `Hide ${collectionsFiltered.length} items` : `Show ${collectionsFiltered.length} items`}
                        <img
                            className="adminPanel__content-table-header-show-img"
                            src="./img/adminPanel/arrow.svg"
                            alt=""
                            style={{ transform: showItems ? "rotate(180deg)" : "rotate(0deg)" }}
                        />
                    </button>
                </div>
                {showItems && (
                    <>
                        <div className="adminPanel__content-table-search">
                            <img src="./img/adminPanel/search.svg" alt="" />
                            <input
                                placeholder="Search Name, Date, Number..."
                                type="text"
                                class="adminPanel__content-table-search-input"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <div className="adminPanel__content-table-main">
                            <div class="adminPanel__content-table-main-head">
                                <div class="adminPanel__content-table-main-head-title">
                                    <div
                                        className={
                                            selectedAll ? "adminPanel__content-table-main-head-title--checked" : null
                                        }>
                                        <input type="checkbox" onClick={setCheckboxesAll} />
                                    </div>
                                    <span
                                        onClick={() => {
                                            if (currentSort !== "name-atLow" && currentSort !== "name-atHigh") {
                                                setCurrentSort("name-atLow");
                                            } else if (currentSort === "name-atLow") {
                                                setCurrentSort("name-atHigh");
                                            } else if (currentSort === "name-atHigh") {
                                                setCurrentSort("name-atLow");
                                            }
                                        }}>
                                        Name
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                                <div class="adminPanel__content-table-main-head-title">
                                    <span
                                        onClick={() => {
                                            if (currentSort !== "date-atLow" && currentSort !== "date-atHigh") {
                                                setCurrentSort("date-atLow");
                                            } else if (currentSort === "date-atLow") {
                                                setCurrentSort("date-atHigh");
                                            } else if (currentSort === "date-atHigh") {
                                                setCurrentSort("date-atLow");
                                            }
                                        }}>
                                        Create date
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                                <div class="adminPanel__content-table-main-head-title">
                                    <span
                                        onClick={() => {
                                            if (currentSort !== "id-atLow" && currentSort !== "id-atHigh") {
                                                setCurrentSort("id-atLow");
                                            } else if (currentSort === "id-atLow") {
                                                setCurrentSort("id-atHigh");
                                            } else if (currentSort === "id-atHigh") {
                                                setCurrentSort("id-atLow");
                                            }
                                        }}>
                                        ID
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                                <div class="adminPanel__content-table-main-head-title">
                                    <span
                                        onClick={() => {
                                            if (
                                                currentSort !== "collection-atLow" &&
                                                currentSort !== "collection-atHigh"
                                            ) {
                                                setCurrentSort("collection-atLow");
                                            } else if (currentSort === "collection-atLow") {
                                                setCurrentSort("collection-atHigh");
                                            } else if (currentSort === "collection-atHigh") {
                                                setCurrentSort("collection-atLow");
                                            }
                                        }}>
                                        Status
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                                <div class="adminPanel__content-table-main-head-title">
                                    <span
                                        onClick={() => {
                                            if (currentSort !== "creator-atLow" && currentSort !== "creator-atHigh") {
                                                setCurrentSort("creator-atLow");
                                            } else if (currentSort === "creator-atLow") {
                                                setCurrentSort("creator-atHigh");
                                            } else if (currentSort === "creator-atHigh") {
                                                setCurrentSort("creator-atLow");
                                            }
                                        }}>
                                        Creator
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                                <div class="adminPanel__content-table-main-head-title">
                                    <span
                                        onClick={() => {
                                            if (currentSort !== "owner-atLow" && currentSort !== "owner-atHigh") {
                                                setCurrentSort("owner-atLow");
                                            } else if (currentSort === "owner-atLow") {
                                                setCurrentSort("owner-atHigh");
                                            } else if (currentSort === "owner-atHigh") {
                                                setCurrentSort("owner-atLow");
                                            }
                                        }}>
                                        Categories
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                                <div class="adminPanel__content-table-main-head-title">
                                    <span
                                        onClick={() => {
                                            if (currentSort !== "state-atLow" && currentSort !== "state-atHigh") {
                                                setCurrentSort("state-atLow");
                                            } else if (currentSort === "state-atLow") {
                                                setCurrentSort("state-atHigh");
                                            } else if (currentSort === "state-atHigh") {
                                                setCurrentSort("state-atLow");
                                            }
                                        }}>
                                        State
                                        <img src="./img/adminPanel/sort.svg" alt="" />
                                    </span>
                                </div>
                            </div>
                            {collectionsFiltered
                                .map(nft => (
                                    <div className="adminPanel__content-table-main-row">
                                        <div className="adminPanel__content-table-main-row-name">
                                            <div
                                                className={
                                                    selects.includes(nft?.id)
                                                        ? "adminPanel__content-table-main-head-title--checked"
                                                        : null
                                                }>
                                                <input
                                                    data-id={nft?.id}
                                                    type="checkbox"
                                                    onClick={e => setCheckbox(e)}
                                                />
                                            </div>
                                            <a href={`/collection/${nft?.id}`} target="_blank" rel="noreferrer">
                                                <section
                                                    className="adminPanel__content-table-main-row-name-nftImg"
                                                    style={{
                                                        background: `${`url(https://nft-one.art/api/files/thumb/?hash=${nft?.img?.hash}&width=45&height=33) no-repeat center center/cover`}`,
                                                    }}></section>
                                                <span>
                                                    {nft?.name?.length > 16 ? nft.name.slice(0, 16) + "..." : nft?.name}
                                                </span>
                                            </a>
                                        </div>
                                        <div className="adminPanel__content-table-main-row-date">
                                            {renderUserCreateDate(nft)}
                                        </div>
                                        <div className="adminPanel__content-table-main-row-title">
                                            {"Collection ID: " + nft.id}
                                        </div>
                                        <div className="adminPanel__content-table-main-row-status">
                                            <div
                                                className={`adminPanel__content-table-main-row-status-${
                                                    !nft?.is_hidden ? "active" : "banned"
                                                }`}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    axios
                                                        .post(
                                                            "https://nft-one.art/api/nft_collections/upsert",
                                                            {
                                                                items: [
                                                                    {
                                                                        id: nft?.id,
                                                                        is_hidden: !nft?.is_hidden,
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                headers: {
                                                                    Token: localStorage.getItem("adminToken"),
                                                                },
                                                                auth: {
                                                                    username: "odmen",
                                                                    password: "NFTflsy",
                                                                },
                                                            },
                                                        )
                                                        .then(response => {
                                                            getAllOffsets("nft_collections", {
            filters: {
                is_foreign: false,
            },
            subqueries: {
                img: {},
                collection: {
                    subqueries: {
                        img: {},
                    },
                },
                creator: {
                    subqueries: {
                        img: {},
                    },
                },
                owner: {},
            },
        }, "admin").then(res => setCollections(res))
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                        });
                                                }}>
                                                {nft?.is_hidden ? "Inactive" : "Active"}
                                            </div>
                                        </div>
                                        <div className="adminPanel__content-table-main-row-title">
                                            {nft?.creator?.name ? (
                                                <a href={`/profile/${nft.creator_id}`}>
                                                        <div
                                                            className="adminPanel__content-table-main-row-title-avatar"
                                                            style={{
                                                                background: nft?.creator?.img?.hash ? `${`url(https://nft-one.art/api/files/thumb/?hash=${nft?.creator?.img?.hash}&width=32&height=32) no-repeat center center/cover`}` : `${`url(/img/sections/myNFT/avatar.svg) no-repeat center center/cover`}`,
                                                            }}></div>
                                                    <span>
                                                        {nft?.creator?.name?.length > 16
                                                            ? nft?.creator?.name.slice(0, 16)
                                                            : nft?.creator?.name}
                                                    </span>
                                                </a>
                                            ) : (
                                                "—"
                                            )}
                                        </div>
                                        <div className="adminPanel__content-table-main-row-title">
                                            {
                                                nft.categories?.length > 0 ?
                                                    nft.categories?.length > 2 ? <>
                                                            <div>{nft.categories[0]},&nbsp;</div>
                                                            <div>{nft.categories[1]}</div>
                                                            <div style={{opacity: "75%"}}>&nbsp;+{nft.categories.length - 2}</div>
                                                    </> : <>
                                                    {
                                                        nft.categories.map((category, index) =>
                                                            index !== nft.categories?.length - 1 ? (
                                                                <div>{category},&nbsp;</div>
                                                          ) : (
                                                              <div>{category}</div>
                                                          ),
                                                        )
                                                    }
                                                    </>
                                                : "—"
                                            }
                                        </div>
                                        <div className="adminPanel__content-table-main-row-status">
                                            <div className={`adminPanel__content-table-main-row-status-${nft.state}`}>
                                                {nft.state[0].toUpperCase() + nft.state.slice(1)}
                                            </div>
                                        </div>
                                        <div className="adminPanel__content-table-main-row-btns">
                                            {nft.state === "new" ? (
                                                <>
                                                    <img
                                                        src="./img/adminPanel/delete.svg"
                                                        alt="Delete nft"
                                                        onClick={() => {
                                                            setAcceptDeleteUserID(nft.id);
                                                            setAcceptDeleteUserUsername(nft.name);
                                                            setShowAcceptDeleteUser(true);
                                                        }}
                                                    />
                                                    <img
                                                        src="./img/adminPanel/edit.svg"
                                                        alt="Edit nft"
                                                        onClick={() => {
                                                            window.location.href = `/edit-collection/${nft.id}`;
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <img
                                                    src="./img/adminPanel/delete.svg"
                                                    alt="Delete nft"
                                                    onClick={() => {
                                                        setAcceptDeleteUserID(nft.id);
                                                        setAcceptDeleteUserUsername(nft.name);
                                                        setShowAcceptDeleteUser(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>)}
            {showAcceptDeleteUser && renderAcceptDeleteUser()}
            {showAcceptDeleteUsers && renderAcceptDeleteUserWithCheckboxes()}
        </>
    );
}

export default AdminPanelCollections;
