import "./Catalog.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useContext, useEffect, useState } from "react";
import { ContextApp } from "../../Context";
import CollectionCard from "../../components/CollectionCard";
import DefaultCard from "../../components/DefaultCard";
import Filters from "../../components/Filters";
import axios from "axios";
import {useQuery, useQueryClient} from "react-query";

function Catalog(options) {
    const { globalExtraFilters, theme, changeTheme, filterStatus, filterQuantity, filterPriceAt, filterPriceTo, filterCategory, filterEmotional, filterSearch, setFilterSearch } =
        useContext(ContextApp);

    const queryClient = useQueryClient();

    const [filter, setFilter] = useState("nft");
    const [sortingPopup, setSortingPopup] = useState(false);
    const [sortingCurrent, setSortingCurrent] = useState("Recently created");
    const [pricePopup, setPricePopup] = useState(false);
    const [priceCurrent, setPriceCurrent] = useState("Low to High");

    const [filtersMobile, setFiltersMobile] = useState(false);
    const [sortMobile, setSortMobile] = useState(false);

    const [currentUser, setCurrentUser] = useState({});

    const [currentSort, setCurrentSort] = useState("");

    const [floorPriceForCollections, setFloorPriceForCollections] = useState([])

    const [offset, setOffset] = useState(1)
    const [offsetItems, setOffsetItems] = useState([])

    const { data: NFTs} = useQuery("nfts", async () => {
        let items = []
        let numberOffsets = 0;

        const firstResponse = await axios.post(
            `https://nft-one.art/api/nfts/list`,
            {
                filters: {
                    is_foreign: false,
                    is_hidden: false
                },
                subqueries: {
                    img: {},
                    likes: {},
                    creator: {
                        subqueries: {
                            img: {}
                        }
                    }
                },
                calc_total: true
            })

        items.push(...firstResponse.data.items)

        if (Number(firstResponse.data.total) > 99) {
            numberOffsets = Math.floor((Number(firstResponse.data.total) / 100))
        }

        if (numberOffsets > 0) {
            const promises = []
            for (let start = 1; start <= numberOffsets; start++) {
                const promise = axios.post("https://nft-one.art/api/nfts/list", {
                    filters: {
                        is_foreign: false,
                        is_hidden: false
                    },
                    subqueries: {
                        img: {},
                        likes: {},
                        creator: {
                            subqueries: {
                                img: {}
                            }
                        }
                    },
                    offset: start * 100
                })

                promises.push(promise)
            }
            const responses = await Promise.all(promises)
            responses.forEach(response => items.push(...response.data.items))
        }

        items.map(nft => {
            if (nft.collection_id !== null) {
                setFloorPriceForCollections(prev => [...prev, {
                    collection_id: nft.collection_id,
                    floor_price: nft.price
                }])
            }
        })

        return items.reverse();
    });
    const { data: collections } = useQuery("collections", async () => {
        let items = []
        let numberOffsets = 0;

        const firstResponse = await axios.post(
            `https://nft-one.art/api/nft_collections/list`,
            {
                filters: {
                    is_foreign: false,
                    is_hidden: false
                },
                subqueries: {
                    img: {},
                    likes: {},
                    creator: {
                        subqueries: {
                            img: {}
                        }
                    }
                },
                calc_total: true
            })

        items.push(...firstResponse.data.items)

        if (Number(firstResponse.data.total) > 99) {
            numberOffsets = Math.floor((Number(firstResponse.data.total) / 100))
        }

        if (numberOffsets > 0) {
            const promises = []
            for (let start = 1; start <= numberOffsets; start++) {
                const promise = axios.post("https://nft-one.art/api/nft_collections/list", {
                    filters: {
                        is_foreign: false,
                        is_hidden: false
                    },
                    subqueries: {
                        img: {},
                        likes: {},
                        creator: {
                            subqueries: {
                                img: {}
                            }
                        }
                    },
                    offset: start * 100
                })

                promises.push(promise)
            }
            const responses = await Promise.all(promises)
            responses.forEach(response => items.push(...response.data.items))
        }

        return items.reverse();
    })
    const { data: history} = useQuery("history", async () => {
        let items = []
        let numberOffsets = 0;

        const firstResponse = await axios.post(
            `https://nft-one.art/api/nft_history/list`,
            {
                filters: {
                    event_type: ["buy", "end"]
                },
                calc_total: true
            })

        items.push(...firstResponse.data.items)

        if (Number(firstResponse.data.total) > 99) {
            numberOffsets = Math.floor((Number(firstResponse.data.total) / 100))
        }

        if (numberOffsets > 0) {
            const promises = []
            for (let start = 1; start <= numberOffsets; start++) {
                const promise = axios.post("https://nft-one.art/api/nft_history/list", {
                    filters: {
                        event_type: ["buy", "end"]
                    },
                    offset: start * 100
                })

                promises.push(promise)
            }
            const responses = await Promise.all(promises)
            responses.forEach(response => items.push(...response.data.items))
        }

        console.log(items)

        return items.reverse();
    });

    function OneOrMinusOne(a, b) {
        return a > b ? 1 : a < b ? -1 : 0;
    }

    function checkScore(nft) {
        let typeIDs = [];
        let score = 0;
        filterEmotional?.map((emot) => (
            emot === "Red Heart"
                ? typeIDs.push("1")
                : emot === "Rolling on the Floor Laughing"
                    ? typeIDs.push("2")
                    : emot === "Smiling Face with Heart-Eyes"
                        ? typeIDs.push("3")
                        : emot === "Enraged Face"
                            ? typeIDs.push("4")
                            : emot === "Weary Cat"
                                ? typeIDs.push("5")
                                : emot === "Woozy Face"
                                    ? typeIDs.push("6")
                                    : emot === "Money-Mouth Face"
                                        ? typeIDs.push("7")
                                        : null
        )
        );
        if (nft.likes.length > 0 || nft?.anon_likes) {
            if (nft.likes.length > 0) {
                    nft.likes.map(like => {
                        if (typeIDs.includes(like.type_id)) {
                            score += 1;
                        }
                    }
                )
            }
            if (nft?.anon_likes) {
                Object.keys(nft.anon_likes).map(like_id => {
                    if (typeIDs.includes(like_id)) {
                        score += 1
                    }
                })
            }
        }
        return score;
    }

        useEffect(() => {
        if (window?.location?.href?.split("?")[1]?.split("=")[1] && window?.location?.href?.split("?")[1]?.split("=")[1] !== "") {
            setFilterSearch(window?.location?.href?.split("?")[1]?.split("=")[1])
            setFilter((window?.location?.href?.split("?")[2]))
        }
        if (localStorage.getItem("auth")) {
            axios
                .post(
                    "https://nft-one.art/api/auth/current",
                    {
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("tonkeeperToken") ? localStorage.getItem("tonkeeperToken") : localStorage.getItem("tonhubToken")
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    setCurrentUser(response.data.user);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [])

    useEffect(() => {
        setOffset(1)
        setOffsetItems([])
    }, [filter, filter, sortingCurrent, priceCurrent, globalExtraFilters, filterStatus, filterQuantity, filterPriceAt, filterPriceTo, filterCategory, filterEmotional, filterSearch, currentSort])

    useEffect(() => {
        if (NFTs && filter === 'nft') {
            setOffsetItems(NFTs.filter(nft => nft.name.toLowerCase().includes(filterSearch.toLowerCase()))
                .filter(nft =>
                    filterStatus === "all"
                        ? nft
                        : filterStatus === "Buy now"
                            ? nft.state === "sale"
                            : filterStatus === "In auction"
                                ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                )
                .filter(nft =>
                    filterQuantity === "all"
                        ? nft
                        : filterQuantity === "single"
                            ? nft.collection_id == null
                            : nft.collection_id != null,
                )
                .filter(nft =>
                    filterPriceAt !== "" ? Number(nft.price) >= Number(filterPriceAt * (10 ** 9)) : nft,
                )
                .filter(nft =>
                    filterPriceTo !== "" ? Number(nft.price) <= Number(filterPriceTo * (10 ** 9)) : nft,
                )
                .filter(nft => {
                    if (filterCategory.length === 0) {
                        return nft
                    } else {
                        let flag = false;
                        if (nft?.categories) {
                            nft.categories.map(cat =>
                                filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                            );
                        }
                        return flag;
                    }
                })
                .filter(nft => {
                    if (globalExtraFilters?.length > 0) {
                        if (nft?.attrs) {
                            if (1) {
                                let flag = 0
                                globalExtraFilters.map(item => {
                                    if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                        if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                            flag = 1
                                        }
                                    }
                                })
                                return flag
                            } else {
                                return 0
                            }
                        } else {
                            return 0
                        }
                    } else {
                        return 1
                    }
                })
                .sort((a, b) => {
                    if (currentSort === "Price") {
                        if (priceCurrent === "Low to High") {
                            return Number(a.price) - Number(b.price)
                        } else {
                            return Number(b.price) - Number(a.price)
                        }
                    } else if (currentSort === "Sorting") {
                        if (sortingCurrent === "Recently created") {
                            return (Number(b.add_time) - Number(a.add_time));
                        } else if (sortingCurrent === "Ending soon") {
                            return (Number(a.end_time) - Number(b.end_time))
                        } else {
                            return (lastNFTSoldTime(b?.id) - lastNFTSoldTime(a?.id))
                        }
                    }
                })
                .sort((a, b) => {
                    return OneOrMinusOne(checkScore(b), checkScore(a));
                })
                .slice(0, offset * 12))
        }
        if (collections && filter === "collection") {
            setOffsetItems(collections
                .filter(collection =>
                    filterSearch !== ""
                        ? collection.name.toLowerCase().includes(filterSearch.toLowerCase())
                        : collection,
                )
                .filter(collection => {
                    if (filterCategory.length === 0) {
                        return collection
                    } else {
                        let flag = false;
                        if (collection?.categories) {
                            collection.categories.map(cat =>
                                filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                            );
                        }
                        return flag;
                    }
                })
                .filter(nft => {
                    if (globalExtraFilters?.length > 0) {
                        if (nft?.attrs) {
                            if (1) {
                                let flag = 0
                                globalExtraFilters.map(item => {
                                    if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                        if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                            flag = 1
                                        }
                                    }
                                })
                                return flag
                            } else {
                                return 0
                            }
                        } else {
                            return 0
                        }
                    } else {
                        return 1
                    }
                }).sort((a, b) => currentSort === "Price" ? (priceCurrent === "Low to High"
                    ? floorPriceSort(a, b)
                    : floorPriceSort(b, a)) : currentSort === "Sorting" ? (sortingCurrent === "Recently created" ? (Number(b.add_time) - Number(a.add_time)) : (Number(a.add_time) - Number(b.add_time))) : 0)
                .sort((a, b) => {
                    return OneOrMinusOne(checkScore(b), checkScore(a));
                })
                .slice(0, offset * 12))
        }
    }, [NFTs, collections, filter, sortingCurrent, priceCurrent, globalExtraFilters, filterStatus, filterQuantity, filterPriceAt, filterPriceTo, filterCategory, filterEmotional, filterSearch, offset, currentSort])

    const floorPriceSort = (a, b) => {
        let arrA = floorPriceForCollections
                                .filter(item => item.collection_id === a.id)
                                .sort((a, b) => Number(a.floor_price) - Number(b.floor_price))
        let arrB = floorPriceForCollections
                                .filter(item => item.collection_id === b.id)
                                .sort((a, b) => Number(a.floor_price) - Number(b.floor_price))
        return (arrA[0]?.floor_price ? Number(arrA[0].floor_price) : 0) - (arrB[0]?.floor_price ? Number(arrB[0].floor_price) : 0)
    }

    useEffect(() => {
        if (window.innerWidth <= 768) {
            let filter = document.getElementsByClassName("filters")[0]
            if (filtersMobile) {
                filter.style.opacity = "1"
                filter.style.pointerEvents = "all"
            } else {
                filter.style.opacity = "0"
                filter.style.pointerEvents = "none"
            }
        }
    }, [filtersMobile])

    const lastNFTSoldTime = (nftID) => {
        let flag = false;
        let addTime = 0;

        history.map(item => {
            if (!flag) {
                if ((item?.nft_id === nftID) && (["buy", "end", "buy_now", "bet"].includes(item?.event_type.toLowerCase())) && (Number(item?.price) > 0)) {
                    flag = true
                    addTime = item?.add_time
                }
            }
        })

        return addTime
    }

    return (
        <>
            <Header currentPage={"catalog"} />
            <section className="catalog" style={{ backgroundColor: changeTheme("#f4f6fa", "#15191E") }}>
                <h1 className="catalog__title" style={{ color: changeTheme("#000", "#fff") }}>
                    Catalog
                </h1>
                <div className="catalog__container">
                    {window.innerWidth > 768 && <Filters search example={filter === "nft" ? "nft" : "collection"} />}
                    <div className="catalog__container-content">
                        <div
                            className="catalog__container-content-optionsMobile"
                            style={{ color: changeTheme("#000", "#fff") }}>
                            <div
                                className="catalog__container-content-optionsMobile-filters"
                                style={{ backgroundColor: changeTheme("rgba(220, 220, 220, 0.5)", "#2b3239"), cursor: "pointer" }}
                                onClick={(e) => {
                                    if (["catalog__container-content-optionsMobile-filters", "catalog__container-content-optionsMobile-filters-img"].includes(e.target.className)) {
                                        setFiltersMobile(!filtersMobile)
                                    }
                                }}>
                                Filters
                                <img
                                className="catalog__container-content-optionsMobile-filters-img"
                                    src={`./img/sections/catalog/arrow-${theme}.png`}
                                    alt=""
                                    style={{ transform: filtersMobile ? "rotate(180deg)" : "rotate(0deg)" }}
                                />
                                <Filters search example={filter === "nft" ? "nft" : "collection"} />
                            </div>
                            <button
                                className="catalog__container-content-optionsMobile-sort"
                                style={{ backgroundColor: changeTheme("rgba(220, 220, 220, 0.5)", "#2b3239") }}
                                onClick={() => setSortMobile(!sortMobile)}>
                                Sort
                                <img
                                    src={`./img/sections/catalog/arrow-${theme}.png`}
                                    alt=""
                                    style={{ transform: sortMobile ? "rotate(180deg)" : "rotate(0deg)" }}
                                />
                                {sortMobile && (
                                    <ul className="catalog__container-content-options-sorting-popup" style={{backgroundColor: changeTheme("", "#2B3239")}}>
                                        <li
                                            className="catalog__container-content-options-sorting-popup-item"
                                            onClick={e => {
                                                setCurrentSort("Price")
                                                setPriceCurrent(e.target.innerText)
                                            }}>
                                            Low to High
                                        </li>
                                        <li
                                            className="catalog__container-content-options-sorting-popup-item"
                                            onClick={e => {
                                                setCurrentSort("Price")
                                                setPriceCurrent(e.target.innerText)
                                            }}>
                                            High to Low
                                        </li>
                                        <li
                                            className="catalog__container-content-options-sorting-popup-item"
                                            onClick={e => {
                                                setCurrentSort("Sorting");
                                                setSortingCurrent(e.target.innerText)
                                            }}>
                                            Recently created
                                        </li>
                                        <li
                                            className="catalog__container-content-options-sorting-popup-item"
                                            onClick={e => {
                                                setCurrentSort("Sorting")
                                                setSortingCurrent(e.target.innerText)
                                        }}>
                                            Recently sold
                                        </li>
                                        {
                                            filter === "nft" && filterStatus === "In auction" && <li
                                            className="catalog__container-content-options-sorting-popup-item"
                                            onClick={e => {
                                                setCurrentSort("Sorting")
                                                setSortingCurrent(e.target.innerText)
                                        }}>
                                            Ending soon
                                        </li>
                                        }
                                    </ul>
                                )}
                            </button>
                        </div>
                        <div className="catalog__container-content-options">
                            <div className="catalog__container-content-options-categories">
                                <button
                                    onClick={() => {
                                        setFilter("nft");
                                        setCurrentSort("");
                                    }}
                                    className={`catalog__container-content-options-categories-nfts ${filter === "nft"
                                            ? "catalog__container-content-options-categories-nfts--active"
                                            : ""
                                        } ${filter !== "nft"
                                            ? "catalog__container-content-options-categories-nfts--hoverMe"
                                            : ""
                                        }`}
                                    style={{
                                        color: theme === "light" && filter !== "nft" ? "#000" : "#fff",
                                        background: theme === "light" ? "rgba(220, 220, 220, 0.5)" : "#2B3239",
                                    }}>
                                    NFTs
                                </button>
                                <button
                                    onClick={() => {
                                        setFilter("collection");
                                        setCurrentSort("");
                                    }}
                                    className={`catalog__container-content-options-categories-collections ${filter === "collection"
                                            ? "catalog__container-content-options-categories-collections--active"
                                            : ""
                                        } ${filter !== "collection"
                                            ? "catalog__container-content-options-categories-collections--hoverMe"
                                            : ""
                                        }`}
                                    style={{
                                        color: theme === "light" && filter !== "collection" ? "#000" : "#fff",
                                        background: theme === "light" ? "rgba(220, 220, 220, 0.5)" : "#2B3239",
                                    }}>
                                    Collections
                                </button>
                            </div>
                            <div
                                className="catalog__container-content-options-sorting"
                                style={{ color: changeTheme("#000", "#fff") }}>
                                <button
                                    style={{ backgroundColor: changeTheme("rgba(220, 220, 220, 0.5)", "#2b3239") }}
                                    onClick={() => setSortingPopup(!sortingPopup)}>
                                    Sorting: {sortingCurrent}
                                    <img
                                        src={`./img/sections/catalog/arrow-${theme}.png`}
                                        alt=""
                                        style={{ transform: sortingPopup ? "rotate(-180deg)" : "rotate(0deg)" }}
                                    />
                                    {sortingPopup && (
                                        <ul className="catalog__container-content-options-sorting-popup" style={{
                                            backgroundColor: changeTheme("", "#23292F")
                                        }}>
                                            <li
                                                className="catalog__container-content-options-sorting-popup-item"
                                                onClick={e => {
                                                    setSortingCurrent(e.target.innerText)
                                                    setCurrentSort("Sorting");
                                                }}>
                                                Recently created
                                            </li>
                                            <li
                                                className="catalog__container-content-options-sorting-popup-item"
                                                onClick={e => {
                                                    setSortingCurrent(e.target.innerText)
                                                    setCurrentSort("Sorting");
                                                }}>
                                                {filter === "nft" ? "Recently sold" : "Most popular"}
                                            </li>
                                            {
                                                filter === "nft" && filterStatus === "In auction" &&
                                                <li
                                                    className="catalog__container-content-options-sorting-popup-item"
                                                    onClick={e => {
                                                        setSortingCurrent(e.target.innerText)
                                                        setCurrentSort("Sorting");
                                                    }}>
                                                    Ending soon
                                                </li>
                                            }
                                        </ul>
                                    )}
                                </button>
                                <button
                                    style={{ backgroundColor: changeTheme("rgba(220, 220, 220, 0.5)", "#2b3239") }}
                                    onClick={() => setPricePopup(!pricePopup)}>
                                    {filter === "nft" ? "Price" : "Floor price"}: {priceCurrent}
                                    <img
                                        src={`./img/sections/catalog/arrow-${theme}.png`}
                                        alt=""
                                        style={{ transform: pricePopup ? "rotate(-180deg)" : "rotate(0deg)" }}
                                    />
                                    {pricePopup && (
                                        <ul className="catalog__container-content-options-sorting-popup" style={{
                                            backgroundColor: changeTheme("", "#23292F")
                                        }}>
                                            <li
                                                className="catalog__container-content-options-sorting-popup-item"
                                                onClick={e => {
                                                    setPriceCurrent(e.target.innerText)
                                                    setCurrentSort("Price")
                                                }}>
                                                Low to High
                                            </li>
                                            <li
                                                className="catalog__container-content-options-sorting-popup-item"
                                                onClick={e => {
                                                    setPriceCurrent(e.target.innerText)
                                                    setCurrentSort("Price")
                                                }}>
                                                High to Low
                                            </li>
                                        </ul>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="catalog__container-content-items">
                            {
                                offsetItems.length > 0 ? filter === "nft" ? (
                                    offsetItems.map((nft) => <DefaultCard nft={nft} currentUser={currentUser} key={nft?.id} />)
                                        )
                                        : (
                                    offsetItems.map(collection => <CollectionCard collection={collection} currentUser={currentUser} key={collection?.id} />)
                                        )
                                    : (
                                        <div className="myContent__right-items-zero" style={{
                                            margin: "0 auto"
                                        }}>NO ITEMS</div>
                                    )
                            }
                        </div>
                        {((((NFTs && NFTs.length && collections && collections.length) && filter === "nft" ? NFTs.filter(nft => nft.name.toLowerCase().includes(filterSearch.toLowerCase()))
                            .filter(nft =>
                                filterStatus === "all"
                                    ? nft
                                    : filterStatus === "Buy now"
                                        ? nft.state === "sale"
                                        : filterStatus === "In auction"
                                            ? ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))
                                            : (nft.state === "sale" || ((nft.state === "auction") && !(Number(nft?.end_time - Date.now() / 1000) <= 0 && Number(nft?.end_time) > 0))),
                            )
                            .filter(nft =>
                                filterQuantity === "all"
                                    ? nft
                                    : filterQuantity === "single"
                                        ? nft.collection_id == null
                                        : nft.collection_id != null,
                            )
                            .filter(nft =>
                                filterPriceAt !== "" ? Number(nft.price) >= Number(filterPriceAt * (10 ** 9)) : nft,
                            )
                            .filter(nft =>
                                filterPriceTo !== "" ? Number(nft.price) <= Number(filterPriceTo * (10 ** 9)) : nft,
                            )
                            .filter(nft => {
                                if (filterCategory.length === 0) {
                                    return nft
                                } else {
                                    let flag = false;
                                    if (nft?.categories) {
                                        nft.categories.map(cat =>
                                            filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                        );
                                    }
                                    return flag;
                                }
                            })
                            .filter(nft => {
                                if (globalExtraFilters?.length > 0) {
                                    if (nft?.attrs) {
                                        if (1) {
                                            let flag = 0
                                            globalExtraFilters.map(item => {
                                                if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                    if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                        flag = 1
                                                    }
                                                }
                                            })
                                            return flag
                                        } else {
                                            return 0
                                        }
                                    } else {
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            }) : (collections || []).filter(collection =>
                            filterSearch !== ""
                                ? collection.name.toLowerCase().includes(filterSearch.toLowerCase())
                                : collection,
                        )
                            .filter(collection => {
                                if (filterCategory.length === 0) {
                                    return collection
                                } else {
                                    let flag = false;
                                    if (collection?.categories) {
                                        collection.categories.map(cat =>
                                            filterCategory.includes(cat.toLowerCase()) ? (flag = true) : null,
                                        );
                                    }
                                    return flag;
                                }
                            })
                            .filter(nft => {
                                if (globalExtraFilters?.length > 0) {
                                    if (nft?.attrs) {
                                        if (1) {
                                            let flag = 0
                                            globalExtraFilters.map(item => {
                                                if (nft?.attrs?.hasOwnProperty(Object.keys(item)[0])) {
                                                    if (item[Object.keys(item)[0]].toLowerCase() === nft?.attrs[Object.keys(item)[0]].toLowerCase()) {
                                                        flag = 1
                                                    }
                                                }
                                            })
                                            return flag
                                        } else {
                                            return 0
                                        }
                                    } else {
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            })).length > offsetItems.length) && offsetItems.length > 0) && (<div className="catalog__container-content-more">
                            <button
                                onClick={() => setOffset(prevState => prevState + 1)}>
                                Show more
                            </button>
                        </div>)}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Catalog;