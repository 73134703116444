import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import "./CreateNFT.scss";
import Select from "../../components/Select";
import RangeSlider from "../../components/RangeSlider";
import {useCallback, useContext, useEffect, useState} from "react";
import {ContextApp} from "../../Context";
import {useDropzone} from "react-dropzone";
import axios from "axios";
import {toast, Toaster} from "react-hot-toast";
import Checkbox from "../../components/Checkbox";
import {Tooltip} from "react-tooltip";
import StringSimilarity from "string-similarity";

function CreateNFT() {
    const { changeTheme, theme, currentUser, getAllOffsets } = useContext(ContextApp);

    // CREATE SINGLE NFT
    const [filename, setFilename] = useState("");
    const [commonAttrs, setCommonAttrs] = useState([]);
    const [attrs, setAttrs] = useState([{ id: 1, name: "", value: "" }]); // Attributes

    const [nameNFT, setNameNFT] = useState(""); // Display Name
    const [tags, setTags] = useState(["Example"]); // Tags
    const [tagInput, setTagInput] = useState("");
    const [collectionNFT, setCollectionNFT] = useState(""); // Collection Name
    const [categories, setCategories] = useState([]); // Category
    const [description, setDescription] = useState(""); // Description
    const [royalties, setRoyalties] = useState(0); // Royalties
    const [useTonStorage, setUseTonStorage] = useState(false);

    const [previewPath, setPreviewPath] = useState(null);

    const [userCollections, setUserCollections] = useState([]);

    const [configCategories, setConfigCategories] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        setPreviewPath(URL.createObjectURL(acceptedFiles[0]))
    }, [setPreviewPath])

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/webp": [".webp"],
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/svg+xml": [".svg"],
            "image/gif": [".gif"]
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 12582912,
        onDrop
    });

    useEffect(() => {
        axios
            .post(
                "https://nft-one.art/api/config/get",
                {},
                {
                    headers: {
                        Token: localStorage.getItem("tonhubToken")
                            ? localStorage.getItem("tonhubToken")
                            : localStorage.getItem("tonkeeperToken"),
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setConfigCategories(response.data.defaults["nft_categories"].split("\n"));
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (currentUser && currentUser?.id) {
            getAllOffsets("nft_collections", {
                filters: {
                    creator_id: currentUser.id,
                    is_foreign: false
                }
            }).then(res => {
                setUserCollections(res)
            })
        }
    }, [currentUser, getAllOffsets])

    useEffect(() => {
        setFilename(acceptedFiles[0]?.path);
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            toast.error(`Invalid file extension or size`, {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            });
        }
    }, [fileRejections])

    useEffect(() => {
        if (collectionNFT !== "") {
            userCollections.map(usCol => {
                if (usCol?.name === collectionNFT) {
                    if (usCol?.common_attrs) {
                        if (Object.keys(usCol?.common_attrs)?.length > 0 && Object.keys(usCol?.common_attrs)[0] !== "") {
                            let arr = [];
                            Object.keys(usCol?.common_attrs).map((key, index) => arr.push({ id: index + 1, name: key, value: usCol?.common_attrs[key] }))
                            setCommonAttrs([...arr])
                        } else {
                            setCommonAttrs([]);
                        }
                    } else {
                        setCommonAttrs([]);
                    }
                }
            })
        }
    }, [collectionNFT])

    const setAttrsChange = (e, type) => {
        let attrs_copy = [...attrs];
        if (type === "value") {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) == attr.id ? (attr.value = e.target.value) : null,
            );
        } else {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) == attr.id ? (attr.name = e.target.value) : null,
            );
        }
        setAttrs(attrs_copy);
    };

    // Добавляет теги
    function onChangeTagInput(e) {
        let currentValue = e.target.value;
        setTagInput(currentValue);
        if (currentValue[currentValue.length - 1] === " ") {
            setTagInput("");
            setTags([...tags, currentValue.slice(0, currentValue.length - 1)]);
        }
    }

    function deleteTag(e) {
        setTags(tags.filter(tag => e.target.getAttribute("data-tag") !== tag));
    }

    function deleteAttr(id) {
        let attrs_new = [];
        attrs.map(attr => (attr.id == Number(id) ? null : attrs_new.push(attr)));
        setAttrs(attrs_new);
    }

    const getFormatAttrsForBackend = () => {
        if (commonAttrs.length === 0) {
            let new_attrs = {};
            attrs.map(attr => (new_attrs[attr.name] = attr.value));
            return new_attrs;
        } else {
            let new_attrs = {};
            attrs.map(attr => (new_attrs[attr.name] = attr.value));
            commonAttrs.map(attr => (new_attrs[attr.name] = attr.value));
            return new_attrs;
        }
    };

    const changeCategoriesArray = e => {
        let currentCategory = e.target.innerText;
        if (categories.includes(currentCategory)) {
            setCategories(categories.filter(cat => cat !== currentCategory));
        } else {
            setCategories([...categories, currentCategory]);
        }
    };

    const validationEnters = () => {
        let valid_errors = [];

        if (!acceptedFiles[0]) {
            valid_errors.push("Add photo")
        }

        if (nameNFT === "") {
            valid_errors.push("Set a name")
        }

        if (description === "") {
            valid_errors.push("Add a description")
        }

        return valid_errors.length > 0 ? valid_errors : true
    };

    const banCurrentUser = async () => {
        const {data} = await axios.post(
            "https://nft-one.art/api/users/upsert",
            {
                items: [
                    {
                        id: currentUser.id,
                        is_blocked: true
                    }
                ]
            },
            {
                headers: {
                    Token: localStorage.getItem("tonhubToken") || localStorage.getItem("tonkeeperToken"),
                }
            },
        )
        return data
    }

    //console.log(StringSimilarity.compareTwoStrings("Tokyo", "1Tokyo"))
    //console.log(StringSimilarity.compareTwoStrings("Tokyo", "Tokyo_1"))
    //console.log(StringSimilarity.compareTwoStrings("Tokyo_5", "Tokyo_1"))

    const validationSpamBot = () => {
        function getDateDifferenceInMinutes(date1, date2) {
            const differenceInMilliseconds = Math.abs(date1 - date2);
            return Math.ceil(differenceInMilliseconds / (1000 * 60));
        }
        axios.post("https://nft-one.art/api/nfts/list", {
            filters: {
                owner_id: currentUser.id,
                is_foreign: false
            },
            calc_total: true,
            order_by: "id desc"
        }, {
            headers: {
                Token: localStorage.getItem("tonhubToken") || localStorage.getItem("tonkeeperToken"),
            }
        }).then(({data}) => {
            if (data.items.length <= 10) { // тут поправить на 10
                return true
            } else if (Number(data.total) > 100) {
                let tenLastNFTs = [...data.items.slice(0, 10)]
                let rates = []
                tenLastNFTs.map(nft => {
                    rates.push(Number(StringSimilarity.compareTwoStrings(nameNFT, nft.name)))
                })
                if (rates.reduce((acc, curr) => acc + curr, 0) / rates.length > 0.8) {
                    banCurrentUser().then(_ => {
                        setTimeout(() => {
                            window.location.href = "/"
                            return false
                        }, 1200)
                    })
                }
            } else {
                let threeLastNFTs = [...data.items.slice(0, 3)]
                if (((getDateDifferenceInMinutes(new Date(), threeLastNFTs[0].add_time) <= 1) &&
                    (getDateDifferenceInMinutes(threeLastNFTs[0].add_time, threeLastNFTs[1].add_time) <= 1) &&
                    (getDateDifferenceInMinutes(threeLastNFTs[1].add_time, threeLastNFTs[2].add_time) <= 1))) {
                    banCurrentUser().then(_ => {
                        setTimeout(() => {
                            window.location.href = "/"
                            return false
                        }, 1200)
                    })

                }
            }
        })
        return true
    }

    // создает НФТ
    const createNFT = () => {
        const getCollectionIDForName = () => {
            let id = null;
            userCollections.map(collection => collection.name.toLowerCase() === collectionNFT.toLowerCase() ? id = collection.id : null)
            console.log({userCollections})
            return id;
        }
        if (validationEnters() === true) {
            if (validationSpamBot()) {
                const formData = new FormData();
                formData.append(
                    "json_data",
                    JSON.stringify({
                        items: [
                            {
                                img: "file123",
                                name: nameNFT,
                                info: description,
                                collection_id: getCollectionIDForName(),
                                royalty_pct: royalties,
                                attrs: getFormatAttrsForBackend(),
                                state: "new",
                                categories: categories,
                                tags: tags,
                                use_tonstorage: useTonStorage
                            },
                        ],
                    }),
                );
                formData.append("file123", acceptedFiles[0]);
                axios
                    .post("https://nft-one.art/api/nfts/upsert", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Token: localStorage.getItem("tonhubToken")
                                ? localStorage.getItem("tonhubToken")
                                : localStorage.getItem("tonkeeperToken"),
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    })
                    .then(response => {
                        toast.success(`NFT «${nameNFT}» created`, {
                            position: "bottom-right",
                            style: {
                                font: "400 21px/100% 'DM Sans'",
                            },
                        });
                        axios
                            .post("https://nft-one.art/api/nfts/list",
                                {
                                    filters: {
                                        name: nameNFT,
                                        is_foreign: false
                                    }
                                },
                                {
                                    headers: {
                                        Token: localStorage.getItem("tonhubToken") ? localStorage.getItem("tonhubToken") : localStorage.getItem("tonkeeperToken"),
                                    },
                                    auth: {
                                        username: "odmen",
                                        password: "NFTflsy",
                                    },
                                })
                            .then(response => {
                                setTimeout(() => {
                                    window.location.href = `/nft/${response.data.items.reverse()[0].id}`
                                }, 1200)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(error => {
                        toast.error(`Server error: try later`, {
                            position: "bottom-right",
                            style: {
                                font: "400 21px/100% 'DM Sans'",
                            },
                        });
                        console.log(error)
                    });
            }
        } else {
            validationEnters().map(err => {
                toast.error(`${err}`, {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
            })
        }
    };

    const getValuesForCollections = () => {
        let arr = []
        userCollections.map(usCol => {
            if (usCol?.state === "new" || usCol?.state === "active") {
                arr.push(usCol.name)
            }
        })
        return arr.length > 0 ? arr : ["No collection"];
    }

    const setCommonAttrsChange = (e, type) => {
        let attrs_copy = [...commonAttrs];
        if (type === "value") {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) == attr.id ? (attr.value = e.target.value) : null,
            );
        } else {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) == attr.id ? (attr.name = e.target.value) : null,
            );
        }
        setCommonAttrs(attrs_copy);
    }

    return (
        <>
            <Tooltip id="click-me" place="bottom" style={{
                font: "400 12px/120% 'DM Sans'",
                padding: "6px 10px",
                maxWidth: "200px",
                zIndex: 1000
            }} />
            <Header />
            <section
                className={`createNFT ${changeTheme("", "createNFT--dark")}`}
                style={{ backgroundColor: changeTheme("#fff", "#15191E") }}>
                <Toaster />
                <div className="createNFT__left">
                    <div
                        {...getRootProps({ className: "dropzone" })}
                        className="createNFT__left-download"
                        style={{
                            backgroundColor: changeTheme("#f4f6f9", "#1C2026"),
                            borderColor: changeTheme("#efefef", "#1c2026"),
                            color: changeTheme("#000", "#fff"),
                        }}>
                        <img
                            className="createNFT__left-download-img"
                            src={previewPath ? previewPath : "/img/sections/createNFT/drag-and-drop.svg"}
                            alt="Drag and drop NFT"
                        />
                        {filename ? (
                            <>
                                <p className="createNFT__left-download-drag">
                                    {filename.length > 12
                                        ? filename.slice(filename.length - 12, filename.length)
                                        : filename}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="createNFT__left-download-drag">Drag and drop photos here<span style={{ color: "#ff0000" }}>*</span><br /><span style={{
                                    opacity: ".8",
                                    fontSize: "0.82em",
                                    fontWeight: "400"
                                }}>(.png, .jpg, .jpeg, .svg, .webp, .gif maximum size of 12mb)</span></p>
                                <p
                                    className="createNFT__left-download-or"
                                    style={{ color: changeTheme("#000", "rgba(255, 255, 255, 0.7)") }}>
                                    or
                                </p>
                            </>
                        )}
                        <button className="createNFT__left-download-browse">
                            <input {...getInputProps()} type="file" onClick={e => e.stopPropagation()} />
                            Browse Files
                        </button>
                    </div>
                    {
                        commonAttrs.length > 0 && (
                            <div className="createCollection__left-attr createCollection__left-attr--common ">
                                <h6 className="createCollection__left-attr-title">Common attributes<span style={{ color: "red" }}>*</span></h6>
                                <div className="createCollection__left-attr-box">
                                    {window.innerWidth <= 768 ? (
                                        commonAttrs.map((item, index) => (
                                            <>
                                                {/* <div className="createCollection__left-attr-box-name">
                                                    <label>Name</label>
                                                    <input data-attrid={item.id} type="text" placeholder={`${!index ? "Price" : ""}`} value={item.name}
                                                        onChange={e => setCommonAttrsChange(e, "name")}/>
                                                </div>
                                                <div className="createCollection__left-attr-box-value">
                                                    <label>Value</label>
                                                    <div>
                                                        <input type="text" placeholder={`${!index ? "10$" : ""}`} data-attrid={item.id} value={item.value}
                                                            onChange={e => setCommonAttrsChange(e, "value")}/>
                                                    </div>
                                                </div> */}
                                            </>
                                        ))
                                    ) : (
                                        <>
                                            <div className="createCollection__left-attr-box-name">
                                                <label>Name</label>
                                                {commonAttrs.map((item) => (
                                                    <input
                                                        data-attrid={item.id}
                                                        type="text"
                                                        value={item.name}
                                                        onChange={e => setCommonAttrsChange(e, "name")}
                                                        disabled
                                                    />
                                                ))}
                                            </div>
                                            <div className="createCollection__left-attr-box-value">
                                                <label>Value</label>
                                                {
                                                    commonAttrs.map((item) => (
                                                        <div>
                                                            <input
                                                                data-attrid={item.id}
                                                                type="text"
                                                                value={item.value}
                                                                onChange={e => setCommonAttrsChange(e, "value")}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    }
                    <div
                        className="createNFT__left-attr"
                        style={{
                            backgroundColor: changeTheme("#f4f6f9", "#1C2026"),
                            borderColor: changeTheme("#efefef", "#1c2026"),
                            color: changeTheme("#000", "#fff"),
                        }}>
                        <h6 className="createNFT__left-attr-title">Attributes</h6>
                        <div className="createNFT__left-attr-box">
                            {window.innerWidth <= 768 ? (
                                attrs.map((item, index) => (
                                    <>
                                        <div className="createNFT__left-attr-box-name">
                                            <label>Name</label>
                                            <input type="text" placeholder={`${!index ? "Price" : ""}`} />
                                        </div>
                                        <div className="createNFT__left-attr-box-value">
                                            <label>Value</label>
                                            <div>
                                                <input data-attrid={item.id} type="text" placeholder={`${!index ? "10$" : ""}`} value={item.value}
                                                    onChange={e => setAttrsChange(e, "value")} />
                                                {index !== 0 && (
                                                    <div
                                                        data-attrid={item.id}
                                                        className="createNFT__left-attr-box-value-minus"
                                                        onClick={(e) => deleteAttr(e.target.getAttribute("data-attrid"))}></div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <>
                                    <div className="createNFT__left-attr-box-name">
                                        <label>Name</label>
                                        {attrs.map((item, index) => (
                                            <input
                                                data-attrid={item.id}
                                                type="text"
                                                placeholder={`${!index ? "Price" : ""}`}
                                                value={item.name}
                                                onChange={e => setAttrsChange(e, "name")}
                                            />
                                        ))}
                                    </div>
                                    <div className="createNFT__left-attr-box-value">
                                        <label>Value</label>
                                        {attrs.map((item, index) => (
                                            <div>
                                                <input
                                                    data-attrid={item.id}
                                                    type="text"
                                                    placeholder={`${!index ? "10$" : ""}`}
                                                    value={item.value}
                                                    onChange={e => setAttrsChange(e, "value")}
                                                />
                                                {index !== 0 && (
                                                    <div
                                                        data-attrid={item.id}
                                                        className="createNFT__left-attr-box-value-minus"
                                                        onClick={e =>
                                                            deleteAttr(e.target.getAttribute("data-attrid"))
                                                        }></div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        <button
                            className="createNFT__left-attr-add"
                            onClick={() => {
                                setAttrs([...attrs, { id: attrs[attrs.length - 1].id + 1, name: "", value: "" }]);
                            }}>
                            + Add attribute
                        </button>
                    </div>
                </div>
                <div
                    className="createNFT__right"
                    style={{
                        backgroundColor: changeTheme("#f4f6f9", "#1C2026"),
                        borderColor: changeTheme("#efefef", "#1c2026"),
                        color: changeTheme("#000", "#fff"),
                    }}>
                    <h1 className="createNFT__right-title">Create single nft</h1>
                    <form className="createNFT__right-form" action="">
                        <div className="createNFT__right-form-name">
                            <label>
                                Display Name<span>*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="NFT name"
                                value={nameNFT}
                                onChange={e => setNameNFT(e.target.value)}
                            />
                        </div>
                        <div className="createNFT__right-form-tags">
                            <label>Tags</label>
                            <div
                                className="createNFT__right-form-tags-box"
                                style={{
                                    background: changeTheme("#fff", "#1C2026"),
                                    borderColor: changeTheme("", "#596577"),
                                }}>
                                <div className="createNFT__right-form-tags-box-items">
                                    {tags.map((tag, index) => (
                                        <div
                                            key={index}
                                            className="createNFT__right-form-tags-box-items-item"
                                            style={{ backgroundColor: changeTheme("", "rgba(255, 255, 255, 0.13)") }}>
                                            {tag}
                                            <img
                                                data-tag={tag}
                                                src={`./img/sections/createNFT/cross-${theme}.svg`}
                                                alt="Tag"
                                                onClick={e => {
                                                    deleteTag(e);
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <input
                                        id="tags-input"
                                        type="text"
                                        onChange={e => {
                                            onChangeTagInput(e);
                                        }}
                                        value={tagInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="createNFT__right-form-collection">
                            <label>Collection</label>
                            <Select
                                values={getValuesForCollections()}
                                value={collectionNFT}
                                setValue={arg => setCollectionNFT(arg)}
                            />
                        </div>
                        <div className="createNFT__right-form-categories">
                            <label>Categories</label>
                            <div>
                                {configCategories.map(category => (
                                    <Checkbox text={category} onClick={e => changeCategoriesArray(e)} />
                                ))}
                            </div>
                        </div>
                        <div className="createNFT__right-form-description">
                            <label>
                                Description<span>*</span>
                            </label>
                            <textarea value={description} onChange={e => setDescription(e.target.value)}></textarea>
                        </div>
                        {/* <div className="createNFT__right-form-attributes">
                            <div className="createNFT__right-form-attributes-type">
                                <label>
                                    Type<span>*</span>
                                </label>
                                <Select
                                    values={["Auction", "Sale"]}
                                    value={typeNFT}
                                    setValue={arg => setTypeNFT(arg)}
                                />
                            </div>
                            <div className="createNFT__right-form-attributes-price">
                                <label>
                                    {
                                        typeNFT.toLowerCase() === "auction" ? "Start price" : "Price"
                                    }<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    placeholder="TON"
                                    value={priceNFT}
                                    onChange={e => setPriceNFT(e.target.value)}
                                />
                            </div>
                            {
                                typeNFT.toLowerCase() === "auction" && (
                                    <div className="createNFT__right-form-attributes-price">
                                        <label>
                                            Price step<span>*</span>
                                        </label>
                                        <input
                                            placeholder="TON"
                                            type="number"
                                            min="0"
                                            value={priceStep}
                                            onChange={e => setPriceStep(e.target.value)}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        {
                            typeNFT.toLowerCase() === "auction" && (
                                <div className="createNFT__right-form-attributes">
                                    <div className="createNFT__right-form-attributes-price">
                                        <label>
                                            Buy now price
                                        </label>
                                        <input
                                            type="number"
                                            min="0"
                                            value={buyNowPrice}
                                            onChange={e => setBuyNowPrice(e.target.value)}
                                            placeholder="TON"
                                        />
                                    </div>
                                    <div className="createNFT__right-form-attributes-price" style={{
                                        width: "100%",
                                        zIndex: "10000000",
                                        position: "relative"
                                    }}>
                                        <label>
                                            End time
                                        </label>
                                        <ReactDatePicker
                                            minDate={new Date()}
                                            placeholderText="None"
                                            popperPlacement="bottom"
                                            className="datepicker-auction"
                                            selected={endDateAuction}
                                            onChange={date => setEndDateAuction(date)}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </div>
                                </div>
                            )
                        } */}
                        <div className="createNFT__right-form-royalties">
                            <label>Royalties</label>
                            <RangeSlider setValue={val => setRoyalties(val)} />
                        </div>
                        <div className="createNFT__right-form-buttons">
                            <input
                                className="createNFT__right-form-buttons-create"
                                type="button"
                                onClick={createNFT}
                                value="CREATE NFT"
                            />
                            <input
                                className={`createNFT__right-form-buttons-reset ${changeTheme(
                                    "",
                                    "createNFT__right-form-buttons-reset--dark",
                                )}`}
                                type="button"
                                value="RESET"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            />
                        </div>
                        <div
                            className="createNFT__right-form-ts"
                            data-tooltip-id="click-me"
                            data-tooltip-content="TON Storage is a technology for reliable storage of data of any size in a decentralized TON network" style={{
                                marginTop: "40px",
                                display: "inline-block",
                                backgroundColor: "unset"
                            }}>
                            <Checkbox text="Do you want use TON Storage?" onClick={() => setUseTonStorage(!useTonStorage)} />
                        </div>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default CreateNFT;
