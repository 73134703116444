import axios from "axios";

import axs from "../../../../utils/axios";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { ContextApp } from "../../../../Context";
import {Popup} from "./../../../../components/Popup";

function AdminPanelSettings() {
    const [config, setConfig] = useState({});
    const [configFooterSocialLinks, setConfigFooterSocialLinks] = useState("");

    const [showGeneral, setShowGeneral] = useState(false);
    const [showHomepage, setShowHomepage] = useState(false);
    const [showCards, setShowCards] = useState(false);
    const [showPopularAndSeeAlso, setShowPopularAndSeeAlso] = useState(false);
    const [showFooter, setShowFooter] = useState(false);

    const [showItemsPopup, setShowItemsPopup] = useState(false);
    const [showPopularNFTsAndCOllectionsPopup, setShowPopularNFTsAndCOllectionsPopup] = useState(false);
    const [showEmojiPopup, setShowEmojiPopup] = useState(false);
    const [searchQueryPopup, setSearchQueryPopup] = useState("");
    const [searchFilters, setSearchFilters] = useState("nft")

    const [NFTs, setNFTs] = useState([])
    const [collections, setCollections] = useState([])

    const [chooseItem, setChooseItem] = useState("")
    const [chooseEmoji, setChooseEmoji] = useState("")
    const [chooseValue, setChooseValue] = useState("")

    const [emojies, setEmojies] = useState([
        {
            "id": "1",
            "code": "❤️"
          },
          {
            "id": "2",
            "code": "🤣",
          },
          {
            "id": "3",
            "code": "😍",
          },
          {
            "id": "4",
            "code": "😡",
          },
          {
            "id": "5",
            "code": "🙀",
          },
          {
            "id": "6",
            "code": "🥴",
          },
          {
            "id": "7",
            "code": "🤑",
          }
    ])

    const [popularNFTsAndCollections, setPopularNFTsAndCollections] = useState({
        "nfts": [],
        "collections": []
    })

    const { changeTheme, getAllOffsets } = useContext(ContextApp)

    useEffect(() => {
        getAllOffsets("nfts", {filters: {
            is_foreign: false
        },
        subqueries: {
            img: {},
            likes: {},
            creator: {
                subqueries: {
                    img: {},
                },
            },
        }}, "admin").then(res => setNFTs(res))
        getAllOffsets("nft_collections", {filters: {
            is_foreign: false
        },
        subqueries: {
            likes: {},
            img: {},
            creator: {
                subqueries: {
                    img: {},
                },
            },
        },}, "admin").then(res => setCollections(res))
            axios
            .post(
                "https://nft-one.art/api/batch",
                {
                    "cfg": {
                        action: "config/get"
                    },
                    "popular-nfts": {
                        action: "nfts/list",
                        filters: {
                            is_popular: true
                        },
                        subqueries: {
                            img: {},
                            likes: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        }
                    },
                    "popular-collections": {
                        action: "nft_collections/list",
                        filters: {
                            is_popular: true
                        },
                        subqueries: {
                            img: {},
                            likes: {},
                            creator: {
                                subqueries: {
                                    img: {},
                                },
                            },
                        }
                    }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                setConfig(response.data["cfg"]);
                setConfigFooterSocialLinks(response.data["cfg"].values.footer_social_links.split(","));
                setPopularNFTsAndCollections({nfts: response.data["popular-nfts"].items, collections: response.data["popular-collections"].items});
                console.log({nfts: response.data["popular-nfts"].items, collections: response.data["popular-collections"].items});
            })
            .catch(error => {
                console.log(error);
            });
    }, [getAllOffsets]);

    useEffect(() => {
        if(showFooter) {
            document.getElementById("current-footer-text").value = config.values.footer_about;
            document.getElementById("social-vk-input").value =  (configFooterSocialLinks[0] !== "0" ? configFooterSocialLinks[0] : "")
            document.getElementById("social-telegram-input").value =  (configFooterSocialLinks[1] !== "0" ? configFooterSocialLinks[1] : "")
            document.getElementById("social-twitter-input").value =  (configFooterSocialLinks[2] !== "0" ? configFooterSocialLinks[2] : "")
            document.getElementById("social-reddit-input").value =  (configFooterSocialLinks[3] !== "0" ? configFooterSocialLinks[3] : "")
            document.getElementById("social-discord-input").value =  (configFooterSocialLinks[4] !== "0" ? configFooterSocialLinks[4] : "")
        }
        if(showCards) {
            const getCurrentCategoriesString = () => {
                let str = "";
                config.values.nft_categories.split("\n").map((item, index) => index !== str.length - 1 && index !== 0 ? str += `, ${item}` : str += item)
                return str;
            }
            document.getElementById("current-categories").value = getCurrentCategoriesString();
        }
        if(showGeneral) {
            document.getElementById("current-commission").value = config.values.fee_percent + "%";
        }
        if(showHomepage) {
            document.getElementById("current-circle-text").value = config.values.index_circle_text;
            document.getElementById("current-three-nfts").value = config.values.index_nfts;
            document.getElementById("current-homepage-title").value = config.values.index_center_title;
            document.getElementById("current-homepage-text").value = config.values.index_center_text;
        }
        if(showPopularAndSeeAlso) {
            document.getElementById("cur-1").value = config.values.see_also_same_collection;
            document.getElementById("cur-2").value = config.values.see_also_same_author;
            document.getElementById("cur-3").value = config.values.see_also_same_price;
            document.getElementById("cur-4").value = config.values.see_also_price_range_percent;
        }
    }, [showCards, showFooter, showGeneral, showHomepage, showPopularAndSeeAlso, config])

    const saveCurrentNFTCategories = () => {
        const getAnonLikes = () => {
            if (!chooseItem?.anon_likes) {
                return { [chooseEmoji?.id]: Number(chooseValue) }
            } else {
                if (!chooseItem.anon_likes.hasOwnProperty(chooseEmoji.id)) {
                    console.log('Новый тип лайка');
                    return {...(chooseItem.anon_likes), [chooseEmoji.id]: Number(chooseValue)}
                } else {
                    let obj = { ...(chooseItem.anon_likes) }
                    obj[chooseEmoji.id] += Number(chooseValue)
                    return obj
                }
            }
        }

        // Update likes
        if (chooseItem !== "" && chooseEmoji !== "" && chooseValue !== "") {
            axios
                .post(
                    `https://nft-one.art/api/${chooseItem?.price ? "nfts" : "nft_collections"}/upsert`,
                    {
                        items: [
                            {
                                id: chooseItem?.id,
                                anon_likes: getAnonLikes()
                            }
                        ]
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("adminToken")
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => {
                    toast.success("Saved", {
                        position: "bottom-right",
                        style: {
                            font: "400 21px/100% 'DM Sans'",
                        },
                    });
                    setChooseItem("")
                    setChooseEmoji("")
                    setChooseValue("")
                    // axios
                    //     .post(
                    //         "https://nft-one.art/api/config/get",
                    //         {},
                    //         {
                    //             headers: {
                    //                 Token: localStorage.getItem("adminToken")
                    //             },
                    //             auth: {
                    //                 username: "odmen",
                    //                 password: "NFTflsy",
                    //             },
                    //         },
                    //     )
                    //     .then(response => {
                    //         setConfig(response.data);
                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });
                })
                .catch(error => {
                    toast.error("Server error: try later", {
                        position: "bottom-right",
                        style: {
                            font: "400 21px/100% 'DM Sans'",
                        },
                    });
                    console.log(error);
                });
        }

        // Update nft_categories in config
        axios
            .post(
                "https://nft-one.art/api/config/update",
                {
                    "values": {
                        "nft_categories": document.getElementById("current-categories").value.split(",").map(item => item.trim()).join("\n")
                    }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                toast.success("Saved", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                axios
                    .post(
                        "https://nft-one.art/api/config/get",
                        {},
                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setConfig(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                toast.error("Server error: try later", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                console.log(error);
            });
    }

    const saveCurrentFooterText = () => {
        axios
            .post(
                "https://nft-one.art/api/config/update",
                {
                    "values": {
                        "footer_about": document.getElementById("current-footer-text").value
                    }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                toast.success("Saved", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                axios
                    .post(
                        "https://nft-one.art/api/config/get",
                        {},
                        
                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setConfig(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                toast.error("Server error: try later", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                console.log(error);
            });
    }

    const saveCurrentFooterSocialLinks = () => {
        const getFooterSocialLinks = () => {
            let arr = [document.getElementById("social-vk-input").value !== "" ? document.getElementById("social-vk-input").value : "0",
            document.getElementById("social-telegram-input").value !== "" ? document.getElementById("social-telegram-input").value : "0",
            document.getElementById("social-twitter-input").value !== "" ? document.getElementById("social-twitter-input").value : "0",
            document.getElementById("social-reddit-input").value !== "" ? document.getElementById("social-reddit-input").value : "0",
            document.getElementById("social-discord-input").value !== "" ? document.getElementById("social-discord-input").value : "0"]
            return arr.join(",")
        }
        axios
            .post(
                "https://nft-one.art/api/config/update",
                {
                    "values": {
                        "footer_social_links": getFooterSocialLinks()
                    }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                toast.success("Saved", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                axios
                    .post(
                        "https://nft-one.art/api/config/get",
                        {},
                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setConfig(response.data);
                        setConfigFooterSocialLinks(response.data.values.footer_social_links.split(","));
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                toast.error("Server error: try later", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                console.log(error);
            });
    }

    const saveCurrentGeneralCommission = () => {
        axios
            .post(
                "https://nft-one.art/api/config/update",
                {
                    "values": {
                        "fee_percent": document.getElementById("current-commission").value.slice(0, -1)
                    }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                toast.success("Saved", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                axios
                    .post(
                        "https://nft-one.art/api/config/get",
                        {},
                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setConfig(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                toast.error("Server error: try later", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                console.log(error);
            });
    }

    const saveHomepage = () => {
        axios
            .post(
                "https://nft-one.art/api/config/update",
                {
                    "values": {
                        "index_circle_text": document.getElementById("current-circle-text").value,
                        "index_nfts": document.getElementById("current-three-nfts").value,
                        "index_center_title": document.getElementById("current-homepage-title").value,
                        "index_center_text": document.getElementById("current-homepage-text").value
                    }
                },
                {
                    headers: {
                        Token: localStorage.getItem("adminToken")
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                },
            )
            .then(response => {
                toast.success("Saved", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                axios
                    .post(
                        "https://nft-one.art/api/config/get",
                        {},
                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setConfig(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                toast.error("Server error: try later", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                console.log(error);
            });
    }

    const savePopularAndSeeAlso = () => {
        axs
            .post(
                "https://nft-one.art/api/config/update",
                {
                    "values": {
                        "see_also_same_collection": document.getElementById("cur-1").value,
                        "see_also_same_author": document.getElementById("cur-2").value,
                        "see_also_same_price": document.getElementById("cur-3").value,
                        "see_also_price_range_percent": document.getElementById("cur-4").value
                    }
                }
            )
            .then(response => {
                toast.success("Saved", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                axios
                    .post(
                        "https://nft-one.art/api/config/get",
                        {},

                        {
                            headers: {
                                Token: localStorage.getItem("adminToken")
                            },
                            auth: {
                                username: "odmen",
                                password: "NFTflsy",
                            },
                        },
                    )
                    .then(response => {
                        setConfig(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                toast.error("Server error: try later", {
                    position: "bottom-right",
                    style: {
                        font: "400 21px/100% 'DM Sans'",
                    },
                });
                console.log(error);
            });
    }

    const getChooseValuePlaceholder = () => {
        if (chooseItem !== "" && chooseEmoji !== "") {
            if (chooseItem?.likes?.length > 0 || chooseItem?.anon_likes) {
                let i = 0
                if (chooseItem?.likes?.length > 0) {
                    chooseItem?.likes.map(like => {
                        if (like?.type_id === chooseEmoji?.id) {
                            i += 1
                        }
                    })
                }
                if (chooseItem?.anon_likes) {
                    i += chooseItem.anon_likes[chooseEmoji.id]
                }
                return `Old is ${i}`
            } else {
                return "Old is 0"
            }
        } 
        return "Number"
    }

    const deletePopularNFTsAndCollections = (type_item, id_item) => {
        axs.post(`${type_item}/upsert`,{
            items: [
                {
                    id: id_item,
                    is_popular: false
                }
            ]
        }).then(({data}) => {
            toast.success("Updated list", {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            })
            axs
                .post(
                    "batch",
                    {
                        "popular-nfts": {
                            action: "nfts/list",
                            filters: {
                                is_popular: true
                            },
                            subqueries: {
                                img: {},
                                likes: {},
                                creator: {
                                    subqueries: {
                                        img: {},
                                    },
                                },
                            }
                        },
                        "popular-collections": {
                            action: "nft_collections/list",
                            filters: {
                                is_popular: true
                            },
                            subqueries: {
                                img: {},
                                likes: {},
                                creator: {
                                    subqueries: {
                                        img: {},
                                    },
                                },
                            }
                        }
                    },
                    {
                        headers: {
                            Token: localStorage.getItem("adminToken")
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    },
                )
                .then(response => setPopularNFTsAndCollections({nfts: response.data["popular-nfts"].items, collections: response.data["popular-collections"].items}))
                .catch(err => console.log(err))
        }).catch(err => {
            toast.error("Server error: try later", {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            })
            console.log(err)
        })
    }

    return (
        <>
            <Toaster/>
            <div className="adminPanelSettings">
                {/* General */}
                <div className="adminPanelSettings__cards" style={{
                    backgroundColor: changeTheme("", "#1C2026"),
                    color: changeTheme("", "#fff")
                }}>
                    <div className="adminPanelSettings__element-title">
                        <h6>General</h6>
                        <button className="adminPanel__content-table-header-show" onClick={() => setShowGeneral(!showGeneral)}>
                            {showGeneral ? "Hide" : "Show"}
                            <img
                                className="adminPanel__content-table-header-show-img"
                                src="/img/adminPanel/arrow.svg"
                                alt=""
                                style={{ transform: showGeneral ? "rotate(180deg)" : "rotate(0deg)" }}
                            />
                        </button>
                    </div>
                    {
                        showGeneral && (
                            <div className="adminPanelSettings__footer-currentText">
                                <label>Current marketplace commission <span style={{color: "orange"}}>(only integer)</span>:</label>
                                <input id="current-commission" style={{
                                    backgroundColor: changeTheme("", "#1C2026"),
                                    borderColor: changeTheme("", "rgb(55, 63, 74)")
                                }} />
                                <div>
                                    <div className="adminPanelSettings__footer-currentText-btns">
                                        <button className="adminPanelSettings__footer-currentText-btns-save" onClick={() => saveCurrentGeneralCommission()}>Save</button>
                                        <button className="adminPanelSettings__footer-currentText-btns-reset" onClick={() => document.getElementById("current-commission").value = config.values.fee_percent + "%"}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {/* Homepage */}
                <div className="adminPanelSettings__cards" style={{
                    backgroundColor: changeTheme("", "#1C2026"),
                    color: changeTheme("", "#fff")
                }}>
                    <div className="adminPanelSettings__element-title">
                        <h6>Homepage</h6>
                        <button className="adminPanel__content-table-header-show" onClick={() => setShowHomepage(!showHomepage)}>
                            {showHomepage ? "Hide" : "Show"}
                            <img
                                className="adminPanel__content-table-header-show-img"
                                src="/img/adminPanel/arrow.svg"
                                alt=""
                                style={{ transform: showHomepage ? "rotate(180deg)" : "rotate(0deg)" }}
                            />
                        </button>
                    </div>
                    {
                        showHomepage && (
                            <>
                                <div className="adminPanelSettings__footer-currentText">
                                    <label>Text for circle:</label>
                                    <input id="current-circle-text" style={{
                                        backgroundColor: changeTheme("", "#1C2026"),
                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                    }} />
                                </div>
                                <div className="adminPanelSettings__footer-currentText">
                                    <label>Three NFTs on the main <span style={{ color: "orange" }}>(ID separated by commas)</span>:</label>
                                    <input id="current-three-nfts" style={{
                                        backgroundColor: changeTheme("", "#1C2026"),
                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                    }} />
                                </div>
                                <div className="adminPanelSettings__footer-currentText">
                                    <label>Homepage title <span style={{ color: "orange" }}>(16-40 characters are recommended)</span>:</label>
                                    <input id="current-homepage-title" style={{
                                        backgroundColor: changeTheme("", "#1C2026"),
                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                    }} />
                                </div>
                                <div className="adminPanelSettings__footer-currentText">
                                    <label>Homepage text <span style={{ color: "orange" }}>(80-120 characters are recommended)</span>:</label>
                                    <input id="current-homepage-text" style={{
                                        backgroundColor: changeTheme("", "#1C2026"),
                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                    }} />
                                    <div>
                                        <div className="adminPanelSettings__footer-currentText-btns">
                                            <button className="adminPanelSettings__footer-currentText-btns-save" onClick={() => saveHomepage()}>Save</button>
                                            <button className="adminPanelSettings__footer-currentText-btns-reset" onClick={() => {
                                                document.getElementById("current-three-nfts").value = config.values.index_nfts
                                                document.getElementById("current-circle-text").value = config.values.index_circle_text
                                                document.getElementById("current-homepage-title").value = config.values.index_center_title;
                                                document.getElementById("current-homepage-text").value = config.values.index_center_text;
                                            }}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                {/* NFTs and collections */}
                <div className="adminPanelSettings__cards" style={{
                    backgroundColor: changeTheme("", "#1C2026"),
                    color: changeTheme("", "#fff")
                }}>
                    <div className="adminPanelSettings__element-title">
                        <h6>NFTs and collections</h6>
                        <button className="adminPanel__content-table-header-show" onClick={() => setShowCards(!showCards)}>
                            {showCards ? "Hide" : "Show"}
                            <img
                                className="adminPanel__content-table-header-show-img"
                                src="/img/adminPanel/arrow.svg"
                                alt=""
                                style={{ transform: showCards ? "rotate(180deg)" : "rotate(0deg)" }}
                            />
                        </button>
                    </div>
                    {
                        showCards && (
                            <>
                                <div className="adminPanelSettings__footer-currentText">
                                    <label>Change likes:</label>
                                    {/* выбирает нфт или коллекцию из попапа */}
                                    <div className="adminPanelSettings__changeLikes-item">
                                        <p className="adminPanelSettings__changeLikes-item-title">Choose an item:</p>
                                        { chooseItem?.id > 0 ? (
                                        <div className="adminPanelSettings__changeLikes-item-nftOrCollection" onClick={() => setShowItemsPopup(true)}>
                                            <div className="adminPanelSettings__changeLikes-item-nftOrCollection-img" style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${chooseItem?.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                            <p className="adminPanelSettings__changeLikes-item-nftOrCollection-name">{chooseItem?.name}</p>
                                        </div>
                                        ) : <div className="adminPanelSettings__changeLikes-item-choose" onClick={() => setShowItemsPopup(true)}>Choose</div>
                                        }
                                    </div>
                                    {/* выбирает то, какой тип лайка поменять */}
                                    <div className="adminPanelSettings__changeLikes-item">
                                        <p className="adminPanelSettings__changeLikes-item-title">Choose a reaction:</p>
                                        {
                                            chooseEmoji === "" ?
                                            <div className="adminPanelSettings__changeLikes-item-choose" onClick={() => setShowEmojiPopup(true)}>Choose</div> : (
                                                <div className="adminPanelSettings__changeLikes-item-reaction" onClick={() => setShowEmojiPopup(true)}>
                                                    { chooseEmoji.code }
                                                </div>
                                            )
                                        }
                                    </div>
                                    {/* выбирает какое значение поставить */}
                                    <div className="adminPanelSettings__changeLikes-item">
                                        <p className="adminPanelSettings__changeLikes-item-title">Set a new value:</p>
                                        <input value={chooseValue} onChange={(e) => setChooseValue(e.target.value)} className="adminPanelSettings__changeLikes-item-reactionValue" type="number"
                                        placeholder={getChooseValuePlaceholder()}
                                        />
                                    </div>
                                </div>
                                <div className="adminPanelSettings__footer-currentText">
                                    <label>Current categories:</label>
                                    <input id="current-categories" style={{
                                        backgroundColor: changeTheme("", "#1C2026"),
                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                    }} />
                                    <div>
                                        <div className="adminPanelSettings__footer-currentText-btns">
                                            <button className="adminPanelSettings__footer-currentText-btns-save" onClick={() => saveCurrentNFTCategories()}>Save</button>
                                            <button className="adminPanelSettings__footer-currentText-btns-reset" onClick={() => {
                                                const getCurrentCategoriesString = () => {
                                                    let str = "";
                                                    config.values.nft_categories.split("\n").map((item, index) => index !== str.length - 1 && index !== 0 ? str += `, ${item}` : str += item)
                                                    return str;
                                                }
                                                document.getElementById("current-categories").value = getCurrentCategoriesString();
                                                setChooseItem("")
                                                setChooseEmoji("")
                                                setChooseValue("")
                                            }}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                {/* "Popular" and "See also" */}
                <div className="adminPanelSettings__cards" style={{
                    backgroundColor: changeTheme("", "#1C2026"),
                    color: changeTheme("", "#fff")
                }}>
                    <div className="adminPanelSettings__element-title">
                        <h6>"Popular" and "See also"</h6>
                        <button className="adminPanel__content-table-header-show" onClick={() => setShowPopularAndSeeAlso(!showPopularAndSeeAlso)}>
                            {showPopularAndSeeAlso ? "Hide" : "Show"}
                            <img
                                className="adminPanel__content-table-header-show-img"
                                src="/img/adminPanel/arrow.svg"
                                alt=""
                                style={{ transform: showPopularAndSeeAlso ? "rotate(180deg)" : "rotate(0deg)" }}
                            />
                        </button>
                    </div>
                    {
                        showPopularAndSeeAlso && (
                            <div className="adminPanelSettings__popularAndSeeAlso">
                                <div className="adminPanelSettings__popularAndSeeAlso-content">
                                    <div className="adminPanelSettings__popularAndSeeAlso-content-blocks">
                                        <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block">
                                            <label>Popular NFTs and collections:</label>
                                            <ul className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-list">
                                                {
                                                    popularNFTsAndCollections.nfts.map(item => (
                                                        <li>
                                                            <div className="adminPanelSettings__changeLikes-item-nftOrCollection" onClick={() => window.open(`/nft/${item.id}`, '_blank')}>
                                                                <div className="adminPanelSettings__changeLikes-item-nftOrCollection-img" style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${item?.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                                                <p className="adminPanelSettings__changeLikes-item-nftOrCollection-name">{item.name} <span style={{color: "YellowGreen"}}>[NFT]</span></p>
                                                            </div>
                                                            <img onClick={() => deletePopularNFTsAndCollections("nfts", item?.id)} src="/img/adminPanel/delete.svg" alt="Delete item"/>
                                                        </li>
                                                    ))
                                                }
                                                {
                                                    popularNFTsAndCollections.collections.map(item => (
                                                        <li>
                                                            <div className="adminPanelSettings__changeLikes-item-nftOrCollection" onClick={() => window.open(`/collection/${item.id}`, '_blank')}>
                                                                <div className="adminPanelSettings__changeLikes-item-nftOrCollection-img" style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${item?.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                                                <p className="adminPanelSettings__changeLikes-item-nftOrCollection-name">{item.name} <span style={{color: "#7f81cd"}}>[COLLECTION]</span></p>
                                                            </div>
                                                            <img onClick={() => deletePopularNFTsAndCollections('nft_collections', item?.id)} src="/img/adminPanel/delete.svg" alt="Delete item"/>
                                                        </li>
                                                    ))
                                                }
                                                <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-list-add" onClick={() => setShowPopularNFTsAndCOllectionsPopup(true)}>+ Add</div>
                                            </ul>
                                        </div>
                                        <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block">
                                            <label>See also NFTs and collections:</label>
                                            <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-items">
                                                <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-items-item">
                                                    <label>NFT from this collection:</label>
                                                    <input type={"number"} id="cur-1" style={{
                                                        backgroundColor: changeTheme("", "#1C2026"),
                                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                    }} />
                                                </div>
                                                <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-items-item">
                                                    <label>NFT from this author:</label>
                                                    <input type={"number"} id="cur-2" style={{
                                                        backgroundColor: changeTheme("", "#1C2026"),
                                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                    }} />
                                                </div>
                                                <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-items-item">
                                                    <label>NFT from other authors:</label>
                                                    <input type={"number"} id="cur-3" style={{
                                                        backgroundColor: changeTheme("", "#1C2026"),
                                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                    }} />
                                                </div>
                                                <div className="adminPanelSettings__popularAndSeeAlso-content-blocks-block-items-item">
                                                    <label>Price range (as a percentage):</label>
                                                    <input type={"number"} id="cur-4" style={{
                                                        backgroundColor: changeTheme("", "#1C2026"),
                                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adminPanelSettings__footer-currentText-btns">
                                        <button className="adminPanelSettings__footer-currentText-btns-save" onClick={savePopularAndSeeAlso}>Save</button>
                                        <button className="adminPanelSettings__footer-currentText-btns-reset" onClick={() => {
                                            document.getElementById("cur-1").value = config.values.see_also_same_collection
                                            document.getElementById("cur-2").value = config.values.see_also_same_author
                                            document.getElementById("cur-3").value = config.values.see_also_same_price
                                            document.getElementById("cur-4").value = config.values.see_also_price_range_percent
                                        }}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {/* Footer */}
                <div className="adminPanelSettings__footer" style={{
                    backgroundColor: changeTheme("", "#1C2026"),
                    color: changeTheme("", "#fff")
                }}>
                    <div className="adminPanelSettings__element-title">
                        <h6>Footer</h6>
                        <button className="adminPanel__content-table-header-show" onClick={() => setShowFooter(!showFooter)}>
                            {showFooter ? "Hide" : "Show"}
                            <img
                                className="adminPanel__content-table-header-show-img"
                                src="/img/adminPanel/arrow.svg"
                                alt=""
                                style={{ transform: showFooter ? "rotate(180deg)" : "rotate(0deg)" }}
                            />
                        </button>
                    </div>
                    {
                        showFooter && (
                            <>
                                <div class="adminPanelSettings__footer-currentText">
                                    <label>Current footer text:</label>
                                    <textarea id="current-footer-text" style={{
                                        backgroundColor: changeTheme("", "#1C2026"),
                                        borderColor: changeTheme("", "rgb(55, 63, 74)")
                                    }}></textarea>
                                    <div className="adminPanelSettings__footer-currentText-btns">
                                        <button className="adminPanelSettings__footer-currentText-btns-save" onClick={() => saveCurrentFooterText()}>Save</button>
                                        <button className="adminPanelSettings__footer-currentText-btns-reset" onClick={() => document.getElementById("current-footer-text").value = config.values.footer_about}>Reset</button>
                                    </div>
                                </div>
                                <div className="editProfile__box-social">
                                    <label>Social links</label>
                                    <ul>
                                        <li>
                                            <input
                                                style={{
                                                    backgroundColor: changeTheme("", "#1C2026"),
                                                    borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                }}
                                                id="social-vk-input"
                                                type="text"
                                                placeholder="account-id"
                                                // value={socialForm.vk}
                                                data-social="vk"
                                            // onChange={e => setSocialFormChange(e)}
                                            />
                                        </li>
                                        <li>
                                            <input
                                                style={{
                                                    backgroundColor: changeTheme("", "#1C2026"),
                                                    borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                }}
                                                id="social-telegram-input"
                                                type="text"
                                                placeholder="nickname"
                                                // value={socialForm.telegram}
                                                data-social="telegram"
                                            // onChange={e => setSocialFormChange(e)}
                                            />
                                        </li>
                                        <li>
                                            <input
                                                style={{
                                                    backgroundColor: changeTheme("", "#1C2026"),
                                                    borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                }}
                                                id="social-twitter-input"
                                                type="text"
                                                placeholder="nickname"
                                                // value={socialForm.twitter}
                                                data-social="twitter"
                                            // onChange={e => setSocialFormChange(e)}
                                            />
                                        </li>
                                        <li>
                                            <input
                                                style={{
                                                    backgroundColor: changeTheme("", "#1C2026"),
                                                    borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                }}
                                                id="social-reddit-input"
                                                type="text"
                                                placeholder="nickname"
                                                // value={socialForm.reddit}
                                                data-social="reddit"
                                            // onChange={e => setSocialFormChange(e)}
                                            />
                                        </li>
                                        <li>
                                            <input
                                                style={{
                                                    backgroundColor: changeTheme("", "#1C2026"),
                                                    borderColor: changeTheme("", "rgb(55, 63, 74)")
                                                }}
                                                id="social-discord-input"
                                                type="text"
                                                placeholder="server-id"
                                                // value={socialForm.discord}
                                                data-social="discord"
                                            // onChange={e => setSocialFormChange(e)}
                                            />
                                        </li>
                                    </ul>
                                    <div className="adminPanelSettings__footer-currentText-btns">
                                        <button className="adminPanelSettings__footer-currentText-btns-save" onClick={() => saveCurrentFooterSocialLinks()}>Save</button>
                                        <button className="adminPanelSettings__footer-currentText-btns-reset" onClick={() => {
                                            document.getElementById("social-vk-input").value = (configFooterSocialLinks[0] !== "0" ? configFooterSocialLinks[0] : "")
                                            document.getElementById("social-telegram-input").value = (configFooterSocialLinks[1] !== "0" ? configFooterSocialLinks[1] : "")
                                            document.getElementById("social-twitter-input").value = (configFooterSocialLinks[2] !== "0" ? configFooterSocialLinks[2] : "")
                                            document.getElementById("social-reddit-input").value = (configFooterSocialLinks[3] !== "0" ? configFooterSocialLinks[3] : "")
                                            document.getElementById("social-discord-input").value = (configFooterSocialLinks[4] !== "0" ? configFooterSocialLinks[4] : "")
                                        }}>Reset</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <Popup show={showItemsPopup} onShow={arg => setShowItemsPopup(arg)} extraClass="nftAvatar">
                <Toaster />
                <div className="header__search">
                    <input
                        value={searchQueryPopup}
                        onChange={e => setSearchQueryPopup(e.target.value)}
                        type="text"
                        placeholder="Search"
                        style={{
                            backgroundColor: changeTheme("#fff", "#272E37"),
                            borderColor: changeTheme("#efefef", "#373F4A"),
                            color: changeTheme("", "#fff")
                        }}
                    />
                    <img
                        src={`/img/header/${searchQueryPopup !== "" ? "clear" : "search"}.svg`}
                        alt=""
                        onClick={() => setSearchQueryPopup("")}
                    />
                    <div className="header__search-popup" style={{ position: "unset" }}>
                        <div className="header__search-popup-btns">
                            <button
                                onClick={() => {
                                    setSearchFilters("nft");
                                }}
                                className={`header__search-popup-btns-nfts ${searchFilters === "nft" ? "header__search-popup-btns-nfts--active" : ""
                                    }`}>
                                NFTs
                            </button>
                            <button
                                onClick={() => setSearchFilters("collection")}
                                className={`header__search-popup-btns-collections ${searchFilters === "collection" ? "header__search-popup-btns-collections--active" : ""
                                    }`}>
                                Collections
                            </button>
                        </div>
                        <ul className="header__search-popup-list">
                            {searchFilters === "nft"
                                ? NFTs.filter(
                                    nft =>
                                        nft.name.toLowerCase().includes(searchQueryPopup.toLowerCase())
                                ).length > 0 ? NFTs.filter(
                                    nft =>
                                        nft.name.toLowerCase().includes(searchQueryPopup.toLowerCase())
                                ).map(nft => (
                                    <li className="header__search-popup-list-item" onClick={() => {
                                        setChooseItem(nft)
                                        setShowItemsPopup(false)
                                    }}>
                                        <div style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${nft.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                        <p>{nft?.name?.length > 15 ? nft?.name.slice(0, 12) + "..." : nft?.name}</p>
                                    </li>
                                )) : "No items"
                                : collections.filter(collection => collection.name.toLowerCase().includes(searchQueryPopup.toLowerCase())).length > 0 ? collections.filter(
                                    collection =>
                                        collection.name.toLowerCase().includes(searchQueryPopup.toLowerCase())
                                ).map(collection => (
                                    <li className="header__search-popup-list-item" onClick={() => {
                                        setChooseItem(collection)
                                        setShowItemsPopup(false)
                                    }}>
                                        <div style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${collection.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                        <p>{collection?.name?.length > 15 ? collection?.name.slice(0, 12) + "..." : collection?.name}</p>
                                    </li>
                                )) : "No items"}
                        </ul>
                    </div>
                </div>
            </Popup>
            <Popup show={showPopularNFTsAndCOllectionsPopup} onShow={arg => setShowPopularNFTsAndCOllectionsPopup(arg)} extraClass="nftAvatar">
                <div className="header__search">
                    <input
                        value={searchQueryPopup}
                        onChange={e => setSearchQueryPopup(e.target.value)}
                        type="text"
                        placeholder="Search"
                        style={{
                            backgroundColor: changeTheme("#fff", "#272E37"),
                            borderColor: changeTheme("#efefef", "#373F4A"),
                            color: changeTheme("", "#fff")
                        }}
                    />
                    <img
                        src={`/img/header/${searchQueryPopup !== "" ? "clear" : "search"}.svg`}
                        alt=""
                        onClick={() => setSearchQueryPopup("")}
                    />
                    <div className="header__search-popup" style={{ position: "unset" }}>
                        <div className="header__search-popup-btns">
                            <button
                                onClick={() => {
                                    setSearchFilters("nft");
                                }}
                                className={`header__search-popup-btns-nfts ${searchFilters === "nft" ? "header__search-popup-btns-nfts--active" : ""
                                }`}>
                                NFTs
                            </button>
                            <button
                                onClick={() => setSearchFilters("collection")}
                                className={`header__search-popup-btns-collections ${searchFilters === "collection" ? "header__search-popup-btns-collections--active" : ""
                                }`}>
                                Collections
                            </button>
                        </div>
                        <ul className="header__search-popup-list">
                            {searchFilters === "nft"
                                ? NFTs.filter(nft => !nft?.is_popular && !nft?.is_hidden).filter(
                                    nft =>
                                        nft.name.toLowerCase().includes(searchQueryPopup.toLowerCase())
                                ).length > 0 ? NFTs.filter(nft => !nft?.is_popular && !nft?.is_hidden).filter(
                                    nft =>
                                        nft.name.toLowerCase().includes(searchQueryPopup.toLowerCase())
                                ).map(nft => (
                                    <li className="header__search-popup-list-item" onClick={() => {
                                        axs.post("nfts/upsert",{
                                            items: [
                                                {
                                                    id: nft?.id,
                                                    is_popular: true
                                                }
                                            ]
                                        }).then(({data}) => {
                                            toast.success("Updated list", {
                                                position: "bottom-right",
                                                style: {
                                                    font: "400 21px/100% 'DM Sans'",
                                                },
                                            })
                                            axs
                                                .post(
                                                    "batch",
                                                    {
                                                        "popular-nfts": {
                                                            action: "nfts/list",
                                                            filters: {
                                                                is_popular: true
                                                            },
                                                            subqueries: {
                                                                img: {},
                                                                likes: {},
                                                                creator: {
                                                                    subqueries: {
                                                                        img: {},
                                                                    },
                                                                },
                                                            }
                                                        },
                                                        "popular-collections": {
                                                            action: "nft_collections/list",
                                                            filters: {
                                                                is_popular: true
                                                            },
                                                            subqueries: {
                                                                img: {},
                                                                likes: {},
                                                                creator: {
                                                                    subqueries: {
                                                                        img: {},
                                                                    },
                                                                },
                                                            }
                                                        }
                                                    },
                                                    {
                                                        headers: {
                                                            Token: localStorage.getItem("adminToken")
                                                        },
                                                        auth: {
                                                            username: "odmen",
                                                            password: "NFTflsy",
                                                        },
                                                    },
                                                )
                                                .then(response => setPopularNFTsAndCollections({nfts: response.data["popular-nfts"].items, collections: response.data["popular-collections"].items}))
                                                .catch(err => console.log(err))
                                        }).catch(err => {
                                            toast.error("Server error: try later", {
                                                position: "bottom-right",
                                                style: {
                                                    font: "400 21px/100% 'DM Sans'",
                                                },
                                            })
                                            console.log(err)
                                        }).finally(() => setShowPopularNFTsAndCOllectionsPopup(false))
                                    }}>
                                        <div style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${nft.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                        <p>{nft?.name?.length > 15 ? nft?.name.slice(0, 12) + "..." : nft?.name}</p>
                                    </li>
                                )) : "No items"
                                : collections.filter(collection => !collection?.is_popular && !collection?.is_hidden).filter(collection => collection.name.toLowerCase().includes(searchQueryPopup.toLowerCase())).length > 0 ? collections.filter(collection => !collection?.is_popular && !collection?.is_hidden).filter(
                                    collection =>
                                        collection.name.toLowerCase().includes(searchQueryPopup.toLowerCase())
                                ).map(collection => (
                                    <li className="header__search-popup-list-item" onClick={() => {
                                        axs.post("nft_collections/upsert",{
                                            items: [
                                                {
                                                    id: collection?.id,
                                                    is_popular: true
                                                }
                                            ]
                                        }).then(({data}) => {
                                            toast.success("Updated list", {
                                                position: "bottom-right",
                                                style: {
                                                    font: "400 21px/100% 'DM Sans'",
                                                },
                                            })
                                            axs
                                                .post(
                                                    "batch",
                                                    {
                                                        "popular-nfts": {
                                                            action: "nfts/list",
                                                            filters: {
                                                                is_popular: true
                                                            },
                                                            subqueries: {
                                                                img: {},
                                                                likes: {},
                                                                creator: {
                                                                    subqueries: {
                                                                        img: {},
                                                                    },
                                                                },
                                                            }
                                                        },
                                                        "popular-collections": {
                                                            action: "nft_collections/list",
                                                            filters: {
                                                                is_popular: true
                                                            },
                                                            subqueries: {
                                                                img: {},
                                                                likes: {},
                                                                creator: {
                                                                    subqueries: {
                                                                        img: {},
                                                                    },
                                                                },
                                                            }
                                                        }
                                                    },
                                                    {
                                                        headers: {
                                                            Token: localStorage.getItem("adminToken")
                                                        },
                                                        auth: {
                                                            username: "odmen",
                                                            password: "NFTflsy",
                                                        },
                                                    },
                                                )
                                                .then(response => setPopularNFTsAndCollections({nfts: response.data["popular-nfts"].items, collections: response.data["popular-collections"].items}))
                                                .catch(err => console.log(err))
                                        }).catch(err => {
                                            toast.error("Server error: try later", {
                                                position: "bottom-right",
                                                style: {
                                                    font: "400 21px/100% 'DM Sans'",
                                                },
                                            })
                                            console.log(err)
                                        }).finally(() => setShowPopularNFTsAndCOllectionsPopup(false))
                                    }}>
                                        <div style={{ background: `${`url(https://nft-one.art/api/files/thumb/?hash=${collection.img?.hash}&width=100&height=100) no-repeat center center/cover`}` }}></div>
                                        <p>{collection?.name?.length > 15 ? collection?.name.slice(0, 12) + "..." : collection?.name}</p>
                                    </li>
                                )) : "No items"}
                        </ul>
                    </div>
                </div>
            </Popup>
            <Popup show={showEmojiPopup} onShow={arg => setShowEmojiPopup(arg)}>
                <ul className="popup__emojies">
                    {
                        emojies.map(emoji => (
                            <li className="popup__emojies-item" onClick={() => {
                                setChooseEmoji(emoji)
                                setShowEmojiPopup(false)
                            }}>{ emoji.code }</li>
                        ))
                    }
                </ul>
            </Popup>
        </>
    );
}

export default AdminPanelSettings;