import axios from "axios";
import { useCallback, useEffect } from "react";
import { useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { toast, Toaster } from "react-hot-toast";
import Checkbox from "../../components/Checkbox";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ContextApp } from "../../Context";
import "./CreateCollection.scss";
import StringSimilarity from "string-similarity";

function CreateCollection() {
    const { changeTheme, currentUser } = useContext(ContextApp);

    const [photoPreviewPath, setPhotoPreviewPath] = useState(null)
    const [bannerPreviewPath, setBannerPreviewPath] = useState(null)

    const onDropPhoto = useCallback(acceptedFilesFromPhoto => {
        setPhotoPreviewPath(URL.createObjectURL(acceptedFilesFromPhoto[0]))
    }, [setPhotoPreviewPath])

    const onDropBanner = useCallback(acceptedFilesFromBanner => {
        setBannerPreviewPath(URL.createObjectURL(acceptedFilesFromBanner[0]))
    }, [setBannerPreviewPath])

    const { acceptedFiles:acceptedFilesFromPhoto, fileRejections: fileRejectionFromPhoto, getRootProps:getRootPropsFromPhoto, getInputProps:getInputPropsFromPhoto } = useDropzone({
        accept: {
            "image/webp": [".webp"],
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/svg+xml": [".svg"],
            "image/gif": [".gif"]
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 12582912,
        onDrop: onDropPhoto
    });

    const { acceptedFiles:acceptedFilesFromBanner, fileRejections: fileRejectionFromBanner, getRootProps:getRootPropsFromBanner, getInputProps:getInputPropsFromBanner } = useDropzone({
        accept: {
            "image/webp": [".webp"],
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/svg+xml": [".svg"],
            "image/gif": [".gif"]
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 12582912,
        onDrop: onDropBanner
    });

    const [filenamePhoto, setFilenamePhoto] = useState("");
    const [filenameBanner, setFilenameBanner] = useState("");
    const [commonAttrs, setCommonAttrs] = useState([{ id: 1, name: "", value: "" }]);
    const [nameCollection, setNameCollection] = useState("");
    const [descriptionCollection, setDescriptionCollection] = useState("");
    const [categories, setCategories] = useState([]);
    const [socials, setSocials] = useState({
        vk: "",
        facebook: "",
        discord: "",
        instagram: "",
        reddit: "",
        telegram: "",
        "tik-tok": "",
        twitter: "",
        youtube: ""
    });

    const [configCategories, setConfigCategories] = useState([]);

    useEffect(() => {
        axios
                .post("https://nft-one.art/api/config/get", {}, {
                    headers: {
                        Token: localStorage.getItem("tonhubToken") ? localStorage.getItem("tonhubToken") : localStorage.getItem("tonkeeperToken"),
                    },
                    auth: {
                        username: "odmen",
                        password: "NFTflsy",
                    },
                })
                .then(response => {
                    setConfigCategories(response.data.defaults["nft_categories"].split("\n"))
                })
                .catch(error => {
                    console.log(error);
                });
    }, [])

    useEffect(() => {
        if (fileRejectionFromPhoto.length > 0 || fileRejectionFromBanner.length > 0) {
            toast.error(`Invalid file extension or size`, {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            });
        }
    }, [fileRejectionFromPhoto, fileRejectionFromBanner])

    useEffect(() => {
        setFilenamePhoto(acceptedFilesFromPhoto[0]?.path);
        setFilenameBanner(acceptedFilesFromBanner[0]?.path);
    }, [acceptedFilesFromPhoto, acceptedFilesFromBanner]);

    const setCommonAttrsChange = (e, type) => {
        let attrs_copy = [...commonAttrs];
        if (type === "value") {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) === attr.id ? (attr.value = e.target.value) : null,
            );
        } else {
            attrs_copy.map(attr =>
                Number(e.target.getAttribute("data-attrid")) === attr.id ? (attr.name = e.target.value) : null,
            );
        }
        setCommonAttrs(attrs_copy);
    };

    const deleteCommonAttr = id => {
        let attrs_copy = [];
        commonAttrs.map(attr => (attr.id === Number(id) ? null : attrs_copy.push(attr)));
        setCommonAttrs(attrs_copy);
    };

    const getFormatCommonAttrsForBackend = () => {
        let new_attrs = {};
        commonAttrs.map(attr => new_attrs[attr.name] = attr.value);
        return new_attrs;
    }

    const changeCategoriesArray = (e) => {
        let currentCategory = e.target.innerText;
        if (categories.includes(currentCategory)) {
            setCategories(categories.filter(cat => cat !== currentCategory));
        } else {
            setCategories([...categories, currentCategory]);
        }
    }

    const changeSocialObject = (e) => {
        let currentSocial = e.target.getAttribute("data-social");
        let socials_copy = {...socials};
        socials_copy[currentSocial] = e.target.value;
        setSocials(socials_copy);
    }

    const banCurrentUser = async () => {
        const {data} = await axios.post(
            "https://nft-one.art/api/users/upsert",
            {
                items: [
                    {
                        id: currentUser.id,
                        is_blocked: true
                    }
                ]
            },
            {
                headers: {
                    Token: localStorage.getItem("tonhubToken") || localStorage.getItem("tonkeeperToken"),
                }
            },
        )
        return data
    }

    const validationSpamBot = () => {
        function getDateDifferenceInMinutes(date1, date2) {
            const differenceInMilliseconds = Math.abs(date1 - date2);
            return Math.ceil(differenceInMilliseconds / (1000 * 60));
        }
        axios.post("https://nft-one.art/api/nft_collections/list", {
            filters: {
                creator_id: currentUser.id,
                is_foreign: false
            },
            calc_total: true,
            order_by: "id desc"
        }, {
            headers: {
                Token: localStorage.getItem("tonhubToken") || localStorage.getItem("tonkeeperToken"),
            }
        }).then(({data}) => {
            if (data.items.length <= 10) { // тут поправить на 10
                return true
            } else if (Number(data.total) > 100) {
                let tenLastCollections = [...data.items.slice(0, 10)]
                let rates = []
                tenLastCollections.map(collection => {
                    rates.push(Number(StringSimilarity.compareTwoStrings(nameCollection, collection.name)))
                })
                if (rates.reduce((acc, curr) => acc + curr, 0) / rates.length > 0.8) {
                    banCurrentUser().then(_ => {
                        setTimeout(() => {
                            window.location.href = "/"
                            return false
                        }, 1200)
                    })
                }
            } else {
                let threeLastCollections = [...data.items.slice(0, 3)]
                if (((getDateDifferenceInMinutes(new Date(), threeLastCollections[0].add_time) <= 1) &&
                    (getDateDifferenceInMinutes(threeLastCollections[0].add_time, threeLastCollections[1].add_time) <= 1) &&
                    (getDateDifferenceInMinutes(threeLastCollections[1].add_time, threeLastCollections[2].add_time) <= 1))) {
                    banCurrentUser().then(_ => {
                        setTimeout(() => {
                            window.location.href = "/"
                            return false
                        }, 1200)
                    })

                }
            }
        })
        return true
    }

    const validationCreateCollection = () => {
        return (nameCollection !== "" && descriptionCollection !== "" && acceptedFilesFromPhoto.length !== 0 && acceptedFilesFromBanner.length !== 0)
    }

    const createCollection = () => {
        if (validationCreateCollection()) {
            if (validationSpamBot()) {
                const formData = new FormData();
                formData.append("json_data", JSON.stringify({
                    items: [{
                        img: "img-collection",
                        hdr: "hdr-collection",
                        name: nameCollection,
                        info: descriptionCollection,
                        categories: categories,
                        social_links: socials,
                        common_attrs: getFormatCommonAttrsForBackend(),
                    }]
                }));
                formData.append("img-collection", acceptedFilesFromPhoto[0]);
                formData.append("hdr-collection", acceptedFilesFromBanner[0]);
                axios
                    .post("https://nft-one.art/api/nft_collections/upsert", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Token: localStorage.getItem("tonhubToken") ? localStorage.getItem("tonhubToken") : localStorage.getItem("tonkeeperToken"),
                        },
                        auth: {
                            username: "odmen",
                            password: "NFTflsy",
                        },
                    })
                    .then(response => {
                        toast.success(`Collection «${nameCollection}» created`, {
                            position: "bottom-right",
                            style: {
                                font: "400 21px/100% 'DM Sans'",
                            },
                        });
                        axios
                            .post("https://nft-one.art/api/nft_collections/list",
                                {
                                    filters: {
                                        name: nameCollection
                                    }
                                },
                                {
                                    headers: {
                                        Token: localStorage.getItem("tonhubToken") ? localStorage.getItem("tonhubToken") : localStorage.getItem("tonkeeperToken"),
                                    },
                                    auth: {
                                        username: "odmen",
                                        password: "NFTflsy",
                                    },
                                })
                            .then(response => {
                                setTimeout(() => {
                                    window.location.href = `/collection/${response.data.items.reverse()[0].id}`
                                }, 1200)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(error => {
                        toast.error(`Server error: try later`, {
                            position: "bottom-right",
                            style: {
                                font: "400 21px/100% 'DM Sans'",
                            },
                        });
                    });
            }
        } else {
            toast.error(`Check the data`, {
                position: "bottom-right",
                style: {
                    font: "400 21px/100% 'DM Sans'",
                },
            });
        }
    }

    return (
        <>
            <Header />
            <section className={changeTheme("createCollection", "createCollection createCollection--dark")}>
                <div className="createCollection__left">
                    <div className="createCollection__left-download dropzone" {...getRootPropsFromPhoto()}>
                        <img
                            className="createCollection__left-download-img"
                            src={photoPreviewPath ? photoPreviewPath : "/img/sections/createNFT/drag-and-drop.svg"}
                            alt="Drag and drop"
                        />
                        {filenamePhoto ? (
                            <p className="createCollection__left-download-drag">{filenamePhoto.length > 12 ? filenamePhoto.slice(filenamePhoto.length - 12, filenamePhoto.length) : filenamePhoto}</p>
                        ) : (
                            <>
                                <p className="createCollection__left-download-drag">Add photo<span style={{color: "#ff0000"}}>*</span><br/><span style={{
                                    opacity: ".8",
                                    fontSize: "0.82em",
                                    fontWeight: "400"
                                }}>(.png, .jpg, .jpeg, .svg, .webp, .gif maximum size of 12mb)</span></p>
                                <p className="createCollection__left-download-or">or</p>
                            </>
                        )}
                        <button className="createCollection__left-download-browse">
                            <input {...getInputPropsFromPhoto()} type="file" onClick={e => e.stopPropagation()} />
                            Browse File
                        </button>
                    </div>
                    <div className="createCollection__left-download dropzone" {...getRootPropsFromBanner()}>
                        <img
                            className="createCollection__left-download-img"
                            src={bannerPreviewPath ? bannerPreviewPath : "/img/sections/createNFT/drag-and-drop.svg"}
                            alt="Drag and drop"
                        />
                        {filenameBanner ? (
                            <p className="createCollection__left-download-drag">{filenameBanner.length > 12 ? filenameBanner.slice(filenameBanner.length - 12, filenameBanner.length) : filenameBanner}</p>
                        ) : (
                            <>
                                <p className="createCollection__left-download-drag">Add banner<span style={{color: "#ff0000"}}>*</span><br/><span style={{
                                    opacity: ".8",
                                    fontSize: "0.82em",
                                    fontWeight: "400"
                                }}>(.png, .jpg, .jpeg, .svg, .webp, .gif maximum size of 12mb)</span></p>
                                <p className="createCollection__left-download-or">or</p>
                            </>
                        )}
                        <button className="createCollection__left-download-browse">
                            <input {...getInputPropsFromBanner()} type="file" onClick={e => e.stopPropagation()} />
                            Browse File
                        </button>
                    </div>
                    <div className="createCollection__left-attr createCollection__left-attr--common ">
                        <h6 className="createCollection__left-attr-title">Common attributes</h6>
                        <div className="createCollection__left-attr-box">
                            {window.innerWidth <= 768 ? (
                                commonAttrs.map((item, index) => (
                                    <>
                                        <div className="createCollection__left-attr-box-name">
                                            <label>Name</label>
                                            <input data-attrid={item.id} type="text" placeholder={`${!index ? "Price" : ""}`} value={item.name}
                                                onChange={e => setCommonAttrsChange(e, "name")}/>
                                        </div>
                                        <div className="createCollection__left-attr-box-value">
                                            <label>Value</label>
                                            <div>
                                                <input type="text" placeholder={`${!index ? "10$" : ""}`} data-attrid={item.id} value={item.value}
                                                    onChange={e => setCommonAttrsChange(e, "value")}/>
                                                {index !== 0 && (
                                                    <div
                                                    data-attrid={item.id}
                                                        className="createCollection__left-attr-box-value-minus"
                                                        onClick={(e) => deleteCommonAttr(e.target.getAttribute("data-attrid"))}></div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <>
                                    <div className="createCollection__left-attr-box-name">
                                        <label>Name</label>
                                        {commonAttrs.map((item, index) => (
                                            <input
                                                data-attrid={item.id}
                                                type="text"
                                                placeholder={`${!index ? "Price" : ""}`}
                                                value={item.name}
                                                onChange={e => setCommonAttrsChange(e, "name")}
                                            />
                                        ))}
                                    </div>
                                    <div className="createCollection__left-attr-box-value">
                                        <label>Value</label>
                                        {commonAttrs.map((item, index) => (
                                            <div>
                                                <input
                                                    data-attrid={item.id}
                                                    type="text"
                                                    placeholder={`${!index ? "10$" : ""}`}
                                                    value={item.value}
                                                    onChange={e => setCommonAttrsChange(e, "value")}
                                                />
                                                {index !== 0 && (
                                                    <div
                                                        data-attrid={item.id}
                                                        className="createCollection__left-attr-box-value-minus"
                                                        onClick={e =>
                                                            deleteCommonAttr(e.target.getAttribute("data-attrid"))
                                                        }></div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        <button
                            className="createCollection__left-attr-add"
                            onClick={() => {
                                setCommonAttrs([...commonAttrs, { id: commonAttrs[commonAttrs.length - 1].id + 1, name: "", value: "" }]);
                            }}>
                            + Add attribute
                        </button>
                    </div>
                </div>
                <div className="createCollection__right">
                    <h1 className="createCollection__right-title">Create collection</h1>
                    <form className="createCollection__right-form" action="">
                        <div className="createCollection__right-form-name">
                            <label>
                                Display Name<span>*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Collection name"
                                value={nameCollection}
                                onChange={e => setNameCollection(e.target.value)}
                            />
                        </div>
                        <div className="createCollection__right-form-description">
                            <label>
                                Description<span>*</span>
                            </label>
                            <textarea
                                value={descriptionCollection}
                                onChange={e => setDescriptionCollection(e.target.value)}
                                placeholder="Tell us more about your collection"></textarea>
                        </div>
                        <div className="createCollection__right-form-box">
                            <div className="createCollection__right-form-box-categories">
                                <label>Categories</label>
                                <div>
                                    {configCategories.map(category => <Checkbox text={category} onClick={e => changeCategoriesArray(e)}/>)}
                                </div>
                            </div>
                            <div className="createCollection__right-form-box-social">
                                <label>Social Links</label>
                                <ul>
                                    {[
                                        "vk",
                                        "facebook",
                                        "discord",
                                        "instagram",
                                        "reddit",
                                        "tg",
                                        "tik-tok",
                                        "twitter",
                                        "youtube",
                                    ].map((social, i) => (
                                        <li>
                                            <img src={`./img/sections/collection/social-icons/${social}.svg`} alt="" />
                                            <input
                                                value={socials[social]}
                                                onChange={e => changeSocialObject(e)}
                                                type="text"
                                                placeholder={
                                                    i === 0 || i === 1 || i === 3
                                                        ? "account-id"
                                                        : i === 2
                                                        ? "server-id"
                                                        : i === 4 || i === 5 || i === 6 || i === 7
                                                        ? "nickname"
                                                        : i === 8
                                                        ? "url-code"
                                                        : ""
                                                }
                                                data-social={social}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="createCollection__right-form-buttons">
                            <input
                                className="createCollection__right-form-buttons-create"
                                type="button"
                                value="CREATE COLLECTION"
                                onClick={createCollection}
                            />
                            <input
                                className="createCollection__right-form-buttons-reset"
                                type="button"
                                value="RESET"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            />
                        </div>
                    </form>
                </div>
            </section>
            <Toaster/>
            <Footer />
        </>
    );
}

export default CreateCollection;
