import { useContext, useState, useEffect } from "react";
import { ContextApp } from "../../Context";
import Checkbox from "../Checkbox";
import Radiobutton from "../Radiobutton";
import "./Filters.scss";
import $ from "jquery"
import { Fragment } from "react";
import { Tooltip } from "react-tooltip";

function Filters({ example, handleArray, search, searchBottom, extraFiltersForURL }) {
    const [popups, setPopups] = useState([true, true, true, true, true, true]);
    const [extraPopup, setExtraPopup] = useState(true);
    const [extraPopups, setExtraPopups] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const [extraFilters, setExtraFilters] = useState([]);
    const [extraFiltersCheckes, setExtraFiltersCheckes] = useState([]);

    const [buyCheck, setBuyCheck] = useState(false);
    const [auctionCheck, setAuctionCheck] = useState(false);
    const [quantityCheck, setQuantityCheck] = useState("all");
    const [priceAt, setPriceAt] = useState("");
    const [priceTo, setPriceTo] = useState("");
    const [categoryCheck, setCategoryCheck] = useState([]);
    const [emotionalCheck, setEmotionalCheck] = useState([]);

    const [isOpen, setIsOpen] = useState(false)

    const getExtraFilters = (handleArray) => {
        let arr = []
        let arrKeys = []
        let filters = []

        handleArray?.map(elem => {
            if (elem?.attrs && Object.keys(elem?.attrs)?.length > 0) {
                Object.keys(elem?.attrs).map(el => {
                    if (el !== "" && elem?.attrs[el] !== "") {
                        arr.push({ [el.charAt(0).toUpperCase() + el.slice(1)]: elem?.attrs[el]})
                    }
                })
                arr = arr.sort((a, b) => {
                    if (Object.keys(a)[0] > Object.keys(b)[0]) return 1
                    if (Object.keys(a)[0] < Object.keys(b)[0]) return -1
                    return 0
                })
            }
        })

        arr?.map(item => !arrKeys.includes(Object.keys(item)[0]) ? arrKeys.push(Object.keys(item)[0]) : null)

        arrKeys?.map(key => {
            let countKey = arr.reduce((acc, val) => Object.keys(val)[0] === key ? acc += 1 : acc += 0, 0)
            // если что поменять на countKey > 2
            if (countKey > 0) {
                // key - это сам фильтр (размер, форма, тип)
                let a = [] // тут лежат все значения (круглый, квадратный, треугольный)
                arr.map(item => {
                if (Object.keys(item)[0] === key) {
                    if (!a.includes(item[Object.keys(item)[0]])) {
                    a.push(item[Object.keys(item)[0]])
                    }
                }
                }
                )
                filters.push({[key]: a})
            }
        })

        return filters
    }

    useEffect(() => {
            $(".checkbox__input").prop("checked", false)
            $("input[name='quantity']").prop("checked", false)
    }, [])

    useEffect(() => {
        if (extraFiltersForURL) {
            for (let elem of $(".checkbox")) {
                if ($($(elem).children("label"))[0].innerText?.toLowerCase() === window.location.href.split("#")[2]?.toLowerCase()) {
                    $($(elem).children("input")).prop("checked", true)
                }
            }
           }
    }, [extraFiltersForURL])

    useEffect(() => {
        setExtraFilters([...getExtraFilters(handleArray)])
    }, [handleArray])

    useEffect(() => {
        if (extraFilters.length > 0) {
            extraFilters.map(eF => setExtraPopups(prev => [...prev, true]))
        }
    }, [extraFilters])

    const {
        theme,
        changeTheme,
        setFilterStatus,
        setFilterQuantity,
        setFilterPriceAt,
        setFilterPriceTo,
        setFilterCategory,
        setFilterEmotional,
        setGlobalExtraFilters,
        setFilterSearch,
        filterSearch,
    } = useContext(ContextApp);

    const categories = [
        "Art",
        "Collectibles",
        "Music",
        "Photography",
        "Sports",
        "Trading",
        "Cards",
        "Utility",
        "Virtual",
        "Worlds",
    ];

    function applyFilters() {
        setIsOpen(false)

        setFilterStatus(
            (buyCheck && auctionCheck) ? "Buy now and In auction" : (!buyCheck && !auctionCheck) ? "all" : buyCheck ? "Buy now" : "In auction",
        );
        setFilterQuantity(quantityCheck);
        setFilterPriceAt(priceAt);
        setFilterPriceTo(priceTo);
        setFilterCategory(categoryCheck);
        setFilterEmotional(emotionalCheck);
        setGlobalExtraFilters(extraFiltersCheckes);
    }

    function applyFiltersMod() {
        setIsOpen(false)

        setFilterStatus(
            (buyCheck && auctionCheck) ? "Buy now and In auction" : (!buyCheck && !auctionCheck) ? "all" : buyCheck ? "Buy now" : "In auction",
        );
        setFilterQuantity(quantityCheck);
        setFilterPriceAt(priceAt);
        setFilterPriceTo(priceTo);
        setFilterCategory(categoryCheck);
        setFilterEmotional(emotionalCheck);
        setGlobalExtraFilters(extraFiltersCheckes);
    }

    function addOrRemoveCategoryCheck(category) {
        if (categoryCheck.includes(category)) {
            setCategoryCheck(categoryCheck.filter(cat => cat !== category));
        } else {
            setCategoryCheck([...categoryCheck, category]);
        }
    }

    function addOrRemoveEmotionalCheck(emotion) {
        if (emotionalCheck.includes(emotion)) {
            setEmotionalCheck(emotionalCheck.filter(emot => emot !== emotion));
        } else {
            setEmotionalCheck([...emotionalCheck, emotion]);
        }
    }

    const haveObjectInArray = (arr, obj) => {
        let obj_key = Object.keys(obj)[0]
        let obj_val = obj[obj_key]
        let flag = false;
        arr.map(item => {
          if (Object.keys(item)[0].toLowerCase() === obj_key.toLowerCase() && item[Object.keys(item)[0]].toLowerCase() === obj_val.toLowerCase()) {
            flag = true
          }
        })
        return flag;
      }

    const handleExtraClick = (e, key, value) => {
        // если есть в массиве - удаляем
        if (haveObjectInArray(extraFiltersCheckes, {[key]: value})) {
            let copy = [...extraFiltersCheckes]
            copy = copy.filter(item => {
                if ((key.toLowerCase() === Object.keys(item)[0].toLowerCase() && item[Object.keys(item)[0]].toLowerCase() === value.toLowerCase())) {
                    return 0
                }
                return 1
            })
            setExtraFiltersCheckes(copy)
        } else {
            setExtraFiltersCheckes([...extraFiltersCheckes, { [key]: value }])
        }
    }

    return (
        <>
            <Tooltip
                className="tt"
                id="apply-tooltip"
                place="right"
                isOpen={isOpen}
                openOnClick
                offset={20}
                style={{
                    zIndex: "100000",
                    font: "500 16px/100% 'DM Sans'"
                }}
            >
                <button className='filters__applyTooltip-btn' onClick={() => applyFiltersMod()}>Apply</button>
            </Tooltip>
            <div
                className="filters"
                style={{ backgroundColor: changeTheme("#fff", "#1C2026"), color: changeTheme("#000", "#fff") }}>
                {
                    search && (
                        <div className="filters__search">
                                <input
                                    value={filterSearch}
                                    onChange={e => setFilterSearch(e.target.value)}
                                    placeholder="Search"
                                    className="filters__search-input"
                                    type="text"
                                    style={{
                                        backgroundColor: changeTheme("", "#272E37"),
                    border: changeTheme("", "2px solid #596577")
                                    }}
                                />
                                <img
                                    className="filters__search-img"
                                    alt=""
                                    src={`/img/sections/collection/search-${theme}.svg`}
                                />
                        </div>
                    )
                }
                {example !== "collection" && (
                    <>
                        <div className="filters__status">
                            <div
                                onClick={() =>
                                    setPopups([!popups[0], popups[1], popups[2], popups[3], popups[4], popups[5]])
                                }>
                                <h6>Status</h6>
                                {popups[0] ? (
                                    <img src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                                ) : (
                                    <img
                                        src={`/img/sections/catalog/arrow-${theme}.png`}
                                        alt="Arrow"
                                        style={{ transform: "rotate(-90deg) translateX(30%)" }}
                                    />
                                )}
                            </div>
                            {popups[0] && (
                                <div>
                                    <Checkbox dti="apply-tooltip" text={window.location.href.includes("profile") ? "On sale" : "Buy now"} onClick={() => {
                                        setBuyCheck(!buyCheck)
                                        setIsOpen(true)
                                    }} />
                                    <Checkbox dti="apply-tooltip" text={"On Auction"} onClick={() => {setAuctionCheck(!auctionCheck)
                                    setIsOpen(true)}} />
                                </div>
                            )}
                        </div>
                        {
                            example !== "collection-page" && (
                                <div className="filters__quantity">
                            <div
                                onClick={() =>
                                    setPopups([popups[0], !popups[1], popups[2], popups[3], popups[4], popups[5]])
                                }>
                                <h6>Quantity</h6>
                                {popups[1] ? (
                                    <img src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                                ) : (
                                    <img
                                        src={`/img/sections/catalog/arrow-${theme}.png`}
                                        alt="Arrow"
                                        style={{ transform: "rotate(-90deg) translateX(30%)" }}
                                    />
                                )}
                            </div>
                            {popups[1] && (
                                <div>
                                    <Radiobutton
                                        dti="apply-tooltip"
                                        text={"All items"}
                                        group={"quantity"}
                                        onClick={() => {
                                            setQuantityCheck("all")
                                            setIsOpen(true)
                                        }}
                                    />
                                    <Radiobutton
                                        dti="apply-tooltip"
                                        text={"Single items"}
                                        group={"quantity"}
                                        onClick={() => {
                                            setQuantityCheck("single")
                                            setIsOpen(true)
                                        }}
                                    />
                                    <Radiobutton
                                        dti="apply-tooltip"
                                        text={"In collection"}
                                        group={"quantity"}
                                        onClick={() => {
                                            setQuantityCheck("bundles")
                                            setIsOpen(true)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                            )
                        }
                        <div className="filters__price">
                            <div
                                onClick={() =>
                                    setPopups([popups[0], popups[1], !popups[2], popups[3], popups[4], popups[5]])
                                }>
                                <h6>Price range</h6>
                                {popups[2] ? (
                                    <img src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                                ) : (
                                    <img
                                        src={`/img/sections/catalog/arrow-${theme}.png`}
                                        alt="Arrow"
                                        style={{ transform: "rotate(-90deg) translateX(30%)" }}
                                    />
                                )}
                            </div>
                            {popups[2] && (
                                <div className="filters__price-content">
                                    <div>
                                        <p>From</p>
                                        <input
                                            data-tooltip-id="apply-tooltip"
                                            data-tooltip-offset={10}
                                            data-tooltip-place="bottom"
                                            min="0"
                                            type="number"
                                            value={priceAt}
                                            onChange={e => {setPriceAt(e.target.value)
                                            }}
                                            onClick={() => setIsOpen(true)}
                                        />
                                    </div>
                                    <div>
                                        <p>To</p>
                                        <input
                                            data-tooltip-id="apply-tooltip"
                                            data-tooltip-offset={10}
                                            data-tooltip-place="bottom"
                                            min="0"
                                            type="number"
                                            value={priceTo}
                                            onChange={e => {setPriceTo(e.target.value)
                                            }}
                                            onClick={() => setIsOpen(true)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div className="filters__emotional">
                    <div onClick={() => setPopups([popups[0], popups[1], popups[2], popups[3], !popups[4], popups[5]])}>
                        <h6>The most emotional</h6>
                        {popups[4] ? (
                            <img src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                        ) : (
                            <img
                                src={`/img/sections/catalog/arrow-${theme}.png`}
                                alt="Arrow"
                                style={{ transform: "rotate(-90deg) translateX(30%)" }}
                            />
                        )}
                    </div>
                    {popups[4] && (
                        <>
                            <Checkbox dti="apply-tooltip" text={"❤️"} onClick={e => {
                                addOrRemoveEmotionalCheck("Red Heart")
                                setIsOpen(true)
                            }} />
                            <Checkbox dti="apply-tooltip"
                                text={"🤣"}
                                onClick={e => {addOrRemoveEmotionalCheck("Rolling on the Floor Laughing")
                                setIsOpen(true)}}
                            />
                            <Checkbox dti="apply-tooltip"
                                text={"😍"}
                                onClick={e => {addOrRemoveEmotionalCheck("Smiling Face with Heart-Eyes")
                                setIsOpen(true)}}
                            />
                            <Checkbox dti="apply-tooltip" text={"😡"} onClick={e => {addOrRemoveEmotionalCheck("Enraged Face") 
                            setIsOpen(true)}} />
                            <Checkbox dti="apply-tooltip" text={"🙀"} onClick={e => {addOrRemoveEmotionalCheck("Weary Cat") 
                            setIsOpen(true)}} />
                            <Checkbox dti="apply-tooltip" text={"🥴"} onClick={e => {addOrRemoveEmotionalCheck("Woozy Face") 
                            setIsOpen(true)}} />
                            <Checkbox dti="apply-tooltip"
                                text={"🤑"}
                                onClick={e => {
                                    
                                    addOrRemoveEmotionalCheck("Money-Mouth Face")
                                    setIsOpen(true)
                                }}
                            />
                        </>
                    )}
                </div>
                <div className="filters__category">
                    <div onClick={() => setPopups([popups[0], popups[1], popups[2], popups[3], popups[4], !popups[5]])}>
                        <h6>Category</h6>
                        {popups[5] ? (
                            <img src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                        ) : (
                            <img
                                src={`/img/sections/catalog/arrow-${theme}.png`}
                                alt="Arrow"
                                style={{ transform: "rotate(-90deg) translateX(30%)" }}
                            />
                        )}
                    </div>
                    {popups[5] && (
                        <div>
                            {categories.map((category, index) => (
                                <Checkbox
                                    dti="apply-tooltip"
                                    key={index}
                                    text={category}
                                    onClick={e => {
                                        addOrRemoveCategoryCheck(e.target.innerText.toLowerCase())
                                        setIsOpen(true)
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
                {
                    extraFilters.length > 0 &&
                    <div className="filters__extra">
                        <div className="filters__extra-title" onClick={() => setExtraPopup(!extraPopup)}>
                            <h6>Attributes</h6>
                            {extraPopup ? (
                                <img className="filters__extra-popup" src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                            ) : (
                                <img
                                    className="filters__extra-popup"
                                    src={`/img/sections/catalog/arrow-${theme}.png`}
                                    alt="Arrow"
                                    style={{ transform: "rotate(-90deg) translateX(30%)" }}
                                />
                            )}
                        </div>
                        {
                            extraPopup && searchBottom && (
                                <div className="filters__search">
                                    <input
                                        value={searchQuery}
                                        onChange={e => setSearchQuery(e.target.value)}
                                        placeholder="Search"
                                        className="filters__search-input"
                                        type="text"
                                        style={{
                                            backgroundColor: changeTheme("", "#272E37"),
                                            border: changeTheme("", "2px solid #596577")
                                        }}
                                    />
                                    <img
                                        className="filters__search-img"
                                        alt=""
                                        src={`/img/sections/collection/search-${theme}.svg`}
                                    />
                                </div>
                            )
                        }
                        <div className={`${extraFilters.length > 7 ? "filters__extra-block-scroller" : ""}`}>
                            {
                                extraPopup && extraFilters
                                    .filter(block => Object.keys(block)[0].toLowerCase().includes(searchQuery.toLowerCase()))
                                    .map((block, index) => (
                                        <div className={`filters__extra-block`} key={index}>
                                            {
                                                Object.keys(block).map((title, i) => (
                                                    <Fragment key={i}>
                                                        <div onClick={() => {
                                                            let copy = [...extraPopups]
                                                            copy[index] = !copy[index]
                                                            setExtraPopups([...copy])
                                                        }}>
                                                            <h6>{title} ({block[title].length})</h6>
                                                            {extraPopups[index] ? (
                                                                <img src={`/img/sections/catalog/arrow-${theme}.png`} alt="Arrow" />
                                                            ) : (
                                                                <img
                                                                    src={`/img/sections/catalog/arrow-${theme}.png`}
                                                                    alt="Arrow"
                                                                    style={{ transform: "rotate(-90deg) translateX(30%)" }}
                                                                />
                                                            )}
                                                        </div>
                                                        {
                                                            extraPopups[index] && (
                                                                <div>
                                                                    {block[title].map((value, ind) => (
                                                                        <Checkbox
                                                                            dti="apply-tooltip"
                                                                            key={ind}
                                                                            text={value?.toString()?.charAt(0)?.toUpperCase() + value?.toString()?.slice(1)}
                                                                            onClick={(e) => {
                                                                                handleExtraClick(e, title, value)
                                                                                setIsOpen(true)
                                                                            }}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                }
                <button className="filters__apply" onClick={() => applyFilters()}>
                    Apply filters
                </button>
                <button className="filters__clear" onClick={() => {
                    setIsOpen(false)
                    setFilterSearch("");
                    setFilterStatus("all");
                    setFilterQuantity("all");
                    setFilterPriceAt("");
                    setFilterPriceTo("");
                    setFilterCategory([]);
                    setFilterEmotional();
                    $(".checkbox__input").each((index, element) => {
                        if(!($(element).attr("data-id") === "not-filter")) {
                            $(element).prop("checked", false);
                        }
                    })
                    $(".radiobutton__input").each((index, element) => {
                        if(!($(element).attr("data-id") === "not-filter")) {
                            $(element).prop("checked", false);
                        }
                    })
                }}>
                    Clear
                </button>
            </div>
        </>
    );
}

export default Filters;